@import '../theme.styl';

.dashboard-sidebar-wrap {
  border-right: 1px solid border-grey;
  padding-top: 16px;
  mobile({
    display: none
  });
  a {
    display: block;
    height: 58px;
    line-height: 58px;
    padding: 0 10px;
    font-size: 16px;
    font-family: 'Lato-bold';
    box-sizing: content-box;
    border-left: 4px solid transparent;
    color: rgba(45, 46, 61, 0.5);
    transition: all .3s;
    position:relative;
    &.active {
      background: light;
      border-left-color: #96979E;
      color: primary-dark;
    }
    &.notification-active, &.dashboard-active{
      position:relative;
      &:after{
        content:'';
        position: absolute;
        right: 16px;
        top:50%;
        transform: translateY(-50%);
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: #ff6b00;

      }
    }
    .unread-count{
      position: absolute;
      right:16px;
      top: 50%;
      transform: translateY(-50%)
      min-width 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #FF6B00;
      color: white;
      font-family: 'Lato';
      font-weight: 800;
      font-size: 12px;
      line-height: 24px;
      padding: 0 6px;
      text-align: center;
    }
  }
  .account-completion{
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-top: 1px solid rgba(45, 46, 61, 0.1);
    border-bottom:  1px solid rgba(45, 46, 61, 0.1);
    .title{
      font-family: 'Lato-bold';
      font-size: 16px;
      line-height: 160%;
      color: primary-dark;
      margin-bottom: 8px;
    }
    .completion-header{
      display: flex;
      justify-content: space-between;
      .total{
          font-family: 'Lato';
          font-weight: 800;
          font-size: 14px;
          line-height: 160%;
          color: #5A8E09;
      }
    }
    .progress-wrap{
      padding: 5px;
      background: rgba(131, 178, 58, 0.1);
      border: 1px solid rgba(45, 46, 61, 0.1);
       margin-bottom: 24px;
       width: 100%;
      .progress-bar{
        width:100%;
        background-color:transparent;
        .MuiLinearProgress-barColorPrimary{
          background-color:#83B23A
        }
      }
    }

    .check-item{
      display: flex;
      align-items: center;
      margin-bottom: 8px
      .check-box{
        .ant-checkbox-checked .ant-checkbox-inner{
          border-radius: 100px;
          background-color: #83B23A
          border: none
        }
        .ant-checkbox-inner{
          border-radius: 100px;

        }
      }
      .key{
        font-family: 'Lato';
        font-size: 14px;
        line-height: 160%;
        color: primary-dark;
        margin: 0 8px;
      }
      .value{
         font-family: 'Lato';
         font-weight: 800;
        font-size: 14px;
        line-height: 160%;
        color: rgba(45, 46, 61, 0.25);
      }
    }
  }
}