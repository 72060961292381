@import "../../../theme.less";
.business-dashboard-stats-root {
  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    color: @primary-dark;
  }
  .subtitle-sec {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(45, 46, 61, 0.1);
    align-items: center;
    @media @mobile {
      flex-direction: column;
      border-bottom: none;
    }
    .subtitle {
      font-family: "LibreBaskerville";
      font-size: 20px;
      line-height: 140%;
      color: @primary-dark;
      @media @mobile {
        width: 100%;
      }
    }
    .location-select {
      width: 211px;
      @media @mobile {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
  .content {
    display: flex;
    @media @mobile {
      flex-direction: column;
    }
    .chart-sec {
      background: #ffffff;
      border-radius: 4px;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .chart-title {
        font-family: "Lato-bold";
        font-size: 16px;
        line-height: 160%;
        color: @primary-dark;
        margin: 16px;
      }
      @media @mobile {
        width: 100%;
      }
    }
    .total-sec {
      flex: 1;
      margin-left: 16px;
      @media @mobile {
        margin-left: 0;
        margin-top: 16px / @pv;
      }
      .card-root {
        height: 150px;
        width: 100%;
        background-color: white;
        border-radius: 4px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .card-title {
          font-family: "Lato";
          font-size: 12px;
          line-height: 140%;
          color: @primary-dark;
        }
        .card-value {
          font-family: "LibreBaskerville";
          font-size: 40px;
          line-height: 120%;
          color: @primary-dark;
        }
      }
    }
  }
}
.antd-pro-charts-pie-total {
  .pie-sub-title {
    font-family: "Lato" !important;
    font-size: 8px !important;
    color: rgba(45, 46, 61, 0.5) !important;
    margin-bottom: 0px !important;
    line-height: 100% !important;
  }
  .pie-stat {
    font-family: "LibreBaskerville-bold";
    font-size: 17px;
    color: @primary-dark;
  }
}
.antd-pro-charts-pie-legend {
  top: 0 !important;
  transform: none !important;
  min-width: 230px !important;
  li {
    margin-bottom: 8px !important;
    height: auto !important;
    display: flex;
    align-items: center;
  }
  .ant-divider {
    display: none !important;
  }
  .antd-pro-charts-pie-percent {
    display: none !important;
  }
  .antd-pro-charts-pie-legendTitle {
    max-width: 170px !important;
    display: inline-block;
  }
}
