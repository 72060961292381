@import "../theme.less";
.status-dropdown-root {
  .status-btn {
    padding: 0 9.5px 0 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    border: none;
    .dropdown-icon {
      margin-left: 8px;
      margin-right: 0;
      width: 8px;
    }
    &:hover,
    &:focus {
      border: none;
    }
  }

  @media @mobile {
    .status-btn {
      height: 27px / @pv;
    }
  }
}
#status-dropdopwn-menu {
  .menu-item {
    min-height: 48px;
    .option-text {
      margin-left: 13px;
      font-family: "Lato";
      font-size: 16px;
      line-height: 160%;
      color: @primary-color;
      &:hover {
        //   background-color: #F4F5F5;
      }
    }
  }
}
