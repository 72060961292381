@import "../../../theme.less";

.school-dashboard-students,
.school-dashboard-add-students {
  .title-wrap {
    display: flex;
    border-bottom: 1px solid @rgba-grey-light;
    padding-bottom: 16px;
    @media @mobile {
      flex-direction: column;
    }
    & > div {
      display: flex;
      align-items: center;
      .common-title {
        margin-right: 48px;
      }
    }
    .common-title {
      margin-right: 24px;
      @media @mobile {
        margin-bottom: 16px / @pv;
      }
    }
    .space {
      flex: 1;
    }
  }
  .btn-secondary {
    height: 42px;
    font-size: 14px;
    &.btn-export {
      margin-right: 20px;
      @media @mobile {
        margin-right: 0px;
      }
    }
    @media @mobile {
      margin-top: 16px / @pv;
    }
  }
  .title-with-icon {
    margin-top: 50px;
  }
  .title-add-stu {
    margin-bottom: 0;
    margin-top: 40px;
    padding-top: 32px;
    border-top: 1px solid rgba(45, 46, 61, 0.1);
  }
  .add-by-email {
    margin-top: 16px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(45, 46, 61, 0.1);
    font-family: "Lato-bold";
    font-size: 14px;
    line-height: 160%;
    color: rgba(45, 46, 61, 0.75);
    display: flex;
    align-items: center;
    .hyper-link {
      margin-left: 14px;
      font-family: "Lato";
      font-weight: 800;
      font-size: 14px;
      line-height: 120%;
      color: rgba(45, 46, 61, 0.5);
      cursor: pointer;
    }
  }
  .filter-wrap {
    padding: 32px 0 0 0;
    display: flex;
    align-items: center;
    @media @mobile {
      flex-direction: column;
    }
    .ant-col {
      height: 56px;
      &:last-child {
        display: flex;
        align-items: center;
      }
    }
    .material-input {
      width: 135px;
      margin-right: 16px;
      @media @mobile {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px / @pv;
      }
    }
    .keyword-input {
      height: 56px;
      flex: 1;
    }
    .btn-secondary {
      height: 42px;
      padding: 0 16px;
      margin-left: 16px;
      @media @mobile {
        width: 100%;
        margin-left: 0;
        .center-wrap {
          margin: auto;
        }
      }
      &[disabled] {
        img {
          opacity: 0.25;
        }
      }
    }
  }
}

.table-popover .MuiMenu-list {
  .MuiListItem-button {
    padding: 15px;
    font-size: 16px;
    &:hover {
      background-color: rgba(255, 107, 0, 0.1);
    }
    img {
      width: 18px;
      margin-right: 16px;
    }
  }
  .orange-text {
    color: #ff6b00;
  }
}

.send-msg-modal {
  padding: 24px;
  .common-title {
    margin-bottom: 16px;
  }
  .modal-text {
    font-size: 16px;
    margin-bottom: 45px;
  }
  .material-input {
    width: 100%;
    margin-bottom: 24px;
  }
  .btn-wrap {
    .ant-btn {
      height: 41px;
    }
    .ant-btn-primary {
      margin-right: 16px;
    }
  }
}

.comfirm-modal {
  text-align: center;
  .common-title {
    font-size: 24px;
    margin-bottom: 8px;
    font-family: "LibreBaskerville-bold";
  }
  .modal-text {
    font-size: 16px;
  }
  img {
    width: 88px;
    margin-top: 28px;
  }
  .btn-wrap {
    .flex-center-container;
    margin-top: 24px;
    .ant-btn {
      height: 41px;
    }
    .ant-btn-primary {
      margin-right: 16px;
    }
  }
}
.chat-pop {
  .msg-menu-item {
    font-family: "Lato";
    font-size: 16px;
    line-height: 160%;
    color: @primary-dark;
    img {
      margin-right: 16px;
    }
  }
}
