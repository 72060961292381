@import "../theme.less";
.award-item {
  display: flex;
  min-height: 43px;
  align-items: flex-start;
  .icon-award {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    margin-top: 5px;
  }
  .info-sec {
    display: flex;
    flex-direction: column;
    flex: 1;
    .award-title {
      font-family: "Lato-bold";
      font-size: 14px;
      color: @primary-dark;
      line-height: 160%;
    }
    .issue {
      font-family: "Lato";
      font-size: 12px;
      line-height: 140%;
      color: @dark-grey;
    }
  }
  .icon-edit {
    width: 15px;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 20px;
    margin-top: 5px;
  }
  .icon-delete {
    width: 15px;
    cursor: pointer;
    margin-top: 5px;
  }
}
