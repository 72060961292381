@import "../../theme.less";
.preview-container {
  overflow: auto;
  height: calc(100vh - 80px);
  margin-top: @create-header-height;
  @media @mobile {
    height: calc(100vh - 104px);
    margin-top: 104px;
  }
  .top-sec {
    width: 1156px;
    margin: 24px auto;
    display: flex;
    @media @mobile {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 24px 0;
    }
    .space {
      flex: 1;
      @media @mobile {
        display: none;
      }
    }
    .desktop {
      height: 42px;
      padding: 0 12px;
      background-color: @grey;
      cursor: pointer;
      border-radius: 4px;
      color: white;
      font-family: "Lato";
      font-weight: 800;
      font-size: 12px;
      line-height: 42px;
      @media @mobile {
        display: none;
      }
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
    .icon-mobile {
      display: inline-block;
      width: 42px;
      height: 42px;
      padding: 0 15px;
      background-color: @light;
      border-radius: 4px;
      margin-left: 8px;
      cursor: pointer;
      @media @mobile {
        display: none;
      }
    }
    .note {
      line-height: 42px;
      padding: 0 16px;
      background-color: @light;
      border-radius: 4px;
      font-family: "Lato";
      font-size: 14px;
      color: @dark-grey;
      @media @mobile {
        line-height: 26px / @pv;
        padding: 0 16px / @pv;
        font-size: 12px / @pv;
      }
    }
  }
  .preview-wrap {
    width: 1156px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    @media @mobile {
      width: 100vw - 60px / @pv;
    }
  }
}
