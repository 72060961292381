@import "../../../theme.less";

.admin-school-container {
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  width: 1000px;

  i {
    font-style: normal;
  }

  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    color: @primary-dark;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media @mobile {
      flex-flow: column nowrap;
      align-items: flex-start;

      .title-btn {
        margin-top: 16px;
      }
    }

    i,
    button {
      height: 40px;
      line-height: 40px;
      padding: 0px 16px;
      border: 1px solid rgba(45, 46, 61, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      align-items: center;
      letter-spacing: 0.04em;
      color: #2d2e3d;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      font-family: Lato;
      text-transform: inherit;
      cursor: pointer;

      &:first-child {
        margin-right: 12px;
      }

      &.filter-btn {
        width: 132px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
  }

  .list-wrapper {
    margin-top: 28px;

    @media @mobile {
      margin-top: 40px / @pv;
    }

    .mt-16 {
      margin-top: 16px;
    }

    .search-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      .search-component-container {
        flex: 2;
        max-width: 663px;

        .ant-select {
          width: 100%;

          .ant-select-selection--single {
            height: 56px;
            line-height: 56px;
            width: 100%;
          }

          .ant-select-selection__rendered {
            height: 56px;
            font-size: 16px;
          }

          .ant-select-selection-selected-value {
            height: 56px;
            line-height: 56px;
          }
        }
      }

      .date-sec {
        position: relative;

        img {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }
      }

      .btn-item {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        button {
          width: 132px;

          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }

        .filter-btn {
          height: 42px;
          line-height: 42px;
        }
        .clear-btn {
          width: 160px
        }

        .ml-16 {
          margin-left: 16px;
        }
      }

      .MuiTextField-root {
        width: 320px;
      }

      .redius-wrapper {
        width: 313px;
      }

      .ml-24 {
        margin-left: 24px;
      }

      .ant-input-affix-wrapper {
        width: 400px;
        margin-right: 24px;
        height: 56px;
      }

      .btn-secondary {
        height: 48px;
      }
    }

    .data-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-top: 16px;

      span {
        font-family: Libre Baskerville;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        padding: 16px;
        background-color: #fff;
        width: 19%;
        border-radius: 4px;

        i {
          display: block;
          width: 100%;
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }

    .avatar-name-inner {
      align-items: center;
    }

    .status-cell {
      span {
        display: inline-block;
        padding: 6px 8px;
        background-color: #83b23a;
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;

        &.active {
          background-color: #f1dd23;
          color: rgba(45, 46, 61, 0.5);
        }
      }
    }

    .list-item {
      .MuiTableContainer-root.list-table-wrap {
        margin-top: 6px;
      }
    }
  }

  .MuiTablePagination-root {
    margin-top: 16px;
  }
}

.common-filter {
  .modal-content {
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 160%;
    }

    .filter-field {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 16px;

      .material-input {
        flex: 1;

        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }

    .btn-add {
      margin-top: 8px;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
    }
  }
}

.chat-pop {
  .ant-dropdown-menu-item {
    span.msg-menu-item {
      img {
        margin-right: 12px;
      }
    }
  }
}