@import "../../theme.less";

.search-component-container{
  flex: 0 0 55vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @media @mobile {
     display: none;
  }
  .ant-input-affix-wrapper{
    flex:1;
    height: 3.88vw;
    font-size: 16px;
    &:first-child{
      margin-right: 16px;
    }
    .ant-input:not(:first-child){
      padding-left: 36px;
    }
    input{
      font-size: 16px;
    }
  }
  .select-wrapper{
    flex:1;
    max-width: 49%;
    position: relative;
    @media @mobile {
      height: 56px;
    }
    .ant-select-selection-selected-value{
      position: static;
    }
    .ant-select-selection__rendered{
      margin-left: 36px;
    }
    .anticon.anticon-environment{
      position: absolute ;
      font-size: 16px;
      left: 12px;
      top:51%;
      transform: translateY(-50%);
    }

    .ant-select-selection__placeholder{
      left:3px;
    }
  }
}

.mb-more-filter-panel{
   .search-component-container{
     @media @mobile {
       display: flex;
       flex-flow: column nowrap;
     }
     .ant-input-affix-wrapper{
       height: 56px;
       margin-top: 4.26vw;
     }
     .select-wrapper{
       min-width: 100%;
       margin-top: 4.26vw;
       font-size: 16px;
       .ant-select-selection__rendered{
         //position: static !important;
         top:0 !important;
         left:0 !important;
         height: 100% !important;

       }
       .ant-select-selection-selected-value{
         height: 100%;
         line-height: 14.8vw !important;
       }
     }
   }
}