.admin-sub-form {
  font-family: "Arial", sans-serif;
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  h2 {
    text-align: center;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }

  label {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #333;
    justify-content: center;
    margin: 10px;
  }

  .radio-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    .radio-button {
      width: 250px;
      border: 2px solid #ccc;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s, border-color 0.2s;
      display: inline-block;
    }

    .radio-button:hover {
      background-color: #f0f0f0;
    }

    .hidden-radio {
      display: none;
    }

    .radio-button.selected {
    color: white;
    border-color: #2d2e3d;
    background-color: #2d2e3d;
    }
  }

  .inputs label span {
    text-align: right;
    width: 175px;
  }

  .payment-amount {
    margin-left: 10px;
    color: green;
  }

  .notes {
    margin: 0 15%;
    label {
      flex-direction: column;
    }
  }

  input[type="text"],
  input[type="number"] {
    border: 1px solid #ccc;
    margin-left: 10px;
  }
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    margin-top: 5px;
  }

  input[type="radio"] {
    margin-right: 5px;
    cursor: pointer;
  }

  button {
    margin: 20px;
    padding: 12px 70px;
    background-color: #2d2e3d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #74768e;
  }

  @media (max-width: 768px) {
    .admin-sub-form {
      margin: 10px;
      padding: 15px;
    }
  }

  input:focus,
  textarea:focus {
    border-color: #2d2e3d;
    outline: none;
    box-shadow: 0 0 0 2px rgba(212, 99, 192, 0.25);
  }

  .error {
    color: red;
    font-size: 1.5em;
    margin: 20px;
    padding: 0;
  }
}
