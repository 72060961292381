@import "../../../theme.less";

.school-dashboard-stats {
  .title-sec {
    display: flex;
    align-items: center;
    .space {
      flex: 1;
    }
    .input {
      width: 189px;
      margin-left: 16px;
      @media @mobile {
        margin: 24px / @pv 0;
        width: 100%;
      }
    }
    .btn-add {
      height: 41px;
    }
    @media @mobile {
      flex-direction: column;
      align-items: flex-start;
      .space {
        display: none;
      }
    }
  }
  & > .bold-title {
    margin-bottom: 48px;
  }
  .overall-title {
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 46px;
  }
  .chart-options {
    margin-bottom: 24px;
    .flex-between-container();
    align-items: center;
    @media @mobile {
      flex-direction: column;
    }
    .range-picker {
      @media @mobile {
        margin-top: 16px / @pv;
      }
    }
    .tab-list {
      @media @mobile {
        width: 100%;
        overflow-x: auto;
        display: flex;
      }
      .scroll {
        width: 100%;
      }
    }
    .select-date {
      display: flex;
      @media @mobile {
        width: 100%;
        margin: 16px / @pv 0;
      }
    }
    .tab-btn {
      display: inline-block;
      white-space: nowrap;
      @media @mobile {
        width: auto;
        white-space: nowrap;
      }
    }
    .btn-secondary {
      height: 40px;
      margin-right: 8px;
      padding: 0 16px;
      @media @mobile {
        height: 42px / @pv;
      }
    }
    .material-date-picker {
      width: 189px;
      height: 40px;
      @media @mobile {
        width: auto;
        height: 41px / @pv;
        flex: 1;
      }
      &.MuiTextField-root .MuiInputBase-input {
        padding: 8px 12px;
        // height: 41px / @pv;
        margin: auto 0;
      }
    }
    .material-input {
      width: 278px;
      height: 56px;
    }
    .input {
      @media @mobile {
        width: 100%;
      }
    }
  }
  .chart-wrap {
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    @media @mobile {
      padding: 24px / @pv;
    }
    .data-type {
      .flex-between-container();
      align-items: flex-start;
      margin-bottom: 16px;
      @media @mobile {
        flex-direction: column;
      }
      .tab-card-wrap {
        display: flex;
        @media @mobile {
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .tab-card {
          width: 200px;
          height: 80px;
          background: @extra-light;
          padding: 16px;
          color: @primary-dark;
          line-height: 1.4;
          border-radius: 4px;
          margin-right: 8px;
          cursor: pointer;
          @media @mobile {
            height: 104px / @pv;
            width: calc(50% - 4px);
            margin: 0 0 8px 0;
          }
          p {
            font-size: 12px;
            margin-bottom: 8px;
          }
          div {
            font-size: 24px;
            font-family: "LibreBaskerville-Bold";
          }
          &.active {
            background: @grey;
            color: #fff;
          }
        }
      }
    }
    .current-data {
      line-height: 1.6;
      color: @rgba-grey;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      @media @mobile {
        flex-direction: column;
        align-items: flex-start;
      }
      .chart-name {
        font-size: 16px;
        color: @primary-dark;
        margin-right: 25px;
        font-family: "Lato-bold";
        @media @mobile {
          margin-bottom: 8px;
        }
      }

      .space {
        flex: 1;
      }
      .update {
        display: flex;
        align-items: center;
        color: @rgba-grey;
        @media @mobile {
          margin-top: 20px / @pv;
        }
        span {
          font-family: "Lato-bold";
        }
        img {
          width: 34px;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .dashboard-job-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 20px;
    margin: 46px 0 16px;
    @media @mobile {
      flex-flow: column nowrap;
      justify-content: flex-start;
    }
    & > span {
      font-family: Lato;
      font-size: 14px;
      color: rgba(45, 46, 61, 0.5);
      @media @mobile {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      button {
        height: 40px;
        line-height: 40px;
        padding: 0px 16px;
        border: 1px solid rgba(45, 46, 61, 0.1);
        box-sizing: border-box;
        border-radius: 4px;
        align-items: center;
        letter-spacing: 0.04em;
        color: #2d2e3d;
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        font-family: Lato;
        text-transform: inherit;
        cursor: pointer;
        &:first-child {
          margin-left: 16px;
        }
      }
    }
  }
  .dashboard-job-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    @media @mobile {
      flex-flow: column nowrap;
      justify-content: flex-start;
    }
    .canvas-search-item {
      width: 32%;
      height: 410px;
      @media @desktop {
        margin-right: 2%;
        &:last-child {
          margin-right: 0;
        }
      }
      @media @mobile {
        width: 100%;
      }
      .media-con {
        .media-video,
        .media-img,
        .name-ico {
          height: 215px;
        }
      }

      .info-con {
        padding: 18px;
        height: auto;

        .update-tag {
          right: 0;
        }
        .card-title {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 16px;
          margin-bottom: 12px;
          height: 44px;
        }
        .additions {
          width: auto;
          right: 18px;
          left: 18px;
          bottom: 18px;
          position: static;
        }
        .addition-line {
          height: 26px;
          line-height: 26px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          &:first-child {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
