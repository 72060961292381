@import "../../../theme.less";

.cm-sp-landing, .cm-sc-landing {
  h1 {
    font-family: "LibreBaskerville";
    font-size: 40px;
  }

  h4 {
    color: black;
  }

  .btn-primary {
    height: 48px;
    margin-bottom: 48px;
  }
}

.cm-sp-headline-root {
  background: black;
  display: flex;

  h1,
  h2 {
    color: white;
  }

  .cm-hero-header-txt {
    @media @mobile {
      h1 {
        font-size: 2.2em;
        text-align: center;
      }
    }
  }

  .cm-sp-hero-content {
    flex: 1;
    padding: 48px;

    .btn-primary {
      height: 52px;
      background: white;
      color: black;
      margin-bottom: 0;
    }

    .material-input {
      margin-right: 8px;

      @media @mobile {
        width: 90vw;
        margin-bottom: 16px;
      }
    }


  }

  .cm-sp-hero-img-wrapper {
    width: 640px;
    position: relative;

    @media @mobile {
      width: 100%;
      padding-bottom: 30px;
    }
  }

  .cm-studentpro-vid {
    width: 400px;
    margin-right: 20px;
    @media @mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  .cm-sp-hero-img {
    width: 100%;
  }

  .cm-sp-hero-overlay {
    position: absolute;
    bottom: 0;
    left: 38%;
  }
}

.cm-sp-brands {
  background: #f2f2f2;
  padding: 12px;

  h4 {
    color: gray;
    font-size: 14px;
  }
}

.cm-sp-logos-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  img {
    width: 74px;
    height: auto;
    object-fit: contain;
    margin: 16px;
  }
}

.cm-sp-headline {
  display: flex;
  justify-content: center;

  h1 {
    margin-bottom: 0;
    min-width: 600px;

    @media @mobile {
      min-width: initial;
    }
  }

  p {
    max-width: 600px;
    margin: 8px;
  }

  img {
    height: 128px;
    margin: 8px;
  }
}

.cm-sp-well {
  background: white;
  padding: 16px;
}

.cm-sp-opp-wrapper {
  display: flex;
  justify-content: center;
}

.cm-sp-opp {
  border-top: 1px solid black;
  padding: 8px;
  margin: 24px;
  max-width: 300px;

  p {
    color: black;
    font-size: 14px;
  }

  a {
    text-decoration: underline;
  }
}

.cm-sp-watch-wrap {
  display: flex;
  background: black;
  color: white;
  justify-content: center;
  margin-bottom: 24px;

  .cm-sp-watch-target {
    max-height: 300px;
    cursor: pointer;
    order: 2;
  }
}

.cm-sp-watch-content {
  color: white;
  padding: 16px;
  min-width: 400px;

  h2 {
    color: white;
    max-width: 300px;
  }

  img {
    height: 48px;
    margin-right: 8px;
  }

  p {
    max-width: 300px;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

.cm-sp-canvas-tile {
  width: 300px;
  height: 300px;
  margin: 24px;
  box-shadow: 8px 8px 8px lightgray;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all;
  position: relative;

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

.cm-sp-canvas-tile-img {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.cm-sp-canvas-tile__footer {
  padding: 4px;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    margin-bottom: 0;
  }
}

.cm-sp-thrive-root {
  background: white;
  padding: 16px;

  h1 {
    max-width: 600px;
  }

  p {
    margin: 0;
  }

  .cm-sp-headline-root {
    margin-bottom: 16px;
  }
}

.cm-sp-thrive-item {
  margin: 16px 24px;

  @media @mobile {
    width: 90vw;
  }

  img {
    height: 40px;
    width: 40px;
    margin-right: 8px;
  }
}

.cm-sp-faq {
  .cm-sp-headline {
    margin-top: 32px;
    margin-bottom: -24px;
  }
}

.cm-sp-canvas-tile-overlay,
.cm-sp-canvas-tile-type,
.cm-sp-canvas-tile-type--opportunity {
  position: absolute;
  top: 40%;
  left: 25%;
  background: rgba(255, 255, 255, 0.7);
  color: black;
  text-transform: uppercase;
  padding: 8px;
}

.cm-sp-canvas-tile-type, .cm-sp-canvas-tile-type--opportunity {
  bottom: 22%;
  left: 12px;
  top: initial;
  font-size: 11px;
  border-radius: 4px;
  padding: 4px;
}

.cm-sp-canvas-tile-type--opportunity {
  background: rgba(124, 180, 66, 0.85);
  color: white;
  text-transform: initial;
}

.cm-sp-canvas-ribbon {
  position: absolute;
  left: 16px;
  top: 0;
  width: 36px;
}



.cm-sp-scrolling-ipad {
  position: relative;
  width: 250px;
  height: 300px;
  padding: 14px;

  @media @mobile {
    width: 37vw;
    height: 47vw;
    padding: 10px;
  }

  &__backdrop {
    .absolute-full();
    opacity: 0.5;
  }

  &__screen {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
    border-radius: 8px;
    z-index: 9;

    img {
      transition: all 1.75s ease-in-out;
    }
  }

}