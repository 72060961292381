@import "../../../theme.less";
  
.read-or-hide{
  color: rgb(192,192,192);
  cursor: pointer;
}

.cm-home-testimonials {
  background: #9E8E9F;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;

  @media @mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }
}

.cm-home-testimonial {
  margin-right: 32px;
  width: 357px;
  position: relative;

  @media @mobile {
    margin-right: initial;
    padding-top: 20px;
  }

  &__name, &__title, &__content {
    position: relative;
  }
  &__name {
    font-size: 18px !important;
    font-family: "Lato-bold";
    color: @pseudo-dark;
  }
  &__title {
    font-size: 18px !important;
    font-family: "Lato";
    color: @pseudo-dark;
    margin-bottom: 20px !important;
  }
  &__content {
    font-family: "LibreBaskerville";
    font-size: 21px;
  }
  &__avatar {
    height: 200px;
    width: 200px;
    border-radius: 100px;
  }
  &__inner {
    width: 100%;
    background: white;
    border-radius: 24px;
    padding: 30px;
    padding-top: 100px;
    margin-top: -100px;
  }
  &__tail {
    height: 36px;
    margin-left: 72%;
    margin-top: -8px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  color: white;
  cursor: pointer;
}

.video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 0 0 24px 24px;
}

.video-overlay .text {
  margin: 10px 0;
  font-style: italic;
}

.fullscreen-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  color: white;
  cursor: pointer;
}