@import "../../theme.less";

.info-container {
  & > .common-title {
    font-family: "LibreBaskerville-bold";
  }
  & > .btn-primary,
  & > .btn-secondary {
    height: 50px;
    margin-top: 24px;
  }
  .about-wrap {
    & + .about-wrap {
      margin-top: 24px;
    }
    .title-with-icon {
      justify-content: space-between;
      & > span {
        flex: 1;
      }
      .del-edu {
        border-radius: 4px;
        img {
          width: 20px;
          height: 20px;
          margin: 0;
        }
      }
    }
    .school-name-input {
      margin-bottom: 0;
    }
  }
  .material-input,
  .material-date-picker {
    width: 100%;
    margin-bottom: 16px;
  }
  .yes-or-no-wrap {
    .flex-between-container();
    .title {
      font-family: "Lato-bold";
    }
    .select-wrap {
      span {
        background-color: @light;
        display: inline-block;
        color: @grey;
        font-weight: 800;
        border-radius: 4px;
        cursor: pointer;
        &.active {
          background-color: @grey;
          color: #fff;
        }
      }
    }
  }
  .permit-wrap {
    display: flex;
    .material-input {
      width: 90%;
    }
    .del-icon {
      flex: 1;
      height: 58px;
      .flex-center-container();
      img {
        cursor: pointer;
      }
    }
  }
  .awards-list-wrap {
    .add-btn {
      text-align: right;
    }
    .award-item-wrap {
      .award-item {
        flex: 1;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        .item-icon,
        .handle-item {
          .flex-center-container();
        }
        .item-content {
          flex: 1;
          h2 {
            font-weight: 800;
            line-height: 1.6;
            color: @primary-dark;
          }
          div {
            line-height: 1.4;
            color: @dark-grey;
          }
        }
      }
    }
  }
  @media @desktop {
    width: 920px;
    min-height: @create-content-height;
    margin: @create-header-height auto 0;
    padding: 48px 0;
    & > .common-title {
      margin-bottom: 50px;
    }
    .yes-or-no-wrap {
      height: 56px;
      .title {
        font-size: 16px;
      }
      .select-wrap {
        span {
          padding: 8px 12px;
          font-size: 14px;
        }
      }
    }
    .awards-list-wrap {
      margin-top: 50px;
      .title-with-icon {
        margin-bottom: 20px;
      }
      .add-btn button {
        height: 40px;
      }
      .award-item-wrap {
        margin-bottom: 16px;
        .flex-between-container();
        .award-item {
          padding: 15px 25px;
          .item-icon {
            height: 48px;
            img {
              width: 24px;
            }
          }
          .item-content {
            margin: 0 25px;
            h2 {
              font-size: 16px;
              margin-bottom: 2px;
            }
            div {
              font-size: 12px;
            }
          }
          .handle-item {
            height: 48px;
            img {
              width: 18px;
            }
          }
        }
        .btn-secondary {
          height: 42px;
          margin-left: 24px;
        }
      }
    }
  }
  @media @mobile {
    margin: 136px / @pv 30px / @pv 25px / @pv;
    & > .common-title {
      margin-bottom: 32px / @pv;
    }
    & > .btn-primary,
    & > .btn-secondary {
      height: 50px / @pv;
      margin-top: 24px / @pv;
    }
    .about-wrap .title-with-icon .del-edu img {
      width: 20px / @pv;
      height: 20px / @pv;
    }
    .yes-or-no-wrap {
      margin-bottom: 16px / @pv;
      .title {
        font-size: 16px / @pv;
        margin-right: 16px / @pv;
        flex: 1;
      }
      .select-wrap {
        width: 100px / @pv;
        span {
          padding: 8px / @pv 12px / @pv;
          font-size: 14px / @pv;
        }
      }
    }
    .awards-list-wrap {
      margin-top: 35px / @pv;
      .title-with-icon {
        margin-bottom: 18px / @pv;
      }
      .add-btn button {
        height: 40px / @pv;
      }
      .award-item-wrap {
        margin-bottom: 16px / @pv;
        .award-item {
          padding: 15px / @pv 25px / @pv;
          .item-icon {
            height: 48px / @pv;
            img {
              width: 24px / @pv;
            }
          }
          .item-content {
            margin: 0 25px;
            h2 {
              font-size: 16px / @pv;
              margin-bottom: 2px / @pv;
            }
            div {
              font-size: 12px / @pv;
            }
          }
          .handle-item {
            height: 48px / @pv;
            img {
              width: 18px / @pv;
            }
          }
        }
        .btn-secondary {
          height: 42px;
          margin-top: 16px / @pv;
        }
      }
    }
  }
}
