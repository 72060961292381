@import "../theme.less";
.video-modal-wrap {
  .video-modal-root {
    width: 100%;
    height: 100%;
    .video-js {
      width: 100%;
      height: 100%;
    }
    .youtube-video {
      width: 100%;
      height: 100%;
    }
  }
  .ant-modal {
    width: 800px !important;
    height: 600px !important;
    background-color: transparent;
    .ant-modal-content {
      width: 100%;
      height: 100%;
      .ant-modal-body {
        background-color: transparent;
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
