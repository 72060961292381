@import "../../../theme.less";
.edit-note-root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .top-sec {
    height: 64px;
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    .title {
      font-family: "Lato-Bold";
      font-size: 20px;
      line-height: 160%;
      color: @primary-dark;
    }
  }
  .content {
    padding: 24px;
    width: 100%;
    .input {
      width: 100%;
    }
  }
  .btn-add {
    margin-bottom: 24px;
    margin-left: 24px;
    height: 41px;
  }
}
.edit-note-modal-wrap {
  .ant-modal {
    width: 400px !important;
    .ant-modal-body {
      background-color: #f4f5f5;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;
    }
  }
}
