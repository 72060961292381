@import "../theme.less";
.cr-textarea {
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  //.label {
  //  font-family: "Lato";
  //  font-size: 16px;
  //  color: @grey;
  //  margin-left: 12px;
  //  margin-top: 12px;
  //  flex: 1;
  //  transition: all ease 0.3s;
  //  &.inputFocused {
  //    flex: none;
  //    font-family: "Lato-bold";
  //    font-size: 10px;
  //    line-height: 140%;
  //    color: @dark-grey;
  //    margin-top: 10px;
  //    text-transform: uppercase;
  //    transition: all ease 0.3s;
  //  }
  //}
  //.text-area {
  //  border: none !important;
  //  font-family: "Lato";
  //  color: @primary-dark;
  //  font-size: 16px;
  //  padding-bottom: 24px;
  //}
  textarea{
    min-height: 58px !important;
    line-height: 28px !important;
    padding-bottom: 20px;
  }
  .limit {
    font-family: "Lato";
    font-size: 12px;
    color: @grey;
    position: absolute;
    right: 16px;
    bottom: 24px;
  }
}
