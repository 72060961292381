@import "../theme.less";
.accordian-btn{
  height: 42px;
  padding: 0 16px;
  font-size: 14px;
  background: white;
  color: #2d2e3d;
  font-family: "Lato";
  font-weight: 800;
  letter-spacing: 0.04em;
  border: none;
  border-radius: 4px;
  margin: 10px 0;
}
.arrow{
  transition: transform 0.5s;
  margin-left: 20px;
}
.arrow.rotate{
  transition: transform 0.5s;
  transform: rotate(180deg);
}
.panel {
  span{
    display: block;
  }
}
.menu-modal {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .title-sec {
    height: 80px / @pv;
    flex: 0 0 80px / @pv;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    padding: 0 30px / @pv;
    .logo {
      width: 103px / @pv;
    }
    .close {
      width: 24px / @pv;
    }
  }
  .top-sec {
    display: flex;
    flex-direction: column;
    padding: 30px / @pv 30px / @pv;
    span {
      font-family: "Lato";
      font-weight: 800;
      font-size: 13px / @pv;
      line-height: 120%;
      margin: 15px / @pv 0;
      letter-spacing: 0.04em;
      padding-left: 20px;
      //text-transform: uppercase;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
    }
  }
  .divider {
    width: calc(100% - 60px);
    height: 1px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .nested-divider{
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .bottom-sec {
    padding: 12px / @pv 30px / @pv;
    display: flex;
    flex-direction: column;
    flex: 1;
    .btn-start {
      width: 159px / @pv;
      margin-top: 5px / @pv;
      span {
        color: @primary-dark;
      }
    }
    .btn-start-free {
      margin-top: 5px / @pv;
      span {
        color: @primary-dark;
        margin: 0 !important;
      }
    }
    span {
      font-family: "Lato";
      font-weight: 800;
      font-size: 16px / @pv;
      line-height: 120%;
      margin: 12px / @pv 0;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      &.notification-active {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          right: 16px / @pv;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 12px / @pv;
          height: 12px / @pv;
          border-radius: 12px / @pv;
          background-color: #ff6b00;
        }
      }
      img {
        width: 40px / @pv;
        height: 40px / @pv;
        border-radius: 40px / @pv;
        overflow: hidden;
        margin-right: 16px / @pv;
        object-fit: cover;
      }
    }
    .sign-out {
      color: #ff6b00;
    }
  }
  .footer-sec {
    display: flex;
    justify-content: space-between;
    height: 80px / @pv;
    flex: 0 0 80px / @pv;
    padding: 0 30px / @pv;
    align-items: center;
    .left-wrap {
      display: flex;
      span,
      a {
        font-family: "Lato";
        font-weight: 800;
        font-size: 12px / @pv;
        line-height: 120%;
        margin-right: 24px / @pv;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .right-wrap {
      img {
        width: 24px / @pv;
        margin-left: 24px / @pv;
      }
    }
  }
}
.menu-modal-style {
  background-color: black;
  &::before {
    height: 0 !important;
    display: none !important;
  }
  .ant-modal {
    margin: 0;
    padding-bottom: 0;
    max-width: 100vw;
    width: 100vw !important;
    .ant-modal-content {
      height: 100vh !important;
      width: 100% !important;
      background-color: black !important;
      .ant-modal-body {
        padding: 0px;
        height: 100%;
      }
    }
  }
}

.profile-img {
  width: 30px;
  border-radius: 30px;
  margin-right: 10px;
}
