@import '../../theme.styl';

.loading-spin {
    poa-center();
    position: fixed !important;
}

.homepage-canvases {
    position: relative;
    pc({
        margin: 48px 104px 59px
    });
    mobile({
        margin: 32px 30px
    });

    .search-item{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        .item-title{

            flex: 1;

            font-family: LibreBaskerville;
            font-weight: bold;
            color: #2D2E3D;
            text-transform: capitalize;
            pc({
                font-size: 32px,
                line-height: 120%,
                letter-spacing: -1px,
                 margin-right:12px
            });
            mobile({
                font-size: 24px,
                line-height: 140%
            });
        }
        .search-component-container{
            pc({
               flex:2

            })
        }
    }

    .title {
        font-family: LibreBaskerville;
        font-weight: bold;
        color: #2D2E3D;
        text-transform: capitalize;
        pc({
            font-size: 32px,
            line-height: 120%,
            letter-spacing: -1px
        });
        mobile({
            font-size: 24px,
            line-height: 140%
        });
    }

    .ant-select-selection, .ant-select-selection__rendered {
        pc({
            height: 56px
        });
    }

    .ant-select-disabled .ant-select-selection {
        background: #eeeeee;
    }

    .ant-select-selection-selected-value {
        pc({
            line-height: 56px,
            position: relative,
            top: 4px,
            left: 4px
        });
    }

    .item-tag {
        position: absolute;
        pc({
            left: 12px,
            top: 10px,
            font-size: 10px
        });
        /* Content */
        height: 14px;
        /* Webapp / Label */
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        line-height: 140%;
        /* identical to box height, or 14px */
        letter-spacing: 0.05em;
        text-transform: uppercase;
        /* Grey / 1 */
        color: #61626D;
    }

    .ant-select {
        width: 100%;
    }

    .filter-con {
        position: relative;
        pc({
            display: flex,
            margin: 32px -104px 0,
            padding: 32px 104px,
            flex-wrap: nowrap,
            justify-content: space-between,
            background-color:#EAEAEC
        });
        mobile({
            margin: 24px 0
        });

        .item {
            position: relative;
            &:not(:last-child){
                pc({
                   margin-right: 16px
                });
            }
            pc({
                height: 56px,
                display: inline-block,
                flex: 1
            });
            mobile({
                height: 56px,
                display: none
            });
            &.select-btns{
                display:flex;
                flex-flow: row nowrap;
                justify-content: space-between
                margin-right:0;
                .item-select{
                    flex:1;
                }
                mobile({
                 display: none
                })

            }
        }
        
        .search-input{
            width: 100%;
            pc({
                height: 56px,
            });
            mobile({
                width: 100%,
                height: 56px,
                display: none
            });

            .MuiFormControl-root {
                height: 100%;
            }

            .material-input .MuiFilledInput-root {
                pc({
                    height: 100%
                });
            }

            .ant-input-suffix {
                pointer-events: none;
            } 
        }

        .keyword-input {
            pc({
                flex: 3.17
            });
            mobile({
                width: 100%,
                display: block
            });

            .MuiFormControl-root {
                height: 100%;
            }

            .material-input .MuiFilledInput-root {
                pc({
                    height: 100%
                });
            }

            .ant-input-suffix {
                pointer-events: none;
            }
        }

        .input-wrap{
            position relative;
        }

        .keyword-location-switch {
            position: absolute;
            z-index: 2;
            transform: translate(0, -50%);
            pc({
                top: 28px,
                right: 12px
            });

            &.without-location {
                pc({
                    left: 560px
                });
            }

            mobile({
                display: none
            });
        }

        .filter-btn {
            pc({
                padding-top: 6px
            });

            img {
                pc({
                    width: 20px,
                    height: 22px,
                    position: relative,
                    left: -6px
                });
            }

            &.clear-btn {
                margin-right: 0;
            }
        }

        .filter-btn-mb {
            pc({
                display: none
            });
            mobile({
                position: absolute,
                z-index: 2,
                display: block,
                transform: translate(100%, 0),
                top: 10px,
                right: 42px,
                width: 34px,
                height: 34px,
                padding: 6px 11px,
                border-radius: 4px,
                background: #EAEAEC
            });
        }
        .item-search-btn{
            pc({
               display:none
            })
            mobile({
            font-weight: bold,
            width: 100%,
            height: 48px,
            font-size: 14px,
            font-family: Lato,
            })
        }
    }

    .quick-jobtypes {
        display: flex;
        position: relative;
        pc({
            flex-wrap: wrap,
            margin-bottom: 32px
        });
        mobile({
            overflow: auto
        });

        .job-type {
            display: inline-block;
            flex-direction: row;
            text-align: center;
            background: #EAEAEC;
            border-radius: 4px;
            flex: 0;
            pc({
                padding: 12px 16px
            });
            mobile({
                padding: 12px 16px
            });

            &.ant-tag-checkable-checked {
                background: #96979E;
                color: #ffffff;
            }
        }
    }

    .mb-to-right {
        transform: translate(100%, -100%);
        pc({
            display: none
        });
        mobile({
            background-color: extra-light !important,
            position: absolute,
            border: none,
            width: 34px,
            height: 40px,
            top: 168px,
            right: 30px
        });
    }

    .more-filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        pc({
            border-top: 1px solid rgba(45, 46, 61, 0.1),
            margin: 0 -104px,
            padding: 32px 104px 16px,
            background-color: #EAEAEC
        });
        mobile({
            display: none
        });

        .item {
            width: 32%;
            position: relative;
            pc({
                margin-bottom: 10px
            });
            .school-name-input, .MuiFormControl-root, .MuiInputBase-root {
                height 100%;
            }
            .school-name-input {
                .MuiFormLabel-root {
                    font-size: 0.69vw;
                    color: #61626d;
                    font-weight: bold;
                    transform: translate(0.83vw, 0.69vw);
                }
                input {
                    padding-left: 11px;
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 14px;
                }
                .MuiInputBase-root:not(:hover) {
                    border: 1px solid #d9d9d9;
                }
            }
        }

        .ant-select-selection__rendered {
            pc({
                height: 40px,
                position: relative,
                top: 4px
            });
        }
    }

    .list-con {
        position: relative;
        pc({

            margin-top: 32px,
            padding-bottom: 30px
        });

        .card-list {
            mobile({
                margin-top: 10px
            });
        }

        .bottom-pagination {
            position: absolute;
            right: 0;
            bottom: 0;
            mobile({
                display: none
            });
        }

        .mb-load-more {
            pc({
                display: none
            });
            mobile({
                display: block,
                height: 41px,
                margin: -10px auto
            });
        }
    }
}

.mb-more-filter-panel.ant-modal-wrap {
    top: 0;
    position: fixed;
    background: grey-bg;
    width: 100vw;

    .ant-modal {
        top: 0;
        width: 100% !important;
    }

    .ant-modal-content {
        box-shadow: none;
        mobile({
            padding: 32px 30px 20px,
            margin: 0 auto
        });

        .title {
            font-family: LibreBaskerville;
            font-weight: bold;
            color: #2D2E3D;
            mobile({
                font-size: 24px,
                line-height: 140%
            });
        }

        .close-btn {
            position: absolute;
            mobile({
                top: 40px,
                right: 30px
            });
        }

        .keyword-location-switch {
            mobile({
                margin-top: 24px
            });
        }

        .item {
            position: relative;
            mobile({
                height: 56px,
                margin-top: 16px,
                width: 100%
            });

            .ant-select-disabled .ant-select-selection {
                background: #eeeeee;
            }
            &.select-btns{

                mobile({
                   display:flex,
                    flex-flow: row nowrap,
                    justify-content: space-between,
                     margin-right:0
                })
                button {
                    flex:1;
                    margin-top 0;
                    margin-left 0;
                    height: 52px;
                    line-height:52px;
                    padding-top 0;
                    &:last-child{
                        margin-left:16px;
                    }
                    &:first-child{
                        margin-left:0px;
                    }
                }

            }
        }

        .item-tag {
            position: absolute;
            mobile({
                left: 12px,
                top: 10px,
                font-size: 10px
            });
            /* Content */
            height: 14px;
            /* Webapp / Label */
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            line-height: 140%;
            /* identical to box height, or 14px */
            letter-spacing: 0.05em;
            text-transform: uppercase;
            /* Grey / 1 */
            color: #61626D;
        }

        .filter-btn {
            mobile({
                margin-top: 20px,
                padding-top: 6px,
                padding-left: 10px,
                width: 46%,
                height: auto
            });

            img {
                mobile({
                    width: 20px,
                    height: 22px,
                    position: relative,
                    left: -4px
                });
            }

            &.clear-btn {
                margin-left: 6%;
            }

            .action-btn {
                display: block;
                background: #000;
                color: #ffffff;
                margin: 20px auto;
                height: 52px;
                line-height: 52px;
                width: 100%;
                text-align: center;
            }
        }

        .ant-btn.lato-bold.action-btn {
            display: block;
            background: #000;
            color: #ffffff;
            margin: 20px auto;
            height: 52px;
            line-height: 52px;
            width: 100%;
            text-align: center;
        }

        .ant-select {
            width: 100%;
        }

        .ant-select-selection, .ant-select-selection__rendered {
            mobile({
                height: 56px
            });
        }

        .ant-select-selection-selected-value {
            mobile({
                line-height: 56px
            });
        }

        .ant-select-selection__rendered {
            mobile({
                height: 40px,
                position: relative,
                top: 4px
            });
        }

        .quick-jobtypes {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            mobile({
                margin-bottom: 32px,
                overflow: auto
            });

            .job-type {
                display: inline-block;
                flex-direction: row;
                text-align: center;
                background: #EAEAEC;
                border-radius: 4px;
                flex: 0;
                mobile({
                    padding: 12px 16px,
                    margin-top: 12px
                });

                &.ant-tag-checkable-checked {
                    background: #96979E;
                    color: #ffffff;
                }
            }
        }

        .mb-more-filters {
            mobile({
                margin: 17px -30px,
                padding: 24px 30px
            });
            background: #EAEAEC;
        }
    }
}

.mb-more-filter-panel, .homepage-canvases {
    .keyword-input {
        .ant-input {
            padding-left: 30px;
        }

        .ant-input-suffix {
            left: 12px;
        }

        pc({
            flex: 3.17
        });
        mobile({
            width: 100%,
            display: block
        });

        .MuiFormControl-root {
            height: 100%;

            .material-input .MuiFilledInput-root {
                height: 100%;
            }

            .MuiFilledInput-input {
                padding-right: 260px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                width: 100%;
                mobile({
                    padding-right: 20px
                });
            }
        }
    }

    .keyword-location-switch {
        .ant-radio-button-wrapper {
            min-width: 120px;
            height: 33px;
            mobile({
                width: 88px
            });
            background-color: #ffffff;
            border: none;
        }

        .ant-radio-button-wrapper-checked {
            background-color: #EAEAEC;
            box-shadow: none;

            &::before {
                display: none;
            }
        }
    }

    .lato-bold {
        font-family: Lato;
        font-weight: 800;
        color: #2D2E3D;
        font-size: 14px;
    }
}