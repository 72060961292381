@import "../../theme.less";
.canvas-detail-root {
  .canvas-detail-header {
    z-index: 2;
    @media @mobile {
      width: 100vw;
    }
  }
  .canvas-detail-content {
    margin-top: @header-height;
    @media @mobile {
      margin-top: @mibile-header-height;
    }
  }
}

.canvas-detail-content.cm-loreal, .opportunity-detail-root.cm-loreal > .content-wrap {
  font-family: 'LOREAL_PRO_OFFICE' !important;
  h1, h2, h3, h4, h5 {
    font-family: 'LOREAL_PRO_OFFICE-Bold' !important;
    font-weight: 700;
  }
  div, span, .info-title, .canvas-title {
    font-family: 'LOREAL_PRO_OFFICE' !important;
  }
  & > * {
    font-family: 'LOREAL_PRO_OFFICE' !important;
  }
}
