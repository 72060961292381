@import "../theme.less";
.location-detail-root {
  display: flex;
  flex-direction: column;
  .divider {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: @rgba-grey-light;
  }
  .location-title {
    margin-bottom: 24px;
    font-family: "Lato";
    font-weight: 800;
    font-size: 16px;
    line-height: 160%;
    color: @primary-dark;
  }
  .address-input {
    margin-bottom: 16px;
    width: 100%;
  }
  .footage-wrap {
    position: relative;
    height: 56px;
    flex: 1;
    margin-bottom: 16px;
    .footage-input {
      width: 100%;
    }
    .unit-btn {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 4px;
      line-height: 29px;
      height: 29px;
      background-color: @extra-light;
      padding-left: 6px;
      cursor: pointer;
      img {
        width: 8px;
        margin-left: 8px;
        margin-right: 10px;
      }
    }
  }
}
