@import "../theme.less";
.channel-name-modal-wrap {
  .channel-name-modal-root {
    border-radius: 4px;
    overflow: hidden;
    background-color: #f4f5f5;
    .title-sec {
      height: 64px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      align-items: center;
      background-color: white;
      .title {
        font-family: "Lato-bold";
        font-size: 20px;
        color: @primary-dark;
      }
      .close {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      .desc {
        margin-bottom: 16px;
        font-family: "Lato";
        font-size: 16px;
        line-height: 160%;
        color: rgba(45, 46, 61, 0.75);
      }
      .input {
        margin-bottom: 24px;
      }
      .btn-confirm {
        height: 41px;
      }
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}
