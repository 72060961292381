@import "../../../theme.less";
.bookmarks-all-root {
  display: flex;
  width: 890px;
  margin: 24px auto;
  flex-direction: column;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    margin-top: 16px;
    color: @primary-dark;
  }
  .list {
    margin: 40px 0;
  }
  .btn-add {
    height: 56px;
  }
}
