@import "../../../theme.less";
.opportunity-apply-modal {
  .ant-modal-content {
    position: relative;
    padding: 24px;
    border-radius: 8px;
    background-color: @extra-light;
  }
  .apply-opportunity-content {
    display: flex;
    flex-direction: column;
    .title {
      font-family: "LibreBaskerville-bold";
      font-size: 32px;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: -1px;
      color: @primary-color;
    }
    .desc,
    .tips {
      font-family: "Lato";
      font-size: 16px;
      line-height: 160%;
      color: rgba(45, 46, 61, 0.75);
    }
    .desc {
      margin-top: 16px;
    }
    .apply-info {
      margin-top: 48px;
    }
    .tips {
      margin-top: 24px;
    }
    .btn-wrap {
      display: flex;
      align-items: center;
      margin-top: 48px;
      .modal-btn {
        padding: 0 16px;
        height: 51px;
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
  }
  &.sent-result {
    .apply-opportunity-content {
      align-items: center;
      .close {
        position: absolute;
        cursor: pointer;
        height: 18px;
        top: 28px;
        right: 28px;
      }
      .ic-check {
        height: 88px;
        margin-top: 28px;
        width: 88px;
      }
    }
  }
  @media @mobile {
    .ant-modal-content {
      width: auto;
      padding: 24px / @pv 32px / @pv;
      .ant-modal-body {
        padding: 0;
      }
      .apply-opportunity-content {
        .title {
          font-size: 24px / @pv;
        }
        .desc,
        .tips {
          margin-top: 16px / @pv;
          font-size: 14px / @pv;
        }
        .apply-info {
          margin-top: 24px / @pv;
        }
        .btn-wrap {
          margin-top: 32px / @pv;
          .modal-btn {
            padding: 0 16px / @pv;
            height: 51px / @pv;
            font-size: 16px / @pv;
            &:not(:last-child) {
              margin-right: 24px / @pv;
            }
          }
        }
      }
    }
  }
}

.opportunity-apply-modal {
  margin: 0 10% !important;
  width: 80% !important;
  border-radius: 13px !important;
  left: 0 !important;
  transform: translate(0, 0) !important;
}
