@import '../theme.styl';

.canvas-search-item {
    background-color: #fff;
    margin-bottom: 48px;
    transition: transform, 0.3s;

    &:hover {
        shadow-box();
        transform: translateY(-16px);
    }

    pc({
        width: 400px,
        height: 536px
    });
    mobile({
        width: 312px,
        height: auto
    });

    .media-con {
        position: relative;

        .media-video, .media-img, .name-ico {
            display: block;
            pc({
                width: 100%,
                height: 330px,
                object-fit: cover
            });
            mobile({
                width: 100%,
                height: 300px,
                object-fit: cover
            });
        }

        .name-ico {
            text-transform: uppercase;
            font-size: 66px;
            text-align center
            padding-top: 100px
            color: white;
            background #000;
        }

        .video-tag {
            position: absolute;
            pc({
                top: 16px,
                right: 16px
            });
            mobile({
                top: 16px,
                right: 16px
            });
        }
    }

    .info-con {
        position: relative;
        width: 100%;
        pc({
            padding: 24px,
            height: 223px
        });
        mobile({
            padding: 24px,
            height: auto
        });

        .tag-con {
            height: 27px;
            position: relative;

            .ant-tag {
                //max-width: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .update-tag {
                position: absolute;
                pc({
                    right: 12px,
                    top: 0
                });
            }
        }

        .card-title {
            pc({
                font-family: Lato,
                font-weight: bold,
                font-size: 16px,
                line-height: 140%,
                letter-spacing: -0.5px,
                color: #2D2E3D,
                margin-top: 16px,
                white-space: nowrap,
                overflow: hidden,
                text-overflow: ellipsis
            });
            mobile({
                font-family: Lato,
                font-weight: bold,
                font-size: 16px,
                line-height: 140%,
                letter-spacing: -0.5px,
                color: #2D2E3D,
                margin-top: 16px,
                white-space: nowrap,
                overflow: hidden,
                text-overflow: ellipsis
            });
        }

        .additions {

            pc({
                width: 100%,
                position: absolute,
                left: 24px,
                bottom: 24px
            });
            mobile({

            });

            .addition-line {
                width: 100%;
                &:last-child{
                    mobile({
                       margin-top:12px
                    });
                    span.ico{
                        img{
                            width 100%;
                            height 100%;
                        }
                    }
                }
                pc({
                    height: 26px,
                    line-height: 160%,
                    margin-bottom: 8px
                });

                span.ico {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    overflow: hidden;
                    vertical-align: text-bottom;
                    text-align: center;
                }

                .name-ico {
                    background: #aaa;
                    width: 26px;
                    height: 26px;
                    line-height :26px;
                    display: inline-block;
                    text-align: center;
                    color: white;
                }

                span.txt {
                    display: inline-block;
                    padding-left: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 85%;
                }
            }
        }
    }
}