@import "../../theme.less";

.home-root .dashboard-content-wrap {
  display: flex;
  height: calc(100vh - @header-height);
  border-top: 1px solid @rgba-grey-light;
  margin-top: @header-height;
  @media @mobile {
    margin-top: @mibile-header-height;
    height: calc(100vh - @mibile-header-height);
  }
  .dashboard-sidebar-wrap {
    min-width: 15%;
    text-align: left;
    //flex-basis: 24.5%;
  }
  .dashboard-content {
    // padding: 24px 20px;
    flex: 1;
    height: 100%;
    overflow: auto;
    // @media @mobile {
    //   padding: 16px 30px;
    // }
    .dashboard-route-wrap {
      // max-width: 890px;
      height: 100%;
      margin: 0 auto;
    }
  }
}

.tab-btn {
  background: @light;
  padding: 12px 16px;
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0.04em;
  color: @primary-dark;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s;
  &.active {
    background: @grey;
    color: #fff;
  }
}
