@import "../../theme.less";
.change-pw-root {
  .change-pw-header {
    height: 84px;
    background-color: @extra-light;
    position: relative;
    img {
      width: 60%;
      margin: 30px 20%;
      @media @mobile {
        width: 80%;
        margin: 20px 10%;
      }
    }
  }
  .change-pw-wrap {
    .change-pw {
      margin: 130px auto 0;
      width: 515px;
      text-align: center;
      @media @mobile {
        width: 95%;
        margin: 50px auto 0;
      }
      .btn-sign-up {
        margin-top: 32px;
        height: 51px;
      }
      .title {
        font-family: "LibreBaskerville";
        font-size: 32px;
        text-align: center;
        line-height: 120%;
        letter-spacing: -1px;
        color: @primary-color;
      }
      .tips {
        margin-top: 48px;
        font-family: Lato;
        font-size: 16px;
        line-height: 160%;
        color: @primary-color;
        flex: none;
        order: 0;
        align-self: center;
      }
      .input {
        margin-top: 32px;
        width: 100%;
      }
      .send-email-btn {
        margin: 32px auto 0;
        text-align: center;
      }
      .sent-result-container {
        text-align: center;
        .ic-check {
          margin-top: 52px;
        }
        .tips {
          margin-top: 36px;
          .tips-email {
            font-weight: bold;
          }
        }
        .btn-sign-up {
          margin-top: 32px;
        }
        .send-again {
          margin-top: 24px;
          .resend-link {
            display: inline-block;
            border-bottom: 1px solid @rgba-grey;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.pw-criteria-shell {
  display: flex;
  justify-content: center;
  .pw-criteria {
    margin-top:10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .pw-criteria-item {
      transition: .5;
      padding: 2px 5px;
    }
    .match {
      color:green;
    }
    .no-match {
      color:red;
    }
  }
}