@import "../../theme.less";

.homepage-about-wrap {
  .about-banner {
    background-color: #e4ded0;
    .guide {
      color: #bc7039;
    }
  }
  .about-content {
    background-color: #fff;
  }
  @media @desktop {
    .about-banner {
      margin-top: -@header-height;
      .max-content {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }
      .layout {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        .homepage-title-wrap {
          width: 485px;
          max-width: 40%;
          .guide {
            font-size: 14px;
          }
          .title {
            font-size: 48px;
          }
        }
      }
      .banner-img {
        max-width: 50%;
        width: 868px;
      }
    }
    .about-content {
        .content-img {
          position: relative;
          max-width: 40%;
          .about-img {
            width: 504px;
          }
          .icon-play {
            width: 80px;
            height: 80px;
            background: white;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #e4ded0;
            .poa-center();
            display: flex;
            justify-content: center;
          }
        }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .about-banner {
      padding-top: @header-height;
      .layout .homepage-title-wrap {
        width: 315px;
        .title {
          font-size: 32px;
        }
      }
    }
  }
  @media @mobile {
    .about-banner {
      margin-top: -@mibile-header-height;
      padding-top: @mibile-header-height + (48px / @pv);
    }
    .about-content {
      .content-img {
        display: none;
      }
    }
  }
}
