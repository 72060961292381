@import "../../../theme.less";
.message-root {
  height: 100%;
  width: 100%;
  background-color: white;
  @media @mobile {
    height: 100%;
  }
  .channel-list-wrap {
    height: 100%;
    overflow: auto;
    @media @mobile {
      width: 100vw;
    }
    .mobile-header {
      display: none;
      @media @mobile {
        display: block;
        padding: 30px / @pv 24px / @pv;
        width: 100%;
        font-family: "LibreBaskerville-bold";
        font-size: 24px / @pv;
        color: @primary-dark;
        border-bottom: 1px solid rgba(45, 46, 61, 0.1);
      }
    }
  }
  .channel-item {
    padding: 17px 24px;
    width: 377px;
    display: flex;
    align-items: center;
    background-color: white;
    position: relative;
    cursor: pointer;
    @media @mobile {
      width: 100%;
    }
    &.isActive {
      background-color: #f4f5f5;
    }
    img {
      height: 48px;
      width: 48px;
      border-radius: 48px;
      overflow: hidden;
      object-fit: cover;
    }
    .info-sec {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 16px;
      .name-wrap {
        display: flex;
        .name {
          font-family: "Lato-bold";
          font-size: 16px;
          line-height: 160%;
          color: #61626d;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 200px;
          &.highLight {
            font-family: "Lato";
            font-weight: 800;
            color: @primary-dark;
          }
        }
        .point {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #ff6b00;
          border-radius: 8px;
          margin-left: 7px;
        }
      }

      .last-msg {
        font-family: "Lato";
        color: #61626d;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 160%;
        width: 196px;
        &.highLight {
          font-family: "Lato-bold";
          color: @primary-dark;
        }
      }
    }
    .time {
      font-family: "Lato";
      font-size: 14px;
      color: #61626d;
      position: absolute;
      right: 24px;
      bottom: 19px;
      &.highLight {
        font-family: "Lato-bold";
        color: @primary-dark;
      }
    }
    .divider {
      width: 336px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background-color: rgba(45, 46, 61, 0.1);
      height: 1px;
    }
  }
  .channel-header-wrap {
    width: 100%;
    height: 68px;
    background-color: white;
    padding: 0 24px;
    display: flex;
    align-items: center;
    position: relative;
    @media @mobile {
      padding: 0 24px / @pv 0 14px / @pv;
    }
    .icon-back {
      display: none;
      @media @mobile {
        display: inline-block;
        width: 24px / @pv;
        height: 24px / @pv;
        margin-right: 30px / @pv;
      }
    }
    .title {
      font-family: "LibreBaskerville-bold";
      font-size: 20px;
      line-height: 140%;
      color: @primary-dark;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media @mobile {
        width: 156px / @pv;
        font-family: "Lato";
        font-weight: 800;
        font-size: 16px / @pv;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .icon-sec {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      @media @mobile {
        display: flex;
        justify-content: space-around;
      }
      img {
        width: 48px;
        height: 48px;
        padding: 12px;
        cursor: pointer;
        margin-left: 8px;
        @media @mobile {
          width: 24px / @pv;
          height: 24px / @pv;
          padding: 0;
          margin-left: 12px / @pv;
        }
      }
    }
  }
}
.str-chat {
  height: 100%;
  border-right: 1px solid #e0e0e0;
  background-color: white !important;
  .str-chat__channel-list-team__header {
    display: none;
  }
  .str-chat__main-panel {
    padding: 0 !important;
    .str-chat__header-livestream {
      border-radius: 0;
    }
  }
  .str-chat__input {
    padding-bottom: 10px;
  }
  .str-chat__input-footer {
    display: none;
  }
}
.str-chat-channel-list.messaging {
  @media @mobile {
    margin-top: @mibile-header-height;
  }
}
.str-chat-channel-list.messaging::-webkit-scrollbar {
  width: 0 !important;
}
.str-chat__loading-channels {
  width: 377px;
  @media @mobile {
    width: 100%;
  }
}
