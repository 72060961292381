@import "../theme.less";

.preview-card-wrap {
  background-color: #fff;
  padding: 24px 24px 14px;
  border: 1px solid @rgba-grey-light;
  border-radius: 4px;
  margin: 24px 0;
  overflow: auto;
  .card-item {
    margin-bottom: 10px;
    line-height: 1.6;
    display: flex;
    align-items: baseline;
  }
  .card-label {
    text-transform: uppercase;
    font-size: 12px;
  }
  .card-value {
    color: @primary-dark;
    font-size: 14px;
    font-family: "Lato-bold";
  }
  .tip-text {
    font-size: 16px;
    text-align: center;
  }
}
