@import "../theme.less";
.add-award-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  .close {
    position: absolute;
    cursor: pointer;
    height: 24px;
    right: 24px;
    top: 20px;
  }
  .title {
    font-family: "Lato-bold";
    font-size: 20px;
    margin-bottom: 8px;
    color: @primary-dark;
    height: 64px;
    line-height: 64px;
    background-color: white;
    padding-left: 24px;
  }
  .input {
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .btn-add {
    height: 41px;
    width: 94px;
    margin: 24px;
    font-size: 14px;
  }
}
.add-award-modal-wrap {
  .ant-modal {
    width: 400px !important;
    .ant-modal-body {
      background-color: #f4f5f5;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;
      .select {
        margin: 24px;
        min-height: 56px;
        padding: 12px 0;
        border-radius: 4px;
        background-color: white;
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid white;
        &.onFocus {
          border: 1px solid @grey;
        }
        .select-center-warp {
          display: flex;
          flex-direction: column;
          width: 100%;
          .label {
            font-family: "Lato";
            font-size: 16px;
            color: @grey;
            line-height: 32px;
            margin-left: 12px;
            &.inputVisible {
              font-size: 10px;
              line-height: 140%;
              font-family: "Lato-bold";
              color: @dark-grey;
              text-transform: uppercase;
            }
          }
          .select-input {
            min-height: 34px;
            padding-left: 12px;
            &.hasTag {
              padding-left: 22px;
            }
            .ant-select-selection {
              border: none;
              box-shadow: none;
              height: 100%;
              &:focus {
                box-shadow: none;
              }
              .ant-select-selection__rendered {
                margin: 0;
                height: 34px;
    
                .ant-select-search {
                  height: 34px;
                }
                .ant-select-selection__choice {
                  display: flex;
                  height: 34px;
                  border-radius: 34px;
                  background-color: @extra-light;
                  border: 1px solid @rgba-grey-light;
                  align-items: center;
                  padding: 0 8px 0 12px;
                  margin-right: 0;
                  margin-left: -10px;
                  justify-content: space-between;
    
                  .ant-select-selection__choice__content {
                    line-height: 34px;
                  }
                  .ant-select-selection__choice__remove {
                    height: 17px;
                    width: 17px;
                    position: relative;
                    margin-left: 5px;
                    background-color: rgba(45, 46, 61, 0.25);
                    border-radius: 17px;
                    font-size: 10px;
                    i {
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                      color: white;
                    }
                  }
                }
              }
            }
            .ant-select-selection--multiple {
              min-height: 34px;
              height: 34px;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
