primary-color = #2d2e3d;
primary-dark = #2d2e3d;
dark-grey = #61626d;
grey = #96979e;
light-grey = #cacbce;
light = #eaeaec;
extra-light = #f4f5f5;
grey-bg = #f2f3f3;
border-grey = rgba(45, 46, 61, 0.1);

// size define------------------
mb-size = 1024px;
pc-size = 1440px;
pc-size-design = 1440px;
pc-size-max = 1680px;
mb-size-design = 375px;
base-font-size = 12px;
base-font-size-large = 14px;
mb-pv = (mb-size-design / 100vw);
mb-rem = (base-font-size / mb-pv);

// functions and mixins---------
to-pv(size, design-size = mb-size-design) {
  res-size = 0;

  for sz in size {
    push(res-size, type(sz) == 'unit' && unit(sz) == 'px' ? unit((floor((sz * 100 * 100 / design-size)) / 100), 'vw') : sz);
  }

  shift(res-size);
  res-size;
}

mobile(rules) {
  @media screen and (max-width: mb-size - 1) {
    for key, value in rules {
      {key}: to-pv(value, mb-size-design);
    }
  }
}

pc(rules) {
  @media screen and (min-width: mb-size) {
    for key, value in rules {
      {key}: to-pv(value, pc-size-design);
    }
  }
}

poa-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

flex-center-container() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

flex-between-container() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

shadow-box() {
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.07), 0px 3px 7px rgba(0, 0, 0, 0.07), 0px 0px 4px rgba(0, 0, 0, 0.07);

  &:hover {
    box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.1), 0px 7px 15px rgba(0, 0, 0, 0.05), 0px 0px 5px rgba(0, 0, 0, 0.04);
  }
}

deco-scrollbar(bg-color = #f0f0f0, thumb-color = #aaa) {
  /* 定义滚动条高宽及背景
  高宽分别对应横竖滚动条的尺寸 */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: bg-color;
  }

  /* 定义滚动条轨道
  内阴影+圆角 */
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow:inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 4px;
    background-color: bg-color;
  }

  /* 定义滑块
  内阴影+圆角 */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: thumb-color;
  }
}