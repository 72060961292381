@import "../../theme.less";
.business-canvas-container {
  height: @create-content-height;
  background-color: @extra-light;
  display: flex;
  margin-top: @create-header-height;
  @media @mobile {
    height: calc(100vh - 104px);
    margin-top: 104px;
  }
  .left-sec {
    height: 100%;
    width: 472px;
    box-shadow: inset -1px 0px 0px @rgba-grey-light;
    overflow-y: auto;
    .title-sec {
      display: flex;
      margin-left: 24px;
      margin-top: 48px;
      justify-content: space-between;
      margin-right: 29px;
      @media @mobile {
        margin: 32px / @pv 30px / @pv 16px / @pv;
      }
      .title {
        @media @mobile {
          font-size: 24px / @pv;
        }
      }
      .btn-back {
        height: 41px;
        .txt-back {
          @media @mobile {
            display: none;
          }
        }
        .icon-edit {
          display: none;
          @media @mobile {
            display: inline-block;
          }
        }
      }
    }

    .panel {
      background: none;
      .intro-detail {
        width: 100%;
        padding: 24px;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .company-name {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
        .headline {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
        .description {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
      .media-detail {
        width: 100%;
        padding: 24px;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .item-media {
          margin-bottom: 16px;
        }
        .btn-upload-video {
          white-space: normal;
          min-height: 42px;
          width: 187px;
          padding: 0 16px;
          font-size: 14px;
          span {
            margin-left: 0 !important;
          }
          .loading {
            font-size: 18px;
            width: 20px;
            margin-right: 10px;
            @media @mobile {
              font-size: 16px;
              width: 18px;
            }
          }
        }
      }
      .detail-detail {
        padding: 24px;
        width: 100%;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .input {
          margin-bottom: 16px;
          height: 56px;
          width: 100%;
        }
        .text-area-location {
          margin-bottom: 16px;
          width: 100%;
          .input{
            height: auto !important;
            margin-bottom: 0px;
          }
        }
        .auto-complete {
          width: 100% !important;
          margin-bottom: 16px;
        }
        .footage-wrap {
          position: relative;
          height: 56px;
          flex: 1;
          margin-bottom: 16px;
          .footage-input {
            width: 100%;
          }
          .unit-btn {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 4px;
            line-height: 29px;
            height: 29px;
            background-color: @extra-light;
            padding-left: 6px;
            cursor: pointer;
            img {
              width: 8px;
              margin-left: 8px;
              margin-right: 10px;
            }
          }
        }
        .extra-location {
          display: flex;
          height: auto;
          .input {
            flex: 1;
            margin-bottom: 0;
          }
          .icon-delete {
            width: 18px;
            cursor: pointer;
            margin: 0 16px;
          }
        }
        .btn-add-location {
          height: 42px;
          width: 183px;
          padding: 0 16px;
          font-size: 14px;
        }
      }
      .award-detail {
        width: 100%;
        background-color: @light;
        padding: 24px;
        @media @mobile {
          padding: 30px;
        }
        .award-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 0;
          text-align: center;
          width: 100%;
          .icon-award-empty {
            height: 48px;
            width: 48px;
          }
          .empty-title {
            font-family: "Lato-bold";
            font-size: 14px;
            line-height: 160%;
            color: @primary-dark;
            margin: 16px 0 8px;
          }
          .empty-subtitle {
            font-family: "Lato";
            font-size: 14px;
            line-height: 160%;
            color: @grey;
            width: 282px;
          }
        }
        .btn-add-award {
          height: 42px;
          width: 131px;
          margin-top: 16px;
          padding: 0 16px;
        }
        .item-award {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
      .additional-detail {
        padding: 24px;
        width: 100%;
        background-color: @light;
        @media @mobile {
          padding: 30px / @pv 30px / @pv 14px / @pv;
        }
        .auto-complete {
          width: 100% !important;
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
        .payment-input {
          width: 100%;
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
      .social-detail {
        padding: 24px 24px 8px 24px;
        width: 100%;
        background-color: @light;
        @media @mobile {
          padding: 30px / @pv 30px / @pv 14px / @pv;
        }
        .item-social-media {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
    }
  }
  .right-sec {
    height: 100%;
    flex: 1;
    overflow: auto;
    @media @mobile {
      display: none;
    }
    .center-sec {
      width: 800px;
      margin: 48px auto;
      position: relative;
      height: 100%;
    }
  }
}
