@import "../theme.less";
.loading-modal-style {
  .ant-modal {
    width: 100px !important;
    .ant-modal-content {
      .ant-modal-body {
        height: 100px;
        .ant-progress-text {
          color: #2d2e3d;
        }
      }
    }
  }
}
