@import "../theme.less";

.modal-wrap {
  .edit-photo-root {
    display: flex;
    flex-direction: column;
    position: relative;

    .title-sec {
      display: flex;
      height: 64px;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;

      .close {
        cursor: pointer;
        height: 18px;
      }

      .title {
        font-family: "Lato-bold";
        font-size: 20px;
        margin-bottom: 24px;
        color: @primary-dark;
      }
    }

    .center-sec {
      width: 100%;
      background-color: @rgba-grey-light;
      padding: 24px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .image-wrap {
        height: 448px;

        text-align: center;

        img {
          height: 100%;

          display: block;
        }

        .cropper-modal.hidden,
        .cropper-crop-box.hidden {
          visibility: hidden;
        }
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        color: white;
      }
      .keep-size {
        padding: 20px;
        font-family: "Lato";
        font-size: 14px;
        line-height: 160%;
        margin-top: 20px;
        color: rgba(45, 46, 61, 0.75);
        width: 82%;
      }
    }

    .bottom-sec {
      height: 89px;
      display: flex;
      text-align: center;
      justify-content: space-between;
      padding: 0 40px 0 40px;
      align-items: center;

      .btn-apply {
        width: 72px;
        height: 41px;
        font-size: 14px;

      }
      .tip {
        margin-right: auto;
        font-family: "Lato";
        font-weight: 800;
        color: @primary-dark;
        font-size: 16px;
        line-height: 160%;
      }

      .btn {
        font-size: 14px;
        height: 41px;
        margin-right: 20px;
        font-weight: 800;
        align-items: center;
        letter-spacing: 0.04em;
        color: #2D2E3D;
        background-color: #ffffff;
        &:last-child {
          margin-left: 20px;
        }
        &.disabled {
          background-color: @light;
        }
      }
      .btn-save-pos {
        margin-right: 0;
        height: 41px;
      }

      @media @mobile {
        padding: 0 10px;
        height: 160px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .tip {
          width: 100%;
        }
        .btn {
          width: 44%;
          margin: 5px;
        }
        .btn-apply {
          width: calc(88% + 10px)
        }
        .ant-checkbox-wrapper {
          margin-top: 6px;
          flex: 0 0 100%;
        }
      }
    }
  }

  .ant-modal {
    width: 600px !important;

    .ant-modal-body {
      padding: 0;
    }
  }
}