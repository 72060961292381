@import "../../theme.less";
.setting-root {
  display: flex;
  flex-direction: column;
  width: 890px;
  margin: 24px auto;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    margin-top: 18px;
    color: @primary-dark;
  }
  .subtitle {
    font-family: "LibreBaskerville";
    font-size: 20px;
    line-height: 140%;
    margin-top: 48px;
    color: @dark-grey;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
  }
  .avatar-sec {
    display: flex;
    margin-top: 16px;
    align-items: center;
    .avatar {
      height: 120px;
      width: 120px;
      @media @mobile {
        margin-right: 16px / @pv;
      }
    }
    .default-avatar {
      height: 120px;
      width: 120px;
      @media @mobile {
        margin-right: 16px / @pv;
      }
      background-color: @grey;
      border-radius: 120px;
      line-height: 120px;
      text-align: center;
      font-size: 60px;
      color: white;
    }
    .avatar-btn-sec {
      display: flex;
      @media @mobile {
        align-items: flex-start;
        flex-direction: column;
      }
      .btn-upload {
        margin: 0 24px;
        height: 56px;
        @media @mobile {
          margin: 0;
          height: 42px;
        }
        span {
          margin-left: 0 !important;
        }
      }
      .loading {
        font-size: 18px;
        width: 20px;
        margin-right: 10px;
        @media @mobile {
          font-size: 16px;
          width: 18px;
        }
      }

      .btn-delete {
        height: 56px;
        @media @mobile {
          margin-top: 8px;
          height: 42px;
        }
      }
    }
  }
  .info-sec {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 48px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(45, 46, 61, 0.1);
    .name-sec {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media @mobile {
        flex-direction: column;
      }
      .name-input {
        width: calc((100% - 16px) / 2);
        @media @mobile {
          width: 100%;
        }
      }
    }
    .tip {
      font-family: "Lato";
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      margin-top: 6px;
      color: @grey;
      @media @mobile {
        display: none;
      }
    }
    .tip-mobile {
      display: none;
      @media @mobile {
        display: inline;
        margin-bottom: 16px;
      }
    }
    .input {
      width: 100%;
      margin-top: 16px;
    }
  }
  .select-sec {
    display: flex;
    flex-direction: column;
    .select-item {
      margin-top: 18px;
      font-family: "Lato";
      font-size: 14px;
      line-height: 160%;
      color: @primary-dark;
      display: flex;
      .check-box {
        margin-right: 16px;
        height: 18px;
        width: 18px;
      }
      span {
        display: inline-block;
      }
    }
  }
  .btn-sec {
    margin-top: 48px;
    display: flex;
    margin-bottom: 24px;
    .btn-save {
      height: 51px;
      margin-right: 16px;
    }
    .btn-pwd {
      height: 51px;
    }
  }
}
