@import "../../theme.less";

.homepage-terms-wrap {
  .terms-banner .title {
    margin: 0;
  }
  .terms-content {
    blockquote {
      margin: 5px 20px;
    }
    background-color: #fff;
    h3, h4 {
      font-family: "LibreBaskerville";
      letter-spacing: -1px;
      color: #000;
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      line-height: 1.6;
      color: @primary-dark;
      opacity: 0.7;
    }
    .row{
      display: flex;
      flex-flow: row nowrap;
      border-width: 1px 1px 0px 1px;
      border-color: #81828B;
      border-style: solid;

      &.last{
        border-bottom: 1px solid #81828B;
      }
      &.mb-24{
        margin-bottom: 24px;
      }
      .col{
        flex: 1;
        padding: 16px 24px;
        &:first-child{
          border-right: 1px solid #81828B;
        }
      }
    }
    a {
      color: #214cf3;
      word-break: break-all;
    }
  }
  @media @desktop {
    .terms-banner {
      padding: 80px 0 120px;
      text-align: center;
      .title {
        font-size: 48px;
      }
    }
    .terms-content {
      .padding-layout {
        width: 815px;
        margin: 0 auto;
      }
      h3 {
        font-size: 32px;
        margin-bottom: 24x;
        margin-top: 48px;
      }
      h4 {
        font-size: 20px;
        margin-bottom: 8px;
        margin-top: 48px;
      }
      p {
        font-size: 16px;
        margin-bottom: 24px;
      }
      pre {
        width: 100%;
        white-space: pre-wrap;
        font-family: "Lato";
      }
    }
  }
  @media @mobile {
    .terms-banner {
      padding: 48px / @pv 0 48px / @pv;
      .title {
        font-size: 32px / @pv;
      }
    }
    .terms-content {
      h3 {
        font-size: 32px / @pv;
        margin-bottom: 24px / @pv;
        margin-top: 48px / @pv;
      }
      h4 {
        font-size: 20px / @pv;
        margin-bottom: 8px / @pv;
        margin-top: 48px / @pv;
      }
      p {
        font-size: 16px / @pv;
        margin-bottom: 24px / @pv;
      }
    }
  }
}
