@import "../theme.less";
.media-item {
  display: flex;
  .image-sec {
    height: 48px;
    width: 48px;
    border-radius: 4px;
    margin-right: 16px;
    overflow: hidden;
    position: relative;
    .icon-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: #2d2e3d;
    }
    .video-default-thumb {
      height: 100%;
      width: 100%;
      background-color: @primary-dark;
      text-align: center;
      line-height: 48px;
      color: white;
      font-family: "Lato-bold";
      font-size: 16px;
      display: block;
    }
  }

  .info-sec {
    display: flex;
    flex-direction: column;
    .media-title {
      font-family: "Lato-bold";
      font-size: 14px;
      color: @primary-dark;
      line-height: 160%;
    }
    .text {
      font-family: "Lato";
      font-size: 12px;
      line-height: 140%;
      color: @dark-grey;
    }
    .input {
      width: 200px;
      height: 25px;
      margin-top: 8px;
    }
  }
  .space {
    flex: 1;
  }
  .icon-edit {
    width: 18px;
    height: 18px;
    margin-right: 20px;
    padding: 2px;
    cursor: pointer;
  }
  .icon-upload {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .btn-upload-wrap {
    .btn-upload {
      height: 42px;
      padding: 0 16px;
      font-size: 14px;
    }
    .icon-upload {
      display: none;
    }
  }
  @media @mobile {
    .btn-upload-wrap {
      .btn-upload {
        display: none;
      }
      .icon-upload {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
