.post-wrap {
    .post-items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.promotion-code-wrap {
    .material-input .MuiFormLabel-root.Mui-error {
        transform: translate(44px, 10px) scale(0.75) !important;
    }
}

.payment-wrap {
    .confirm-btn {
        margin-bottom: 30px;
    }
}
