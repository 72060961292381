@import "../../../theme.less";
.opportunity-bookmark-item-root {
  background-color: white;
  position: relative;
  width: 100%;
  height: 198px;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  @media @mobile {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
  .feature-img {
    height: 118px;
    width: 118px;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;
  }
  .info-sec {
    display: flex;
    margin-left: 24px;
    flex-direction: column;
    @media @mobile {
      margin-left: 0;
      margin-top: 16px / @pv;
    }
    .canvas-name {
      font-family: "LibreBaskerville-Bold";
      font-size: 20px;
      line-height: 140%;
      color: @primary-dark;
      margin-bottom: 16px;
    }
    .name {
      margin: 9px 0;
    }
    .info-item {
      font-family: "Lato";
      font-size: 14px;
      line-height: 160%;
      color: @grey;
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
  }
  .space {
    flex: 1;
  }
  .btn-sec {
    height: 100%;
    display: flex;
    align-items: flex-end;
    @media @mobile {
      margin-top: 16px / @pv;
      width: 100%;
      flex-direction: column;
    }
    .btn-view {
      height: 41px;
    }
  }
}
