@import "../theme.less";
.footer-root {
  padding: 0 !important;
  background: @primary-dark !important;
  &.isHome {
    background: black !important;
  }
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    padding: 0 48px;
    &.isHome {
      height: 96px;
    }
    .text {
      font-family: "Lato";
      font-weight: 800;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: white;
      cursor: pointer;
    }
    .point {
      margin: 0 12px;
      display: inline-block;
    }
    .left-sec {
      display: inline-flex;
      align-items: center;
      .copyright {
        font-family: "Lato";
        font-size: 16px;
        color: #999999;
      }
    }
    .right-sec {
      display: inline-flex;
      align-items: center;
      .ic-third-party {
        height: 30px;
        width: 30px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .copyright-bottom {
    line-height: 54px;
    text-align: center;
    font-family: "Lato";
    font-size: 14px;
    color: #999999;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    &.isHome {
      display: none;
    }
  }
  @media @mobile {
    .footer-wrap {
      height: 297px;
      text-align: center;
      justify-content: unset;
      align-items: center;
      flex-direction: column;
      &.isHome {
        height: 235px;
      }
      .left-sec {
        display: flex;
        flex-direction: column;
        margin-top: 46px;
        .copyright {
          display: none;
        }
      }
      .right-sec {
        width: 100%;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        .icon-wrap {
          display: flex;
          .ic-third-party {
            height: 24px;
            width: 24px;
            margin: 27px 12px 0 12px;
            cursor: pointer;
          }
        }
      }
      .point {
        display: none;
      }
      .text {
        margin-bottom: 20px;
        color: rgba(255, 255, 255, 0.5);
        &.isHome {
          color: white;
          margin-bottom: 19px;
        }
      }
    }
    .copyright-bottom {
      height: 64px;
      text-align: center;
      font-family: "Lato";
      font-size: 16px;
      border-top: none;
      color: #999999;
      &.isHome {
        display: block;
        color: white;
      }
    }
  }
}
