@import "../theme.less";
.employee-grid-root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .employee-item {
    position: relative;
    display: flex;
    background-color: white;
    border-radius: 4px;
    height: 91px;
    align-items: center;
    padding: 0 16px;
    margin-top: 26px;
    width: calc((100% - 48px) / 3);
    @media @mobile {
      height: 75px / @pv;
    }
    &.isCenter {
      margin: 26px 24px 0;
    }
    .avatar {
      height: 48px;
      width: 48px;
      margin-right: 16px;
    }
    .empty-avatar {
      height: 48px;
      width: 48px;
      margin-right: 16px;
      background-color: @grey;
      border-radius: 48px;
      line-height: 48px;
      font-family: "Lato-bold";
      font-size: 20px;
      color: white;
      text-align: center;
      text-transform: uppercase;
    }
    .name-sec {
      .name {
        font-family: "Lato-bold";
        font-size: 16px;
        line-height: 160%;
        color: @primary-dark;
        @media @mobile {
          font-size: 14px / @pv;
        }
      }
      .operate {
        background-color: @extra-light;
        border-radius: 4px;
        padding: 0 6px;
        display: inline-block;
        height: 29px;
        line-height: 29px;
        font-family: "Lato";
        font-weight: 500;
        font-size: 14px;
        line-height: 29px;
        margin-top: 4px;
        color: @dark-grey;
        cursor: pointer;
        img {
          width: 8px;
          margin-right: 4px;
          margin-left: 8px;
        }
      }
    }
    .icon-delete {
      display: block;
      width: 34px;
      height: 34px;
      padding: 8px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .btn-add-employee {
    width: calc((100% - 48px) / 3);
    height: 56px;
    margin-top: 26px;
    margin-left: 24px;
    @media @mobile {
      margin-left: 0;
    }
    &.isLineLast {
      margin-left: 0;
    }
  }
  @media (max-width: 475px) {
    flex-direction: column;
    align-items: flex-start;
    .employee-item {
      width: 100%;
      margin-top: 16px;
      &.isCenter {
        margin: 16px 0 0 0;
      }
    }
    .btn-add-employee {
      width: 194px;
      height: 42px;
      margin-top: 16px;
    }
  }
}
.menu-auth {
  padding: 8px 0;
  background-color: white;
  border: 1px solid @rgba-grey-light;
  box-sizing: border-box;
  border-radius: 4px;
  .auth-item {
    height: 48px;
    font-family: "Lato";
    font-size: 16px;
    line-height: 48px;
    padding: 0 12px;
    cursor: pointer;
    &:hover {
      background-color: @extra-light;
    }
  }
}
