@import "../../theme.less";
.signin-view {
  .ant-layout-header {
    height: 84px;
    background-color: @extra-light;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid @rgba-grey-light;
  }
  .ant-layout-content {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .left-sec {
      position: relative;
      width: 590px;
      height: 100%;
      background-image: none;
      background-color: @primary-dark;
      overflow-y: auto;
      @media (max-width: 1120px) {
        display: none;
      }
      img {
        position: absolute;
        left: 48px;
        top: 48px;
        width: 165px;
      }
      .left-text {
        position: absolute;
        top: 216px;
        width: 100%;
        padding: 0 48px;
        font-size: 40px;
        color: white;
        font-family: "LibreBaskerville";
        line-height: 120%;
      }
    }
    .right-sec {
      position: relative;
      flex: 1;
      height: 100%;
      overflow-y: auto;
      background-color: @extra-light;
      .sign-in {
        display: flex;
        flex-direction: column;
        padding: 32px;
        .footer-text {
          font-family: "Lato";
          font-size: 14px;
          color: @rgba-grey;
          margin: 16px 10px 16px 10px;

          a {
            border-bottom: 1px solid @rgba-grey;
            color: @rgba-grey;
          }
        }
        .invite-tip {
          position: relative;
          width: 100%;
          padding: 16px;
          border: 1px solid #83b23a;
          border-radius: 4px;
          background-color: rgba(131, 178, 58, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #5a8e09;
          &:hover {
            background-color: #83b23a;
            color: white;
          }
          span {
            line-height: 160%;
            font-size: 16px;
          }
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          margin: 0 auto 0;
          width: fit-content;
          .login-title {
            font-size: 32px;
            line-height: 120%;
            //   text-align: center;
            text-align: center;
            color: #2d2e3d;
            letter-spacing: -1px;
            font-family: "LibreBaskerville";
          }
          .login-type {
            display: flex;
            margin-top: 64px;
            justify-content: center;
            .continue-btn {
              display: flex;
              align-items: center;
              padding-right: 24px;
              padding-left: 24px;
              height: 56px;
              color: #ffffff;
              font-family: Lato;
              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              line-height: 120%;
              background-color: #2d2e3d;
              border-radius: 4px;
              letter-spacing: 0.04em;
              &:not(:first-child) {
                margin-left: 8px;
                @media @mobile {
                  margin-left: 0;
                }
              }
              span {
                margin-left: 16px;
              }
            }
          }
          .divider {
            font-family: "Lato";
            font-size: 16px;
            color: @grey;
            margin-top: 32px;
            display: flex;
            align-items: center;
            .stash {
              height: 1px;
              flex: 1;
              background-color: @light;
            }
            .or {
              margin: 0 16px;
            }
          }
          .login-with-email {
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            width: 43vw;
            @media @mobile {
              width: 80vw;
            }
            .input {
              width: 100%;
              &.showErroe {
                margin-bottom: 10px;
              }
              &:not(:first-child) {
                margin-top: 24px;
              }
            }
          }
          .forget {
            margin-top: 8px;
            width: fit-content;
            border-bottom: 1px solid @rgba-grey;
            font-family: Lato;
            font-size: 14px;
            line-height: 160%;
            color: @rgba-grey;
            cursor: pointer;
          }
          .btn-sign-in {
            font-size: 1.2em;
            background-color: #2d2e3d;
            margin: 8px auto 0;
            height: 50px;
            width: 100px;
          }
          .sign-up {
            margin-top: 24px;
            font-family: Lato;
            font-size: 14px;
            line-height: 160%;
            text-align: center;
            color: @rgba-grey;
            .sign-up-btn {
              display: inline-block;
              margin-left: 5px;
              border-bottom: 1px solid @rgba-grey;
              cursor: pointer;
            }
          }
        }
      }
      .btn-sign-up {
        padding-left: 24px;
        padding-right: 24px;
        height: 50px;
        width: fit-content;
      }
      .send-email,
      .change-pw {
        margin: 130px auto 0;
        width: 515px;
        .title {
          font-family: "LibreBaskerville";
          font-size: 32px;
          text-align: center;
          line-height: 120%;
          letter-spacing: -1px;
          color: @primary-color;
        }
        .tips {
          margin-top: 48px;
          font-family: Lato;
          font-size: 16px;
          line-height: 160%;
          color: @primary-color;
          flex: none;
          order: 0;
          align-self: center;
        }
        .input {
          margin-top: 32px;
          width: 100%;
        }
        .send-email-btn {
          margin: 32px auto 0;
          text-align: center;
        }
        .sent-result-container {
          text-align: center;
          .ic-check {
            margin-top: 52px;
          }
          .tips {
            margin-top: 36px;
            .tips-email {
              font-weight: bold;
            }
          }
          .btn-sign-up {
            margin-top: 32px;
          }
          .send-again {
            margin-top: 24px;
            .resend-link {
              display: inline-block;
              border-bottom: 1px solid @rgba-grey;
              cursor: pointer;
            }
          }
        }
      }
      .change-pw {
        text-align: center;
        .btn-sign-up {
          margin-top: 32px;
        }
      }
      .email-templete {
        .templete-container {
          margin: 60px auto;
          padding: 48px;
          width: 590px;
          background-color: #ffffff;
          .templete-title {
            font-family: Arial;
            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
            letter-spacing: -0.5px;
            color: #2d2e3d;
          }
          .templete-content {
            margin-top: 32px;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 160%;
            color: #2d2e3d;
          }
          .btn-box {
            margin-top: 48px;
            text-align: center;
          }
        }
      }
    }

    @media (max-width: 475px) {
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      .left-sec {
        width: 100%;
        height: 386px;
        &.isBlack {
          height: 302px;
        }
        img {
          position: absolute;
          left: 50%;
          top: 32px;
          width: 132px;
          left: 30px;
        }
        .left-text {
          position: absolute;
          top: 50%;
          margin-top: 25px;
          transform: translateY(-50%);
          width: 100%;
          padding: 0 30px;
          font-size: 24px;
          color: white;
          font-family: "LibreBaskerville";
          line-height: 140%;
        }
      }
      .right-sec {
        .sign-in {
          margin: 0;
          padding: 30px;
          width: 100%;
          .login-title {
            font-size: 24px;
          }
          .login-type {
            flex-direction: column;
            margin-top: 24px;
            .continue-btn {
              width: 100%;
              height: 26px;
              font-size: 14px;
              padding: 0 17px;
              &:not(:first-child) {
                margin-top: 16px;
                margin-left: 0;
              }
            }
          }
          .divider {
            margin-top: 24px;
          }
          .login-with-email {
            margin-top: 24px;
            .input {
              &:not(:first-child) {
                margin-top: 16px;
              }
            }
          }

          .btn-sign-in {
            margin: 10px auto 0 !important;
            width: 80% !important;
          }
          .sign-up {
            margin-top: 16px;
          }
        }
        .send-email,
        .change-pw {
          margin: 0;
          padding: 30px;
          width: 100%;
          .title {
            font-size: 24px;
          }
          .tips {
            margin-top: 24px;
            text-align: center;
          }

          .input,
          .send-email-btn {
            margin-top: 24px;
          }

          .sent-result-container {
            .ic-check {
              margin-top: 42px;
            }
            .tips {
              margin-top: 24px;
            }
            .btn-sign-up {
              margin-top: 24px;
            }
            .send-again {
              margin-top: 16px;
            }
          }
        }
        .email-templete {
          .templete-container {
            margin: 0;
            padding: 30px;
            width: 100%;
            background-color: #ffffff;
            .btn-box {
              margin-top: 32px;
            }
          }
        }
      }
    }
  }
}
