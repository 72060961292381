@import url("https://fonts.googleapis.com/css?family=Lato:300");

.cm-sc-landing {
  .header-txt {
    text-align: center;
    padding: 20px 40px;
    h1 {
      font-size: 2.7em;
    }
  }
  .cm-sp-hero-content {
    text-align: center;
  }
  .input-bg {
    border: 1px solid black;
  }
  .brands {
    background-color: white;
  }
  .change-order {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    flex: 1 0 200px;
  }

  @media (max-width: 600px) {
    .item-2 {
      order: -1;
    }
  }
  .video-style {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .top-form {
    max-width: 100%;
  }
  .backed-by {
    position: fixed;
    top: 53px;
    left: 14px;
    color: white;
    z-index: 99;
    font-family: "Lato-bold";
  }
  .btn-cta {
    height: 48px;
    margin: 20px;
  }
  .cta-header {
    margin: auto;
    font-style: italic;
  }
  .hero {
    margin: 20px;
    .header {
      text-align: center;
      font-size: 2em;
      font-weight: 600;
    }
    .content {
      p {
        font-size: 1.2em;
        color: black;
      }
      .video {
        margin: 20px;
        text-align: center;
        height: 20vh;
      }
    }
  }
  form {
    padding-top: 20px;
  }
  .cm-sp-brands {
    text-align: center;
    h4 {
      font-size: 1.5em;
      text-align: center;
    }
    .cm-sp-logos-wrap {
      img {
        width: 125px;
      }
    }
  }
  .section {
    background-color: white;
    padding: 20px;
    justify-content: center;
    .three-quarter-width {
      width: 75%;
    }
    h1 {
      text-align: center;
      margin-bottom: 10px;
      font-size: 2em;
    }
    p {
      font-weight: bold;
    }
    .txt {
      padding: 15px;
      font-size: 1.2em;
    }
  }
  .cta {
    p {
      font-style: italic;
      font-weight: bold;
      font-size: 1.2em;
    }
    text-align: center;
    margin: 30px;
  }

  @media (min-width: 1024px) {
    .cm-sp-hero-content {
      text-align: center;
      h2 {
        margin: auto;
        width: 80%;
      }
      .top-form {  
        max-width: 75%;
      }
      h1 {
        width: 70%;
      }
    }
    .header-txt {
      padding: 20px 15%;
    }
    .video-style {
      max-width: 80%;
      margin-right: 10px;
    }
    .backed-by {
      top: 59px;
    }
    form {
      margin: auto;
    }
    .content {
      .video {
        height: 50vh !important;
      }
      .sign-up {
        font-size: 1.2em;
        margin: auto;
        text-align: center;
        max-width: 75%;
      }
    }
    .cm-sp-brands {
      .cm-sp-logos-wrap {
        img {
          width: 150px;
        }
      }
    }
    .cta {
      max-width: 50%;
      text-align: center;
      margin-left: 25%;
    }
    .section {
      .create-jobs-img {
        min-width: "600px";
      }
      display: flex;
      align-items: center;
      img {
        max-width: 40%;
        height: min-content;
      }
      .txt {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        max-width: 75%;
        margin: 20px;
        font-size: 1.2em;
        h1 {
          margin: auto;
          margin-bottom: 25px;
        }
        p {
          width: 70%;
          margin: auto;
        }
      }
    }
  }
}
