.opportunity-embed {
  padding: 24px;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .description {
    color: #4a4a4a;
    margin-bottom: 24px;
  }

  .code-section {
    position: relative;
    margin-bottom: 32px;

    pre {
      background: #f5f5f5;
      padding: 16px;
      border-radius: 4px;
      font-family: monospace;
      overflow-x: auto;
    }

    .copy-button {
      background: #2d2d2d;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .copy-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');
      }

      &:hover {
        background: #404040;
      }
    }
  }

  .toggle-section {
    display: flex;
    align-items: flex-start;
    background: #f0faf7;
    padding: 16px;
    border-radius: 8px;

    .toggle {
      margin-right: 16px;
      margin-top: 4px;

      &.ant-switch-checked {
        background-color: #00b07c;
      }
    }

    .toggle-content {
      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        color: #4a4a4a;
        margin: 0;
      }
    }
  }
}
