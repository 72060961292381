@import "../theme.less";
.confirm-modal-root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 24px;
    line-height: 140%;
    color: @primary-dark;
    margin-top: 48px;
  }
  .desc {
    font-family: "Lato";
    font-size: 16px;
    line-height: 160%;
    color: rgba(45, 46, 61, 0.75);
    margin: 8px 0 24px 0;
    text-align: center;
  }
  .btn-sec {
    display: flex;
    margin-bottom: 36px;
    .btn-confirm {
      margin-right: 18px;
      height: 41px;
    }
    .btn-cancel {
      height: 41px;
    }
  }
}
.confirm-modal-wrap {
  .ant-modal {
    width: 400px !important;
    .ant-modal-body {
      background-color: #f4f5f5;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;
    }
  }
}
