.view-opp-count-modal {
    text-align: center;
    Button {
        margin: 5px;
    }
    .selected {
        background-color: #4bc0c0  !important;
        border: none !important;
    }
    .chart-container {
        transition: opacity 0.5s ease;
      }
    .slug {
        font-size: 1.5em;
        font-weight: bold;
    }
}
