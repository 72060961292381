@import "../../../theme.less";
.canvas-bookmark-item-root {
  background-color: white;
  position: relative;
  width: 100%;
  height: 166px;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 4px;
  display: flex;
  @media @mobile {
    flex-direction: column;
    height: auto;
  }
  .feature-img {
    height: 118px;
    width: 118px;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;
  }
  .info-sec {
    display: flex;
    margin-left: 24px;
    flex-direction: column;
    @media @mobile {
      margin-left: 0;
      margin-top: 16px / @pv;
    }
    .canvas-name {
      font-family: "LibreBaskerville-Bold";
      font-size: 20px;
      line-height: 140%;
      color: @primary-dark;
      margin-bottom: 8px;
    }
    .info-item {
      font-family: "Lato";
      font-size: 14px;
      line-height: 160%;
      color: @primary-dark;
      margin-top: 4px;
      display: flex;
      align-items: center;
      span {
        color: @grey;
      }
    }
    .note {
      width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
  .space {
    flex: 1;
  }

  .icon-sec {
    position: absolute;
    top: 24px;
    right: 24px;
    @media @mobile {
      top: 16px / @pv;
      right: 16px / @pv;
    }
    display: flex;
    .operate-icon {
      height: 34px;
      width: 34px;
      padding: 8px;
      margin: 0 3px;
      cursor: pointer;
      &:hover {
        background-color: @extra-light;
        border-radius: 4px;
      }
    }
  }
}
