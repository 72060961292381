@import "../../../theme.less";
.add-member-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: @grey-bg;
  .title-wrap {
    padding-left: 24px;
    background-color: white;
    height: 64px;
    .close {
      position: absolute;
      cursor: pointer;
      height: 24px;
      right: 24px;
      top: 20px;
    }
    .title {
      font-family: "Lato-bold";
      font-size: 20px;
      color: @primary-dark;
      line-height: 64px;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      @media @mobile {
        max-width: 200px / @pv;
      }
    }
  }

  .input-wrap {
    flex: 1;
    display: flex;
    background-color: white;
    padding-left: 12px;
    border-radius: 4px;
    margin: 24px;
    @media @mobile {
      width: calc(100% - 48px);
      margin: 24px / @pv;
    }
    .input {
      flex: 1;
      .MuiFilledInput-root {
        &.Mui-focused {
          border: 1px solid white;
        }
        &:hover {
          border: 1px solid white;
        }
      }
    }
  }
  .btn-continue {
    height: 41px;
    width: 80px;
    margin: 24px;
    font-size: 14px;
  }
}

.add-member-modal-style {
  .ant-modal {
    width: 600px !important;
  }
  .ant-modal-body {
    padding: 0px;
    border-radius: 4px;
    overflow: hidden;
  }
}
.option-item-wrap {
  width: 100%;
  height: 60px;
  padding: 0 16px;
  background-color: white;
  .option-item {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    .avatar {
      width: 32px;
      height: 32px;
      background: #96979e;
      color: white;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
      font-family: "Lato-bold";
      font-size: 14px;
    }
    .name-wrap {
      display: flex;
      flex: 1;
      align-items: center;
      margin-left: 16px;
      @media @mobile {
        flex-direction: column;
        align-items: start;
      }
      .name {
        font-family: "Lato";
        font-size: 16px;
        color: @primary-dark;
        flex: 1;
      }
      .exist {
        font-family: "Lato";
        font-size: 14px;
        color: #96979e;
      }
    }
  }
}
