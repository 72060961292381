@import "../theme.less";

.social-media-item {
  height: 56px;
  width: 100%;
  position: relative;

  .icon {
    position: absolute;
    left: 12px;
    top: 28px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .input {
    width: 100%;

    .MuiFormLabel-root {
      margin-left: 36px;
    }

    .MuiFilledInput-input {
      padding-left: 48px;
    }
  }
}

.social-intsa-section {
  text-align: center;
  position: relative;
  height: auto;

  .big-icon {
    width: 48px;
    height: 48px;
  }
  .insta-btns {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 24px 0;
  }
  .btn-insta {
    background: #EAEAEC;
    margin: 0 8px;
    color: #2D2E3D;
    font-size: 14px;
    font-weight: 800;
    height: 40px;
    flex-shrink: 0;
    padding: 0 16px;
  }

  .title {
    color: #2D2E3D;
    font-weight: bold;
    margin: 16px auto;
  }

  .des {
    color: #96979E;

    a {
      font-weight: bold;
    }
  }

  .other-item-tip {
    border-top: 1px solid rgba(45, 46, 61, 0.1);
    padding: 20px 0 16px;
    color: #2D2E3D;
    font-weight: bold;
  }
}

.insta-modal {
  // width: 400px !important;
  .ant-modal-content {
    .ant-modal-body {
      padding: 44px;

      .modal-title {
        font-family: LibreBaskerville;
        text-align: center;
        font-size: 24px;
        color: #2D2E3D;
        font-weight: bold;
        margin: 0px auto;
      }

      .modal-des {
        color: #96979E;
        margin-top: 21px;
        a {
          font-weight: bold;
        }
      }
      .btns{
        margin: 38px auto 0;
        width: 90%;
        display: flex;
        justify-content: space-between;
        .ant-btn{
          height: 41px;
        }
        .btn-secondary{
          margin-left: 10px;
        }
      }
    }
  }
}