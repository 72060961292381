@import "../../../theme.less";
.add-student-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: @grey-bg;
  .close {
    position: absolute;
    cursor: pointer;
    height: 24px;
    right: 24px;
    top: 20px;
  }
  .title {
    font-family: "Lato-bold";
    font-size: 20px;
    color: @primary-dark;
    height: 64px;
    line-height: 64px;
    padding-left: 24px;
    background-color: white;
  }
  .email-input {
    margin: 24px;
    width: calc(100% - 48px) !important;
  }
  .btn-continue {
    height: 41px;
    width: 109px;
    margin: 167px 24px 24px 24px;
    font-size: 14px;
  }
}

.add-student-modal-style {
  .ant-modal {
    width: 400px !important;
  }
  .ant-modal-body {
    padding: 0px;
    border-radius: 4px;
    overflow: hidden;
  }
}
