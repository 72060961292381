@import "../../theme.less";
.dashboard-empty-root {
  width: 890px;
  margin: 48px auto;
  height: 100%;
  position: relative;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .center-sec {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media @mobile {
      padding: 0 30px / @pv;
    }
    img {
      width: 96px;
      height: 96px;
    }
    .title {
      font-family: "LibreBaskerville";
      font-size: 20px;
      line-height: 140%;
      color: @primary-dark;
      margin-bottom: 6px;
      margin-top: 48px;
      text-align: center;
    }
    .subtitle {
      font-family: "Lato";
      font-size: 12px;
      color: #96979e;
      line-height: 140%;
      text-align: center;
    }
    .btn-create {
      margin-top: 37px;
      height: 41px;
    }
  }
}
