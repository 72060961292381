.cm-get-start-overlay {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  .logo {
    width: 50px;
    align-items: left;
  }

  .header {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
  }

  .subheader {
    font-size: 14px;
    padding: 0 10px;
  }

  .media {
    padding: 20px 0;
  }

  .video {
    border: none;
  }

  .footer {

    .build-btn {
      height: 50px;
    }

    .opp-link {
      display: inline-block;
      font-size: 16px;
      padding: 10px 0;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  h1 {
    font-family: Libre Baskerville;
    font-weight: bold;
    letter-spacing: -1px;
  }

  img {
    margin-bottom: 8px;
  }

  p {
    text-align: center;
  }

  .cm-get-start-icon {
    height: 48px;
    width: 48px;
    color: #bfb8ae;
  }
}
