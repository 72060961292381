@import "../../theme.less";

.homepage-contact-wrap {
  .contact-banner .title {
    margin: 0;
  }
  .contact-content {
    background-color: #fff;

    h1 {
      margin-bottom: 48px;
    }

    .material-input {
      .MuiFilledInput-root {
        border-color: @light;
        background-color: white;
        background: white !important;
      }
      &.MuiFilledInput-underline:before {
        border-bottom: none;
      }
      &.MuiFilledInput-root.Mui-focused {
        background-color: transparent;
      }
      &.MuiFilledInput-underline:after {
        border-bottom: none;
      }
    }

    .MuiFormControl-root {
      width: 100%;

      .MuiAutocomplete-input {
        padding: 0px;
      }
    }

    .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
      padding-top: 25px;
    }

    .social-contacts-section {
      margin-top: 120px;

      a {
        display: inline-block;
        white-space: nowrap;

        img {
          margin-right: 12px;
          width: 24px;
        }
      }
    }

    .thank-you-section {
      text-align: center;

      h1 {
        margin-top: 36px;
        margin-bottom: 16px;
      }
    }
  }
  @media @desktop {
    .contact-banner {
      padding: 80px 0 120px;
      text-align: center;
      .title {
        font-size: 48px;
      }
    }
    .contact-content {
      .padding-layout {
        width: 815px;
        margin: 0 auto;
      }
      h3 {
        font-size: 32px;
        margin-bottom: 24x;
        margin-top: 48px;
      }
      h4 {
        font-size: 20px;
        margin-bottom: 8px;
        margin-top: 48px;
      }
      p {
        font-size: 16px;
        margin-bottom: 24px;
      }
      pre {
        width: 100%;
        white-space: pre-wrap;
        font-family: "Lato";
      }
    }
  }
  @media @mobile {
    .contact-banner {
      padding: 48px / @pv 0 48px / @pv;
      .title {
        font-size: 32px / @pv;
      }
    }
    .contact-content {
      h3 {
        font-size: 32px / @pv;
        margin-bottom: 24px / @pv;
        margin-top: 48px / @pv;
      }
      h4 {
        font-size: 20px / @pv;
        margin-bottom: 8px / @pv;
        margin-top: 48px / @pv;
      }
      p {
        font-size: 16px / @pv;
        margin-bottom: 24px / @pv;
      }
    }
  }
}
