@import "../../theme.less";
.add-employee-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: @grey-bg;
  .close {
    position: absolute;
    cursor: pointer;
    height: 24px;
    right: 24px;
    top: 20px;
  }
  .title {
    font-family: "Lato-bold";
    font-size: 20px;
    color: @primary-dark;
    height: 64px;
    line-height: 64px;
    padding-left: 24px;
    background-color: white;
  }
  .select {
    margin: 24px;
    min-height: 56px;
    padding: 12px 0;
    border-radius: 4px;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid white;
    &.onFocus {
      border: 1px solid @grey;
    }
    .select-center-warp {
      display: flex;
      flex-direction: column;
      width: 100%;
      .label {
        font-family: "Lato";
        font-size: 16px;
        color: @grey;
        line-height: 32px;
        margin-left: 12px;
        &.inputVisible {
          font-size: 10px;
          line-height: 140%;
          font-family: "Lato-bold";
          color: @dark-grey;
          text-transform: uppercase;
        }
      }
      .select-input {
        min-height: 34px;
        padding-left: 12px;
        &.hasTag {
          padding-left: 22px;
        }
        .ant-select-selection {
          border: none;
          box-shadow: none;
          height: 100%;
          &:focus {
            box-shadow: none;
          }
          .ant-select-selection__rendered {
            margin: 0;
            height: 34px;

            .ant-select-search {
              height: 34px;
            }
            .ant-select-selection__choice {
              display: flex;
              height: 34px;
              border-radius: 34px;
              background-color: @extra-light;
              border: 1px solid @rgba-grey-light;
              align-items: center;
              padding: 0 8px 0 12px;
              margin-right: 0;
              margin-left: -10px;
              justify-content: space-between;

              .ant-select-selection__choice__content {
                line-height: 34px;
              }
              .ant-select-selection__choice__remove {
                height: 17px;
                width: 17px;
                position: relative;
                margin-left: 5px;
                background-color: rgba(45, 46, 61, 0.25);
                border-radius: 17px;
                font-size: 10px;
                i {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  color: white;
                }
              }
            }
          }
        }
        .ant-select-selection--multiple {
          min-height: 34px;
          height: 34px;
          padding-bottom: 0;
        }
      }
    }
  }
  .btn-continue {
    height: 41px;
    width: 109px;
    margin: 167px 24px 24px 24px;
    font-size: 14px;
  }
}
.drop-down {
  width: 100%;
  max-height: 160px;
  overflow: auto;
  padding: 8px 0;
  .avatar {
    height: 32px;
    width: 32px;
    background-color: #8b8c94;
    margin-right: 16px;
    text-align: center;
    border-radius: 100px;
    line-height: 32px;
    color: white;
    font-family: "Lato-bold";
    font-size: 20px;
  }
  .result-item {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-family: "Lato";
    color: @primary-dark;
    font-size: 16px;
    line-height: 160%;
    &:hover {
      background-color: @extra-light;
    }
  }
  .no-result-item {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-family: "Lato";
    color: @primary-dark;
    &:hover {
      background-color: @extra-light;
    }
    .no-result-center {
      display: flex;
      flex-direction: column;
      font-family: "Lato";
      color: @primary-dark;
      .no-result-inputed {
        font-size: 16px;
        line-height: 160%;
      }
      .email-address {
        font-size: 12px;
        line-height: 140%;
      }
    }
  }
}
.add-employee-modal-style {
  .ant-modal {
    width: 400px !important;
  }
  .ant-modal-body {
    padding: 0px;
    border-radius: 4px;
    overflow: hidden;
  }
}
