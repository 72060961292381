@import "../theme.less";
.education-background-root {
  margin-bottom: 20px;
  .header {
    font-family: "Lato";
    font-weight: 800;
    font-size: 16px;
    line-height: 160%;
    color: @primary-dark;
    margin-bottom: 17px;
    display: block;
  }
  .input {
    width: 100%;
    margin-bottom: 16px;
  }

  .yes-or-no-wrap {
    height: 56px;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 16px;
    align-items: center;
    .flex-between-container();
    padding: 0 16px;
    .title {
      font-size: 16px;
      font-family: "Lato-bold";
      color: rgba(45, 46, 61, 0.75);
    }
    .select-wrap {
      span {
        background-color: @light;
        display: inline-block;
        color: @grey;
        font-weight: 800;
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
        &.active {
          background-color: @grey;
          color: #fff;
        }
      }
    }
  }
  .date-picker-wrap {
    position: relative;
    margin-bottom: 16px;
    .close {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .material-date-picker {
      margin-bottom: 0;
    }
  }
}
