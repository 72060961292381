@import "../../theme.less";
.student-canvas-preview-root {
  width: 1440px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  background-color: white;
  transform: scale(0.56);
  transform-origin: 0 0;
  box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.1), 0px 7px 15px rgba(0, 0, 0, 0.05),
    0px 0px 5px rgba(0, 0, 0, 0.04);
  &.isView {
    transform: scale(0.8);
    @media @mobile {
      transform: scale(0.84);
    }
  }
  &.isDetail {
    transform: scale(1);
    width: 100%;
    margin-bottom: 0;
    box-shadow: none;
  }
  @media @mobile {
    width: 375px / @pv;
    transform: none;
  }
  .preview-header {
    display: flex;
    padding-right: 24px;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaec;
    @media @mobile {
      height: 80px / @pv;
      padding: 0 30px / @pv;
      align-items: center;
    }
    .logo-left-sec {
      .btn-back {
        display: inline-block;
        color: @primary-dark;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 800;
        line-height: 80px;
        width: 118px;
        height: 100%;
        text-align: center;
        border-right: 1px solid #eaeaec;
        cursor: pointer;
        @media @mobile {
          display: none;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
      .logo-sec {
        font-family: "Lato-bold";
        font-size: 16px;
        margin-left: 24px;
        line-height: 160%;
        color: @light-grey;
        @media @mobile {
          margin-left: 0;
        }
        img {
          height: 33px;
          margin-right: 8px;
          @media @mobile {
            height: 32px / @pv;
          }
        }
        .default-avatar {
          display: inline-block;
          height: 33px;
          width: 33px;
          margin-right: 8px;
          background-color: @grey;
          border-radius: 33px;
          line-height: 33px;
          text-align: center;
          font-size: 16px;
          color: white;
        }
      }
    }

    .space {
      flex: 1;
    }
    .icon-menu {
      display: none;
      @media @mobile {
        display: block;
        width: 24px / @pv;
      }
    }
    .guide-sec {
      @media @mobile {
        display: none;
      }
      .guide-text {
        font-family: "Lato";
        font-weight: 800;
        font-size: 12px;
        line-height: 120%;
        color: @grey;
        margin-right: 24px;
        cursor: pointer;
      }
      .contact-btn {
        height: 42px;
        width: 132px;
        font-size: 12px;
        padding: 0 12px;
        img {
          width: 18px;
          margin-right: 10px;
        }
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 80px / @pv;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: @extra-light;
    align-items: center;
    padding-top: 12px / @pv;
    z-index: 2;
    transition: all ease 0.5s;
    .menu-label {
      font-family: "Lato-bold";
      font-size: 24px / @pv;
      color: @grey;
      margin-top: 16px / @pv;
      &:active {
        color: @primary-dark;
      }
    }
    .contact-btn {
      height: 51px / @pv;
      width: 182px / @pv;
      font-size: 16px / @pv;
      padding: 0 12px / @pv;
      margin-top: 24px / @pv;
      img {
        width: 18px / @pv;
        margin-right: 10px / @pv;
      }
    }
    .divider-menu {
      height: 1px;
      margin-top: 24px / @pv;
      width: 100%;
      background-color: @light;
    }
    .made-with {
      margin: 32px / @pv 0;
      font-family: "Lato";
      font-size: 14px;
      line-height: 160%;
      color: @grey;
      span {
        font-family: "Lato";
        font-size: 14px;
        line-height: 160%;
        color: @primary-dark;
        font-weight: 800;
        text-decoration: underline;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .title-sec {
    margin-left: 108px;
    margin-right: 108px;
    // margin-top: 58px;
    display: flex;
    align-items: center;
    @media @mobile {
      flex-direction: column;
      margin-left: 30px / @pv;
      margin-right: 30px / @pv;
      margin-top: 32px / @pv;
      align-items: flex-start;
    }
    .student-name {
      font-family: "LibreBaskerville";
      font-size: 48px;
      line-height: 120%;
      letter-spacing: -1px;
      color: @primary-dark;
      margin-bottom: 0;
      @media @mobile {
        font-size: 32px / @pv;
      }
    }
    .tag {
      padding: 5px 8px;
      background-color: #83b23a;
      color: white;
      text-transform: uppercase;
      font-family: "Lato-bold";
      font-size: 12px;
      margin-left: 16px;
      @media @mobile {
        font-size: 12px / @pv;
        margin-top: 26px / @pv;
        margin-left: 0;
      }
    }
  }
  .subtitle {
    margin-top: 8px;
    font-family: "Lato-bold";
    font-size: 20px;
    color: @grey;
    margin-left: 108px;
    margin-right: 108px;
    display: inline-flex;
    flex-flow: row wrap;
    .mr-16 {
      margin-right: 16px;
      margin-bottom: 6px;
    }
    .flex {
      display: flex;
      align-items: self-end;
      justify-content: flex-start;
      flex-flow: row nowrap;
    }
    .MuiSvgIcon-root {
      font-size: 28px;
      margin-right: 4px;
    }
    @media @mobile {
      font-family: "Lato";
      font-size: 14px / @pv;
      margin-left: 30px / @pv;
      margin-top: 6px / @pv;
      margin-right: 30px / @pv;
    }
    img {
      margin-right: 10px;
    }
  }
  .msg-sec {
    margin-left: 108px;
    margin-top: 16px;
    display: flex;
    margin-bottom: 118px;
    @media @mobile {
      margin-left: 30px / @pv;
      margin-top: 8px / @pv;
      margin-bottom: 30px / @pv;
      flex-wrap: wrap;
    }
    .msg-btn {
      padding: 0 24px;
      height: 51px;
      font-size: 16px;
      font-family: "Lato";
      font-weight: 800;
      margin-right: 16px;
      @media @mobile {
        height: 42px / @pv;
        padding: 0 12px / @pv;
        margin-top: 16px;
      }
      img {
        margin-right: 10px;
        width: 18px;
      }
    }
  }
  .featured-img-sec {
    width: 100%;
    background-color: @light;
    position: relative;
    text-align: center;
    @media @mobile {
      height: auto;
    }
    .featured-img {
      width: 100%;
      max-height: 768px;
      object-fit: cover;
      @media @mobile {
        max-height: 470px / @pv;
        height: auto;
      }
    }
    .featured-empty {
      display: flex;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Lato";
      font-size: 16px;
      color: @primary-dark;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80px;
        margin-bottom: 16px;
      }
    }
  }
  .media-sec {
    min-height: 728px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 128px 108px;
    @media @mobile {
      height: auto;
      flex-direction: column;
      padding: 30px / @pv;
      min-height: 0;
    }
    .portrait-photo-wrap {
      height: 504px;
      width: 504px;
      position: relative;
      background-color: #eaeaec;
      &.hidden {
        opacity: 0;
      }
      &.image-found {
        background-color: transparent;
      }
      @media @mobile {
        width: 100%;
        height: 317px / @pv;
      }
      .portrait-photo {
        width: 100%;
        height: 100%;
      }
      .empty-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Lato";
        font-size: 10px;
        color: @primary-dark;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          width: 80px;
          margin-bottom: 16px;
        }
      }
    }
    .text-sec-pro {
      margin-left: 128px;
      width: calc(100% - 624px);
      display: flex;
      flex-direction: column;
      @media @mobile {
        margin-left: 0px;
        width: 100%;
        margin-top: 32px / @pv;
      }

      .about-me {
        font-family: "LibreBaskerville";
        font-size: 40px;
        line-height: 120%;
        margin-bottom: 14px;
        color: @primary-dark;
        @media @mobile {
          width: auto;
          font-family: "LibreBaskerville-bold";
          font-size: 24px / @pv;
          margin-bottom: 8px / @pv;
        }
      }
      .desc-sec {
        display: flex;
        flex-direction: column;
        @media @mobile {
          margin-left: 0;
        }
        .desc-text {
          //word-break: break-word;
          font-family: "Lato";
          font-size: 16px;
          line-height: 160%;
          white-space: pre-wrap;
          color: @dark-grey;
          overflow: hidden;
          p{
            margin-block-end: 0;
            margin-block-start: 0;
          }
          .text-more {
            font-weight: bold;
            color: #333;
            cursor: pointer;
            margin-left: 5px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .btn-watch {
          width: 204px;
          padding: 0 24px;
          height: 51px;
          font-size: 16px;
          margin: 32px 0;

          @media @mobile {
            width: 148px / @pv;
            height: 42px / @pv;
            font-size: 12px / @pv;
            padding: 0 12px / @pv;
          }
          img {
            width: 18px;
            margin-right: 10px;
          }
        }
      }
      .relocate-item {
        margin-bottom: 15px;
        display: flex;
        width: 100%;
        align-items: flex-start;
        border-top: 1px solid #eaeaec;
        padding-top: 30px;
        .key {
          font-family: "Lato-bold";
          font-size: 10px;
          line-height: 140%;
          color: @grey;
          text-transform: uppercase;
          width: 208px;
          min-width: 208px;
          margin-top: 6px;
          @media @mobile {
            width: 104px / @pv;
            min-width: 104px / @pv;
          }
        }
        .location-list {
          flex: 1;
          .city {
            font-family: "Lato-bold";
            font-size: 16px;
            line-height: 160%;
            color: @primary-dark;
            display: inline-flex;
            align-items: center;
            .point {
              width: 4px;
              height: 4px;
              border-radius: 4px;
              background-color: #cacbce;
              margin: 0 16px;
              display: inline-block;
            }
          }
          .see-more {
            margin-top: 13px;
          }
        }
      }
    }
  }
  .education-sec {
    display: flex;
    min-height: 366px;
    padding: 120px 104px;
    background-color: #f4f5f5;
    @media @mobile {
      flex-direction: column;
      padding: 32px / @pv 30px / @pv;
    }
    .title {
      font-family: "LibreBaskerville";
      font-size: 40px;
      line-height: 120%;
      color: @primary-dark;
      @media @mobile {
        width: auto;
        font-family: "LibreBaskerville-bold";
        font-size: 24px / @pv;
      }
    }
    .edu-info {
      margin-left: 424px;
      @media @mobile {
        margin-left: 0;
        margin-top: 32px / @pv;
      }
      .kv-item {
        margin-bottom: 15px;
        width: 100%;
        .key {
          font-family: "LibreBaskerville-bold";
          font-size: 20px;
          line-height: 140%;
          color: @primary-dark;
          line-height: 140%;
          min-width: 208px;
          margin-top: 6px;
          @media @mobile {
            // width: 104px / @pv;
            min-width: 104px / @pv;
          }
        }
        .value {
          // word-break: break-all;
          font-family: "Lato-bold";
          font-size: 16px;
          line-height: 160%;
          color: @primary-dark;
          text-align: start;
        }
      }
      .education-item {
        margin-bottom: 15px;
        width: 100%;
        .key {
          font-family: "LibreBaskerville-bold";
          font-size: 20px;
          line-height: 140%;
          color: @primary-dark;
          min-width: 208px;
          margin-top: 6px;
          @media @mobile {
            // width: 104px / @pv;
            min-width: 104px / @pv;
          }
        }
        .edu-sec {
          .school-name {
            font-family: "Lato-bold";
            font-size: 16px;
            line-height: 160%;
            color: @primary-dark;
            display: inline-block;
            flex-shrink: 0;
          }
          .edu-item {
            flex-direction: column;
            margin-bottom: 8px;
            display: flex;
            .info-text {
              font-family: "Lato";
              font-size: 14px;
              line-height: 160%;
              // color: @dark-grey;
              color: @primary-dark;
            }
          }
        }
      }
    }
  }
  .work-sec {
    padding: 120px 104px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media @mobile {
      padding: 32px / @pv 0;
    }
    .img-sec {
      width: 100%;
      margin-top: 48px;
      .img-empty-list {
        display: flex;
      }
      .img-list {
        width: 100%;
        .img-item-wrap {
          height: 400px;
          width: 400px;
          padding: 8px;
          position: relative;
          @media @mobile {
            height: 280px / @pv;
            width: 280px / @pv;
          }
          .video-empty-image {
            height: 100%;
            width: 100%;
            display: block;
            line-height: 392px;
            background-color: @primary-dark;
            color: white;
            font-family: "Lato-bold";
            text-align: center;
            font-size: 240px;
            @media @mobile {
              line-height: 160px / @pv;
            }
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          .play-icon {
            width: 70px;
            height: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }
    }
    .img-control {
      margin-top: 48px;
      img {
        margin: 0 4px;
        height: 48px;
        width: 48px;
        cursor: pointer;
      }
    }
    .img-empty-item-wrap {
      height: 400px;
      width: 400px;
      position: relative;
      background-color: #eaeaec;
      margin: 0 8px;
      .empty-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Lato";
        font-size: 10px;
        color: @primary-dark;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          width: 80px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .why-sec {
    padding: 120px 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f5f5;
    @media @mobile {
      padding: 32px / @pv 30px / @pv;
    }
    .why-desc {
      // word-break: break-all;
      display: block;
      margin: 24px 0;
      width: 817px;
      font-family: "Lato";
      font-size: 16px;
      line-height: 160%;
      color: @dark-grey;
      white-space: pre-wrap;
      text-align: center;
      overflow-x: hidden;
      p{
        margin-block-end: 0;
        margin-block-start: 0;
      }
      @media @mobile {
        width: 100%;
        margin: 16px / @pv 0;
      }
    }
    .btn-drop {
      display: block;
      height: 51px;
      padding: 0 24px;
      font-size: 16px;
      img {
        width: 18px;
        margin-right: 10px;
      }
    }
  }
  .awards-sec {
    padding: 120px 108px 120px 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media @mobile {
      padding: 30px / @pv;
      align-items: flex-start;
    }
    .awards-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @media @mobile {
        flex-direction: column;
        align-items: center;
      }
      .awards-item-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 72px;
        text-align: center;
        width: calc(25% - 9px);
        margin-right: 9px;
        // word-break: break-all;
        @media @mobile {
          margin-top: 34px / @pv;
          width: 100%;
        }
        img {
          width: 48px;
          @media @mobile {
            width: 48px / @pv;
          }
        }
        .title {
          // word-break: break-all;
          font-family: "LibreBaskerville";
          font-size: 20px;
          line-height: 140%;
          margin-top: 16px;
          color: @primary-dark;
          width: 100%;
        }
        .issue {
          // word-break: break-all;
          font-family: "Lato";
          font-size: 16px;
          line-height: 160%;
          width: 288px;
          text-align: center;
          color: @grey;
          width: 100%;
        }
      }
    }
  }
  .kpi-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 108px;
    @media @mobile {
      padding: 32px / @pv 30px / @pv;
    }
    .divider {
      height: 1px;
      background-color: #dbdddf;
      margin-bottom: 120px;
      width: 100%;
      position: relative;
      @media @mobile {
        margin-bottom: 33px / @pv;
      }
    }
    .kpi-list {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin-top: 72px;
      margin-bottom: 120px;
      @media @mobile {
        margin-bottom: 32px / @pv;
        margin-top: 0;
        flex-direction: column;
      }
      .kpi-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media @mobile {
          margin-top: 32px / @pv;
        }
        .value {
          font-family: "LibreBaskerville";
          font-size: 40px;
          line-height: 120%;
          margin-bottom: 8px;
          color: @primary-dark;
        }
        .key {
          font-family: "Lato";
          font-size: 16px;
          line-height: 160%;
          color: @grey;
        }
      }
    }
    .canvas-title {
      margin-bottom: 0;
      @media @mobile {
        text-align: center;
      }
    }
  }
  .additional-sec {
    padding: 120px 63px 120px 108px;
    background-color: @grey-bg;
    position: relative;
    @media @mobile {
      padding: 30px / @pv;
    }
    .additional-info-list {
      display: flex;
      flex-direction: column;
      .additional-info-item {
        width: 100%;
        margin-top: 72px;
        display: flex;
        flex-direction: column;
        margin-right: 45px;
        @media @mobile {
          width: auto;
          margin-top: 32px / @pv;
          margin-right: 0;
        }
        .info-title {
          font-family: "LibreBaskerville-bold";
          font-size: 20px;
          line-height: 140%;
          color: @primary-dark;
          margin-bottom: 16px;
        }
        .info-item-list {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          .info-item-list-item {
            display: flex;
            align-items: center;
            height: 48px;
            @media @mobile {
              margin-right: 0;
            }
            .value {
              font-family: "Lato";
              font-size: 14px;
              line-height: 120%;
              color: @primary-dark;
              @media @mobile {
                width: auto;
              }
              &.isLong {
                width: 264px;
              }
            }
            .point {
              width: 4px;
              height: 4px;
              border-radius: 4px;
              background-color: #cacbce;
              margin: 0 16px;
            }
          }
        }
      }
    }
    .additional-info-item-employ {
      width: 100%;
      margin-top: 72px;
      padding-top: 72px;
      display: flex;
      margin-right: 45px;
      border-top: 1px solid #eaeaec;
      @media @mobile {
        width: auto;
        margin-top: 32px / @pv;
        margin-right: 0;
        padding-top: 32px / @pv;
        flex-direction: column;
      }
      .info-title {
        width: 300px;
        @media @mobile {
          width: 100%;
          margin-bottom: 24px / @pv;
        }
      }
      .see-more {
        display: inline;
        margin-left: 40px;
      }
      .info-item-list-employ {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-left: 320px;
        @media @mobile {
          margin-left: 0;
        }
        .info-item-list-item-employ {
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;
          @media @mobile {
            width: 100%;
            margin-right: 0;
          }
          .number {
            display: block;
            text-align: center;
            width: 32px;
            height: 32px;
            border-radius: 32px;
            border: 1px solid rgba(45, 46, 61, 0.25);
            font-family: "Lato";
            font-weight: 800;
            font-size: 14px;
            line-height: 32px;
            color: @primary-dark;
            margin-right: 8px;
            &.small {
              font-size: 12px;
            }
          }
          .employment-right-sec {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            .value {
              display: block;
              min-height: 6px;
            }
            .issue {
              font-family: "Lato-bold";
              font-size: 12px;
              line-height: 140%;
              color: @grey;
              // word-break: break-all;
            }
            .description {
              margin-top: 8px;
              font-family: "Lato";
              font-size: 14px;
              line-height: 160%;
              color: @primary-dark;
              //word-break: break-word;
              .text-more {
                font-weight: bold;
                color: #333;
                cursor: pointer;
                margin-left: 5px;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  .ins-sec {
    padding: 120px 104px;
    background-color: @grey-bg;
    @media @mobile {
      padding: 32px / @pv 0;
    }
    .ins-top-sec {
      position: relative;
      display: flex;
      margin-bottom: 70px;
      @media @mobile {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px / @pv;
        margin-left: 30px / @pv;
        margin-right: 30px / @pv;
      }
      .follow-wrap {
        width: 50%;
        @media @mobile {
          width: 100%;
        }
        .follow {
          font-family: "Lato";
          font-size: 16px;
          line-height: 150%;
          text-transform: uppercase;
          width: 121px;
          display: inline-block;
          @media @mobile {
            width: 100%;
          }
        }
      }

      .ins-name-sec {
        display: inline-flex;
        flex-direction: column;
        width: 50%;
        @media @mobile {
          left: 0;
          margin-top: 8px / @pv;
          width: 100%;
        }
        .ins-name {
          font-family: "LibreBaskerville";
          font-size: 40px;
          line-height: 120%;
          color: @primary-dark;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .follow-btn {
          margin-top: 24px;
          width: 120px;
          @media @mobile {
            margin-top: 16px / @pv;
          }
        }
      }
    }
    .ins-item {
      position: relative;
      cursor: pointer;
      .img {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .info-wrap {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 30%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        font-weight: bold;
      }
    }
    .ins-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media @mobile {
        display: none;
      }
      .ins-item {
        margin: 0 8px 16px;
        width: 296px;
        // height: 296px;
        max-width: calc(25% - 16px);
        .info-wrap {
          padding-left: 16px;
          padding-bottom: 18px;
          span {
            margin-right: 16px;
          }
          img {
            margin-right: 5px;
          }
        }
      }
      .ins-item-empty {
        margin: 0 8px;
        flex: 1;
        height: 296px;
        background-color: #eaeaec;
        position: relative;
        .center-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 80px;
            height: 80px;
            margin-bottom: 8px;
          }
          span {
            font-family: "Lato";
            font-size: 16px;
            line-height: 160%;
            color: @primary-dark;
          }
        }
      }
    }
    .carousel-wrap {
      width: 100%;
      .ins-item {
        margin: 0 8px / @pv;
        .info-wrap {
          padding-left: 16px / @pv;
          padding-bottom: 18px / @pv;
          span {
            font-size: 14px / @pv;
            margin-right: 16px / @pv;
          }
          img {
            width: 16px / @pv;
            margin-right: 5px / @pv;
          }
        }
      }

      .ins-item-empty {
        background-color: #eaeaec;
        height: 280px / @pv;
        width: 280px / @pv;
        position: relative;
        border-left: 8px / @pv solid @extra-light;
        border-right: 8px / @pv solid @extra-light;
        .center-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 80px;
            height: 80px;
            margin-bottom: 8px;
          }
          span {
            font-family: "Lato";
            font-size: 16px;
            line-height: 160%;
            color: @primary-dark;
          }
        }
      }
    }
  }
  .address-sec {
    padding: 120px 108px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media @mobile {
      padding: 30px / @pv;
      flex-direction: column;
      align-items: flex-start;
    }
    .address-list {
      display: flex;
      flex-direction: column;
      @media @mobile {
        align-items: flex-start;
        margin-bottom: 24px / @pv;
      }
      .location-title {
        margin-bottom: 24px;
      }
      .address-item {
        width: 496px;
        height: 64px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media @mobile {
          width: 100%;
          height: auto;
          min-height: 63px / @pv;
        }
        &.active {
          border-left: 2px solid @primary-dark;
          background: linear-gradient(
            270deg,
            #1a000000 0.1%,
            rgba(0, 0, 0, 0.1) 99.88%
          );
          .number {
            color: white;
            background-color: @grey;
            border: none;
          }
          .value {
            color: @primary-dark;
          }
        }
        .number {
          display: block;
          text-align: center;
          width: 32px;
          height: 32px;
          border-radius: 32px;
          border: 1px solid rgba(45, 46, 61, 0.25);
          font-family: "Lato";
          font-weight: 800;
          font-size: 14px;
          line-height: 32px;
          color: @primary-dark;
          margin-right: 16px;
        }
        .value {
          font-family: "Lato";
          flex: 1;
          font-size: 16px;
          line-height: 160%;
          color: @grey;
        }
      }
    }
    .map-sec {
      width: 496px;
      height: 300px;
      overflow: hidden;
      @media @mobile {
        width: 100%;
        height: 226px / @pv;
        overflow: hidden;
      }
      img {
        width: 100%;
      }
    }
  }
  .footer-media-sec {
    padding: 84px 108px 0px 108px;
    background-color: @grey-bg;
    @media @mobile {
      padding: 30px / @pv;
    }
    .media-content {
      display: flex;
      justify-content: space-between;
      @media @mobile {
        flex-direction: column;
      }
      .icon-list {
        display: flex;
        @media @mobile {
          margin-top: 16px / @pv;
        }
        img {
          width: 72px;
          height: 72px;
          border: 1px solid rgba(45, 46, 61, 0.1);
          border-radius: 4px;
          margin-left: 16px;
          padding: 24px;
          cursor: pointer;
          @media @mobile {
            width: 72px / @pv;
            height: 72px / @pv;
            margin-left: 0;
            margin-right: 16px / @pv;
          }
        }
      }
    }
    .media-divider {
      height: 1px;
      background-color: #dbdddf;
      margin: 73px 0 0 0;
      @media @mobile {
        margin: 24px / @pv 0 0 0;
      }
    }
    .made-with {
      margin: 24px 0;
      font-family: "Lato";
      font-size: 14px;
      line-height: 160%;
      color: @grey;
      @media @mobile {
        margin: 24px / @pv 0 0 0;
      }
      span {
        font-family: "Lato";
        font-size: 14px;
        line-height: 160%;
        color: @primary-dark;
        font-weight: 800;
        text-decoration: underline;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .additional-sec + .ins-sec,
  .additional-sec + .awards-sec[hidden] + .ins-sec,
  .ins-sec + .footer-media-sec,
  .education-sec + .work-sec[hidden] + .why-sec,
  .ins-sec + div[hidden] + .footer-media-sec {
    position: relative;
    &::before {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 0;
      right: 68px;
      left: 68px;
      background-color: #dbdddf;
    }
  }

  .awards-sec + .address-sec,
  .awards-sec + .ins-sec[hidden] + .address-sec,
  .why-sec
    + .additional-sec[hidden]
    + .awards-sec[hidden]
    + .ins-sec[hidden]
    + .address-sec {
    position: relative;
    &::before {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 0;
      right: 68px;
      left: 68px;
      background-color: #eaeaec;
    }
  }
}

.canvas-title {
  font-family: "LibreBaskerville";
  font-size: 40px;
  line-height: 120%;
  color: @primary-dark;
  @media @mobile {
    font-size: 24px;
    line-height: 140%;
  }
}

.draggable {
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-bottom: 16px;
    color: gray;
    margin-right: 8px;
  }
}

.flex-fill {
  flex: 1;
}

.cm-book-button {
  transition: 0ms !important;
  height: 48px !important;
  width: 200px !important;
  z-index: 999 !important;
  @media @mobile {
    position: sticky !important;
    left: 30% !important;
  }
}

@media (min-width: 1024px) {
  .cm-sticky-book-btn {
    position: sticky !important;
    top: 100px !important;
    left: 80% !important;
  }
  
  .cm-not-sticky-book-btn {
    position: relative !important;
    top: 53px !important;
    left: 80% !important;
  }
}

