@import "../theme.less";
.create-header-root {
  height: @create-header-height !important;
  background: @grey-bg !important;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  border-bottom: 1px solid @rgba-grey-light;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;

  .autosave-hint {
    font-size: 10px;
    margin-right: 4px;
    @media @mobile {
      display: none;
    }
  }

  .header-wrap {
    margin-top: 10px;
    width: 100%;
    display: flex;
    height: 100%;
    position: relative;
    justify-content: space-between;
    .logo {
      width: 100px;
      margin-right: 24px;
    }
    .operate-sec {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      .saved {
        font-family: "Lato-bold";
        font-size: 14px;
        line-height: 160%;
        color: @primary-dark;
        display: flex;
        align-items: center;
        transition: all ease 0.3s;
        .check-green {
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }
      }
      .btn-draft {
        height: 51px;
        .icon-view {
          display: none;
        }
      }
      .btn-preview {
        margin-left: 8px;
        height: 51px;
        .icon-view {
          display: none;
        }
      }
      .btn-edit {
        margin-left: 8px;
        width: 79px;
        height: 51px;
      }
      .btn-publish {
        width: 105px;
        height: 51px;
        margin-left: 8px;
        line-height: 51px;
        @media @mobile {
          width: 82px / @pv;
          height: 41px / @pv;
          line-height: 41px / @pv;
        }
      }
    }
  }
  .progress {
    position: absolute;
    display: flex;
    height: 100%;
    border-left: 1px solid @rgba-grey-light;
    left: 148px;
    align-items: center;
    .progress-item {
      display: flex;
      align-items: center;
      margin-left: 17px;
      .item-text {
        font-family: "Lato-bold";
        font-size: 14px;
        line-height: 160%;
        color: @light-grey;
        display: flex;
        align-items: center;
        &.isHeighLight {
          color: @primary-dark;
        }
        &.pointer {
          cursor: pointer;
        }
      }
      .circle {
        height: 15px;
        width: 15px;
        display: inline-block;
        border: 1.5px solid @light-grey;
        border-radius: 100px;
        margin-right: 10px;
      }
      .ic-step {
        width: 15px;
        margin-right: 10px;
      }
    }
    .stash {
      height: 1px;
      width: 24px;
      margin-left: 16px;
      background-color: @light-grey;
    }
  }
  @media @mobile {
    height: 104px !important;
    flex-direction: column;
    border-bottom: none;
    padding: 0 !important;
    .header-wrap {
      height: 64px / @pv;
      padding: 0 16px / @pv;
      .operate-sec {
        right: 16px;
        .saved {
          font-size: 14px / @pv;
          .check-green {
            width: 18px / @pv;
            margin-right: 8px / @pv;
          }
        }
        .btn-draft {
          width: 50px / @pv;
          margin-left: 8px / @pv;
          height: 42px / @pv;
          font-size: 14px / @pv;
          .icon-view {
            display: inline-block;
            width: 18px / @pv;
          }
          .txt-preview {
            display: none;
          }
        }
        .btn-preview {
          width: 50px / @pv;
          margin-left: 8px / @pv;
          height: 42px / @pv;
          font-size: 14px / @pv;
          .icon-view {
            display: inline-block;
            width: 18px / @pv;
          }
          .txt-preview {
            display: none;
          }
        }
        .btn-edit {
          width: 60px / @pv;
          height: 42px / @pv;
          margin-left: 8px / @pv;
          font-size: 14px / @pv;
        }
        .btn-publish {
          width: 82px / @pv;
          height: 42px / @pv;
          font-size: 14px / @pv;
        }
      }
    }
    .progress {
      position: relative;
      height: 40px;
      width: 100%;
      left: 0;
      background-color: @light;
      padding: 0 24px;
      border-left: none;
      .progress-item {
        margin-left: 10px;
        .item-text {
          display: none;
          &.isHeighLight {
            display: inline;
            color: @primary-dark;
          }
          &.pointer {
            cursor: pointer;
          }
        }
        .circle {
          &.isHeighLight {
            display: none;
          }
        }
        .ic-step {
          &.isHeighLight {
            display: none;
          }
        }
      }
      .stash {
        flex: 1;
        margin-left: 10px;
      }
    }
  }
}
