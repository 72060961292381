@import "../../../theme.less";
.opportunity-detail-root {
  width: 1440px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  background-color: white;
  //   transform: scale(0.56);
  //   transform-origin: 0 0;
  //   box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.1), 0px 7px 15px rgba(0, 0, 0, 0.05),
  //     0px 0px 5px rgba(0, 0, 0, 0.04);
  //   &.isDetail {
  //     transform: scale(1);
  width: 100%;
  margin-bottom: 0;
  //     box-shadow: none;
  //   }
  .home-header {
    background-color: black;
    border-bottom: 1px solid @rgba-grey-light;
  }
  @media @mobile {
    width: 375px / @pv;
    transform: none;
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    &.isDetail {
      margin-top: @header-height;
      @media @mobile {
        margin-top: @mibile-header-height;
      }
    }
  }

  .job-type {
    padding: 5px 9px;
    color: #ffffff;
    background-color: #83b23a;
    &.pc-type {
      display: block;
    }

    &.mobile-type {
      display: none;
      width: fit-content;
      margin-left: 108px;
    }
    @media @mobile {
      &.pc-type {
        display: none;
      }
      &.mobile-type {
        display: block;
        margin-left: 30px / @pv;
        margin-top: 32px / @pv;
      }
    }
  }

  .opportunity-name {
    font-family: "LibreBaskerville";
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -1px;
    margin-left: 108px;
    color: @primary-dark;
    margin-top: 58px;
    margin-bottom: 0;
    @media @mobile {
      font-size: 32px / @pv;
      margin-left: 30px / @pv;
      margin-right: 30px / @pv;
      margin-top: 16px / @pv;
    }
  }
  .opportunity-subtitle {
    display: flex;
    align-items: center;
    margin-top: 24px;
    font-size: 16px;
    color: @rgba-grey;
    margin-left: 108px;
    margin-right: 108px;
    .location {
      margin-left: 26px;
    }
    .canvas-name {
      display: flex;
      align-items: center;
      margin-left: 23px;
      text-decoration: underline;
      cursor: pointer;
      .canvas-image-wrap {
        margin-right: 8px;
        // width: 50px;
        height: 50px;
        // border-radius: 50%;
        .canvas-img {
          // width: 100%;
          height: 100%;
          // border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    @media @mobile {
      font-family: "Lato";
      font-size: 14px / @pv;
      margin-left: 30px / @pv;
      margin-top: 6px / @pv;
      margin-right: 30px / @pv;
      flex-direction: column;
      align-items: flex-start;
      .location,
      .canvas-name {
        margin-left: 0;
      }
      .location {
        margin-top: 16px / @pv;
      }
      .canvas-name {
        margin-top: 10px / @pv;
      }
    }
    img {
      margin-right: 10px;
    }
  }

  .msg-sec {
    margin-left: 108px;
    margin-top: 48px;
    display: flex;
    @media @mobile {
      flex-wrap: wrap;
      margin-left: 30px / @pv;
      margin-top: 16px / @pv;
    }
    .msg-btn {
      padding: 0 24px;
      height: 51px;
      font-size: 16px;
      font-family: "Lato";
      font-weight: 800;
      margin-right: 16px;
      @media @mobile {
        padding: 0 12px / @pv;
        margin-top: 8px / @pv;
        height: 42px / @pv;
        font-size: 14px / @pv;
      }
      img {
        margin-right: 10px;
        width: 18px;
      }
    }
  }

  .featured-img-sec {
    position: relative;
    width: 100%;
    background-color: @light;
    margin-top: 72px;
    @media @mobile {
      max-height: 470px / @pv;
      margin-top: 24px / @pv;
      height: auto;
      overflow: hidden;
    }
    .featured-img {
      width: 100%;
      max-height: 768px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .featured-empty {
      display: flex;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Lato";
      font-size: 16px;
      color: @primary-dark;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80px;
        margin-bottom: 16px;
      }
    }

    .btn-wrap {
      position: fixed;
      bottom: 24px;
      right: 24px;
      z-index: 1;
      display: flex;
      padding: 16px;
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid @rgba-grey-light;
      .msg-btn {
        padding: 0 16px;
        height: 41px;
        font-weight: 800;
        font-size: 14px;
        font-family: Lato;
        &:not(:first-child) {
          margin-left: 16px;
        }
      }

      @media @mobile {
        bottom: 24px / @pv;
        right: 24px / @pv;
        padding: 16px / @pv;
        .msg-btn {
          padding: 0 16px / @pv;
          height: 41px / @pv;
          font-size: 14px / @pv;
        }
      }
    }
  }

  .details-table {
    display: table;
    margin-top: 32px;
    .table-row {
      display: table-row;
      height: 42px;
      .table-header-cell,
      .table-cell {
        display: table-cell;
        font-family: Lato;
        font-weight: bold;
      }

      .table-header-cell {
        width: 80px;
        font-size: 10px;
        color: @grey;
      }
      .table-cell {
        padding-left: 24px;
        font-size: 16px;
        color: @primary-color;
      }
    }
    @media @mobile {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      .table-row {
        display: flex;
        flex-direction: column;
        margin-top: 21px / @pv;
        height: auto;
        .table-header-cell {
          font-size: 10px / @pv;
        }
        .table-cell {
          margin-top: 8px / @pv;
          padding-left: 0;
          font-size: 16px / @pv;
        }
      }
    }
  }

  .opportunity-info-wrap {
    display: flex;
    padding: 72px 104px 60px;
    width: 100%;
    .info-title {
      font-family: "LibreBaskerville-bold";
      font-size: 32px;
      letter-spacing: -1px;
      color: @primary-color;
      margin-bottom: 0;
    }
    .left-wrap {
      flex: 7.1;
      .job-desc {
        margin-top: 32px;
      }
    }

    @media @mobile {
      flex-direction: column;
      padding: 30px / @pv;
      .info-title {
        font-size: 24px / @pv;
      }
      .left-wrap .job-desc {
        margin-top: 24px / @pv;
      }
    }
    .right-wrap {
      flex: 4;
      margin-left: 120px;
      min-width: 400px;
      .feature-vedio-wrap {
        margin-top: 24px;
      }
      .main-video {
        margin-top: 10px;
        height: 400px;
        width: 100%;
        background-color: @light;
        position: relative;
        @media @mobile {
          width: 100%;
          height: 250px / @pv;
        }
        .featured-video-cover {
          width: 100%;
          height: 100%;
          position: relative;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          .inner-img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
          }
          .video-empty-image {
            height: 100%;
            width: 100%;
            display: block;
            line-height: 400px;
            background-color: @primary-dark;
            color: white;
            font-family: "Lato-bold";
            text-align: center;
            font-size: 240px;
            @media @mobile {
              line-height: 250px / @pv;
              font-size: 150px / @pv;
            }
          }
          .play {
            width: 70px;
            height: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
        .empty-video {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: "Lato";
          font-size: 10px;
          color: @primary-dark;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            margin-bottom: 16px;
          }
        }
      }
      @media @mobile {
        margin-left: 0;
        min-width: auto;
        .info-title {
          font-size: 24px / @pv;
        }
        .feature-vedio-wrap {
          margin-top: 36px / @pv;
          .main-video {
            margin-top: 10px / @pv;
          }
        }
      }
    }
  }

  .company-info-wrap {
    display: flex;
    padding: 72px 104px 72px;
    width: 100%;
    background-color: @extra-light;
    .left-wrap {
      flex: 7.1;
      .company-info {
        .company-name {
          font-family: "LibreBaskerville-bold";
          font-size: 40px;
          letter-spacing: -1px;
          color: @primary-color;
        }
        .company-desc {
          margin-top: 24px;
          font-family: Lato;
          font-size: 16px;
          color: @dark-grey;
          white-space: pre-wrap;
          display: block;
        }
      }
      .additional-info-wrap {
        margin-top: 48px;
        padding-top: 16px;
        border-top: 1px solid @rgba-grey-light;
        .details-table {
          .table-header-cell {
            width: 192px;
          }
        }
      }
    }
    .right-wrap {
      margin-left: 120px;
      flex: 4;
      min-width: 400px;
    }

    .company-image-wrap {
      height: 400px;
      width: 100%;
      background-color: @light;
      position: relative;
      .company-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .company-empty {
        display: flex;
        flex-direction: column;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: "Lato";
        font-size: 16px;
        color: @primary-dark;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 80px;
          margin-bottom: 16px;
        }
      }
      &.mobile-image {
        display: none;
      }
    }
    @media @mobile {
      flex-direction: column;
      padding: 30px / @pv;
      .left-wrap {
        .company-info {
          .company-name {
            font-size: 24px / @pv;
          }
          .company-desc {
            margin-top: 24px / @pv;
            font-size: 16px / @pv;
          }
        }
        .additional-info-wrap {
          margin-top: 3px / @pv;
          padding-top: 0;
          border-top: none;
          .details-table {
            .table-header-cell {
              color: @dark-grey;
            }
          }
        }
      }

      .company-image-wrap {
        width: 100%;
        height: 250px / @pv;
        margin-top: 24px / @pv;
        &.mobile-image {
          display: block;
        }
        &.pc-image {
          display: none;
        }
      }
    }
  }

  .similars-wrap {
    padding: 72px 104px 76px;
    width: 100%;
    .similiar-title {
      font-family: "LibreBaskerville-bold";
      font-size: 40px;
      letter-spacing: -1px;
      color: @primary-color;
    }
    .similiar-list-wrap {
      display: flex;
      margin-top: 24px;
      cursor: pointer;
      .similiar-card {
        flex: 1;
        max-width: calc((100% - 32px) / 3);
        border-radius: 4px;
        overflow: hidden;
        transition: 0.3s;
        &:hover {
          .shadow-box();
          transform: translateY(-16px);
        }
        &:not(:first-child) {
          margin-left: 16px;
        }
        .image {
          height: 300px;
          overflow: hidden;
          > img {
            height: 100%;
            width: 100%;
          }
        }
        .card-info-wrap {
          padding: 24px;
          height: 195px;
          border: 1px solid rgba(45, 46, 61, 0.1);
          border-radius: 0 0 4px 4px;
          .info-status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            .left-sec {
              display: flex;
              font-family: "Lato";
              font-weight: bold;
              .type,
              .date-status {
                padding: 5px 8px;
              }
              .type {
                background-color: @extra-light;
                color: @primary-color;
              }
              .date-status {
                margin-left: 8px;
                color: #5a8e09;
                border: 1px solid rgba(131, 178, 58, 0.25);
              }
            }
            .right-sec {
              color: @grey;
            }
          }
          .job-title {
            margin-top: 16px;
            font-family: "LibreBaskerville";
            font-weight: bold;
            font-size: 20px;
            line-height: 140%;
            letter-spacing: -0.5px;
            color: @primary-color;
          }

          .location-info,
          .canvas-info {
            display: flex;
            align-items: flex-start;
            margin-top: 8px;
            color: @rgba-grey;
            .location {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .card-icon {
              margin: 2px 10px 0 0;
            }
          }
          .location-info {
            margin-top: 16px;
          }
        }
      }
    }

    @media (min-width: 1440px) {
      .similiar-list-wrap .similiar-card:not(:first-child) {
        margin-left: 16px / @pv;
      }
    }

    @media @mobile {
      padding: 32px / @pv 30px / @pv;
      .similiar-title {
        font-size: 24px / @pv;
      }
      .similiar-list-wrap {
        margin-top: 16px / @pv;
        flex-direction: column;
        .similiar-card {
          max-width: none;
          &:not(:first-child) {
            margin-top: 24px / @pv;
            margin-left: 0;
          }
          img {
            height: 100%;
          }
          .card-info-wrap {
            padding: 24px / @pv;
            .info-status {
              font-size: 12px / @pv;
              .type {
                padding: 5px / @pv 8px / @pv;
              }
              .date-status {
                display: none;
              }
            }

            .job-title {
              margin-top: 21px / @pv;
              font-size: 20px / @pv;
            }
            .canvas-info {
              margin-top: 8px / @pv;
            }
            .location-info {
              margin-top: 16px / @pv;
            }
          }
        }
      }
    }
  }
}

.redirect-modal {
  .link-tips {
    margin-top: 40px;
    font-family: 'Lato';
    font-size: 18px;
    text-align: center;
  }
  .link-btns {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    button {
      height: 40px;
      margin-right: 20px;
    }
  }
}

.how-to-apply {
  margin-bottom: 40px;
  text-align: center;
  h1 {
    font-family: serif;
    font-size: 36px;
    @media @mobile {
      font-size: 6.4vw;
    }
    font-weight: 500;
  }
  .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media @mobile {
      flex-direction: column;
      align-content: center;
    }
    .image {
      text-align: center;
      width: 17vw;
      @media @mobile {
        width: 60vw;
      }
      p {
        color: black;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }
  Button {
    margin: 20px 0;
    height: 50px;
    padding: 0 20px;
  }
}
