@import "../theme.less";
.field-require-root {
  display: flex;
  flex-direction: column;
  .title {
    font-family: "Lato-bold";
    font-size: 18px;
    margin-bottom: 16px;
  }
  .btn-sec {
    margin-top: 16px;
    display: flex;
    flex-direction: row-reverse;
    .btn-close {
      margin-left: 16px;
    }
  }
}
