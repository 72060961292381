@import "../theme.less";
.header-root.ant-layout-header {
  height: @header-height;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: fixed;
  width: 100%;
  top: 0;
  padding: 0;
  align-items: center;
  background-color: @neutral-dark;  
  border-bottom: 1px solid rgba(45, 46, 61, 0.1);
  .left-sec {
    flex: 1;
  }
  .text {
    color: @neutral-light;
    font-family: "Lato";
    font-weight: 800;
    font-size: 14px;
    line-height: 120%;
    margin-left: 24px;
    letter-spacing: 0.04em;
    cursor: pointer;
    &.active {
      color: #999999;
    }
  }
  .ic-arrow {
    width: 24px;
    padding: 8px;
  }
  .right-sec {
    margin-right: 24px;
    display: flex;
    align-items: center;
    .back-btn {
      color: white;
      margin-right: 16px;
      text-transform: capitalize;
      font-weight: bold;
      @media @mobile {
        display: none;
      }
    }
    .post-opportunity-btn {
      margin-right: 16px;
      background-color: #fff;
      color: #000;
      text-transform: capitalize;
      font-weight: bold;
    }
    .btn-start {
      display: inline;
      margin-left: 24px;
      font-size: 14px;
    }
    .sign-up-mobile{
      display: none;
    }
    .user-sec {
      .avatar {
        height: 32px;
        width: 32px;
        margin-right: 16px;
        cursor: pointer;
      }
      .name {
        color: white;
        font-family: "Lato";
        font-weight: 800;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.04em;
        cursor: pointer;
      }
    }
    .ic-menu {
      display: none;
    }
  }
  .logo-sec {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    margin-left: 16px;

    span {
      display: inline-block;
      margin: 1px 0 0 10px;
      font-family: "Lato";
      font-weight: bold;
      white-space: nowrap;
    }
    &.landing-logo {
      img {
        width: 410px;
        height: auto;
      }
    }
  }

  @media @mobile {
    height: @mibile-header-height;
    .left-sec {
      & > * {
        display: none;
      }
    }
    .logo-sec {
      width: 103px / @pv;
      //position: relative;
      left: 30px / @pv;
      top: 0;
      transform: none;

      span {
        margin: 10px 0 0 0;
      }
      &.landing-logo {
        width: 250px;
        img {
          width: 100%;
        }
        
      }
    }
    .right-sec {
      margin-right: 24px / @pv;
      display: flex;
      align-items: center;
      .post-opportunity-btn {
        display: none;
      }
      .user-sec {
        display: none;
      }
      .btn-start {
        display: none;
        margin-left: 12px / @pv;
        font-size: 12px / @pv;
        padding: 0 12px / @pv;
        &.hidden {
          display: none;
        }
      }
      .sign-up-mobile{
        display: inline;
        color: white;
        text-decoration: underline;
        font-weight: bold;
      }
      .login {
        display: none;
      }
      .ic-menu {
        display: inline-flex;
        height: 24px / @pv;
        width: 24px / @pv;
        margin-left: 12px / @pv;
      }
    }
  }
}
.menu-popover {
  .ant-popover-arrow {
    width: 16px;
    height: 16px;
    background: black;
    border-top-color: black !important;
    border-left-color: black !important;
    top: 3px;
  }
  .ant-popover-inner-content {
    width: 375px;
    background-color: black;
    border-radius: 4px;
    padding: 56px 50px;
  }

  .menu-popover-content {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: "Lato";
    font-weight: 800;
    font-size: 16px;
    line-height: 120%;
    color: white;
    span {
      margin: 12px 0;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      position: relative;
      display: flex;
      cursor: pointer;
      &.notification-active {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 12px;
          background-color: #ff6b00;
        }
      }
    }
    .sign-out {
      color: #ff6b00;
    }
  }
}
