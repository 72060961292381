.view-admin-external-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    button {
        margin: 10px;
        padding: 5px 15px;
        background-color: #2d2e3d;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
    }
}
