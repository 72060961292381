.btn-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  .status-content {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
}
