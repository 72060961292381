@import "../theme.less";
.editor-container {
  background: #ffffff;
  border-radius: 4px;
  .editor-toolbar-wrap {
    margin-bottom: 0;
    padding: 8px;
    border: none;
    .inline-wrap,
    .text-align-wrap,
    .list-wrap,
    .link-wrap,
    .rdw-fontsize-wrapper,
    .rdw-fontfamily-wrapper,
    .rdw-colorpicker-wrapper,
    .rdw-image-wrapper {
      margin-bottom: 0;
      .rdw-dropdown-wrapper {
        border: none;
        &:hover {
          box-shadow: none;
          background-color: #f4f5f5;
          border-radius: 4px;
        }
      }
      .rdw-option-wrapper {
        margin: 0;
        padding: 0;
        width: 48px;
        height: 48px;
        border: none;
        &.rdw-option-active {
          box-shadow: none;
          border: none;
          background-color: #f4f5f5;
          border-radius: 4px;
        }
        &:hover {
          box-shadow: none;
          // border: none;
          background-color: #f4f5f5;
          border-radius: 4px;
        }
      }
    }
    .link-popup-wrap {
      width: auto;
      height: auto;
      font-family: Lato;
    }
    @media @mobile {
      .link-popup-wrap,
      .image-popup-wrap {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .editor-content-wrap {
    padding: 0 12px 32px;
    border-top: 1px solid @rgba-grey-light;
    color: #2d2e3d;
    font-size: 16px;
    font-family: Lato;
  }
}

.rdw-colorpicker-option {
  margin: 3px !important;
  padding: 0 !important;
  min-height: 20px !important;
  border: none !important;
  width: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  box-shadow: 1px 2px 1px #BFBDBD inset !important;
}

.rdw-colorpicker-modal-options {
  overflow: auto;
}

.rdw-colorpicker-modal {
  width: 200px;
}
