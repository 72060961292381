@import "../../../theme.less";

.home-root .my-account-opportunity {
  .title-wrap {
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    margin-top: 24px;
    .btn-left-sec,
    .btn-right-sec {
      display: flex;
      align-items: center;
    }
    .btn-right-sec {
      margin-left: 25px;
    }
    .vertical-line,
    .btn-tips {
      margin-left: 25px;
    }
    .vertical-line {
      height: 42px;
      width: 1px;
      background-color: @rgba-grey-light;
    }
    .btn-tips {
      font-family: Lato;
      font-size: 16px;
      color: @rgba-grey;
    }
  }
  .btn-secondary {
    height: 42px;
    font-size: 14px;
  }
  .filter-wrap {
    padding: 32px 0 16px;
    //   border-bottom: 1px solid @rgba-grey-light;
    .ant-col {
      height: 56px;
      &:last-child {
        display: flex;
        align-items: center;
      }

      .material-input {
        width: 100%;
      }
      .keyword-input {
        height: 100%;
      }
      .input .MuiInputBase-root {
        height: 56px;
      }
    }
  }
  .status-wrap {
    margin-top: 16px;
  }
  .opp-list-wrap {
    margin-top: 24px;
  }
  .posted-opp-btn {
    margin-top: 48px;
  }
  .loading {
    margin-top: 60px;
  }
  @media @mobile {
    .btn-wrap {
      flex-direction: column;
      align-items: flex-start;

      .btn-right-sec {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .filter-wrap {
      display: flex;
      flex-direction: column;
      .ant-col {
        width: 100%;
        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }

    .posted-opp-btn {
      margin-top: 32px;
    }
  }
}
