@import "../../../theme.less";

.business-pay-tip {
    width: 100%;
    height: 100%;
    .tip-content {
      text-align: center;
      padding: 110px;
      @media @mobile {
        padding: 16px;
      }
      .tip-title {
        margin: 6px auto;
        font-family: 'LibreBaskerville';
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-align: center;
        color: @primary-color;
        @media @mobile {
          width: 100%;
        }
      }
      .tip-des {
        width: 423px;
        @media @mobile {
          width: 100%;
        }
        margin: 6px auto;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        line-height: 160%;
        color:  #96979e;
      }
      .btn-pay {
        height: 51px;
        margin-top: 10px;
        background: #2d2e3d;
        color: white;
        font-family: "Lato";
        font-weight: 800;
        font-size: 16px;
        border-radius: 4px;
        letter-spacing: 0.04em;
        border: none;
      }
    }
  }