@import "../../../theme.less";

.notification-container {
  display: flex;
  flex-direction: column;
  width: 890px;
  margin: 24px auto;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  i {
    font-style: normal;
  }

  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    color: @primary-dark;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @media @mobile {
      flex-flow: column nowrap;
      align-items: flex-start;
      .title-btn {
        margin-top: 16px;
      }
    }
    i,
    button {
      height: 40px;
      line-height: 40px;
      padding: 0px 16px;
      border: 1px solid rgba(45, 46, 61, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      align-items: center;
      letter-spacing: 0.04em;
      color: #2d2e3d;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      font-family: Lato;
      text-transform: inherit;
      cursor: pointer;
      &:first-child {
        margin-right: 12px;
      }
    }
  }

  .empty-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "LibreBaskerville";
    font-size: 20px;
    line-height: 140%;
    margin-top: 210px;
    color: @primary-dark;

    span {
      margin-bottom: 12px;
    }

    .detail {
      font-family: "Lato";
      font-size: 12px;
      color: @grey;
      line-height: 140%;
    }

    img {
      width: 96px;
      height: 96px;
      margin-bottom: 24px;
    }

    @media @mobile {
      margin-top: 160px;
    }
  }

  .list-wrapper {
    margin-top: 28px;
    @media @mobile {
      margin-top: 40px / @pv;
    }

    .select-wrapper {
      padding-left: 16px;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 16px;
      color: #2d2e3d;
      .box-item-check {
        margin-right: 8px;
      }
    }

    .ant-checkbox-group {
      width: 100%;
    }

    .list-item {
      padding: 16px;
      position: relative;
      background-color: #fff;
      @media @mobile {
        padding-right: 0px / @pv;
      }
      &:not(:last-child):before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: rgba(45, 46, 61, 0.1);
      }

      &.link i {
        cursor: pointer;
      }

      &.unread {
        background-color: transparent;
        &:not(:last-child):before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 16px;
          right: 16px;
          height: 0px;
          background-color: rgba(45, 46, 61, 0.1);
        }

        .list-item-content {
          font-weight: bold;
          position: relative;
          &:after {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 12px;
            background-color: #ff6b00;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .list-item-content {
        font-size: 16px;
        line-height: 1.5;
        color: #2d2e3d;
        padding-right: 62px;
        margin-bottom: 8px;
        display: flex;
        flex-flow: row nowrap;
        .box-item-check {
          margin-right: 8px;
          margin-top: 1px;
        }
        @media @mobile {
          padding-right: 32px / @pv;
        }
      }

      span.list-item-date {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        padding-left: 24px;
        line-height: 1.5%;
        color: #96979e;
      }
    }
  }

  .MuiTablePagination-root {
    margin-top: 16px;
  }
}
