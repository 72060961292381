@import "../../theme.less";
.container {
  background-color: @grey-bg;
  margin-top: 90px !important;
  .center-wrap {
    width: 920px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .title {
      font-family: "LibreBaskerville-bold";
      font-size: 32px;
      color: @primary-dark;
      line-height: 120%;
      margin-top: 48px;
    }
    .subtitle {
      font-family: "LibreBaskerville";
      font-size: 20px;
      color: @primary-dark;
      margin-top: 50px;
      line-height: 140%;
      margin-bottom: 18px;
      img {
        width: 30px;
        margin-right: 17px;
      }
    }

    .input {
      margin-top: 24px;
    }
    .location-detail {
      display: flex;
      flex-direction: column;
      .program-input {
        margin-top: 24px;
      }
      .accredited-sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        .accredited-text {
          font-family: "Lato";
          font-size: 16px;
          line-height: 160%;
          color: @primary-dark;
        }
      }
      .location-divider {
        height: 1px;
        background-color: @rgba-grey-light;
        margin-top: 50px;
      }
      .location-title {
        font-family: "LibreBaskerville";
        font-size: 20px;
        color: @primary-dark;
        margin-top: 48px;
        line-height: 140%;
        img {
          width: 32px;
          margin-right: 16px;
        }
      }
      .chais-sec {
        display: flex;
        .input {
          flex: 1;
        }
        .footage-wrap {
          position: relative;
          height: 56px;
          flex: 1;
          margin-top: 24px;
          .footage-input {
            width: 100%;
          }
          .input{
            margin-top: 0;
          }
          .unit-btn {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 4px;
            line-height: 29px;
            height: 29px;
            background-color: @extra-light;
            padding-left: 6px;
            cursor: pointer;
            img {
              width: 8px;
              margin-left: 8px;
              margin-right: 10px;
            }
          }
        }
        :first-child {
          margin-right: 24px;
        }
      }
      .empty-employee-sec {
        height: 98px;
        background-color: white;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        padding: 0 24px;
        align-items: center;
        margin-top: 24px;
        .left-sec {
          display: flex;
          .icon-employee {
            width: 32px;
            margin-right: 16px;
          }
          .left-center {
            display: flex;
            flex-direction: column;
            .employee {
              font-family: "LibreBaskerville";
              font-size: 20px;
              line-height: 140%;
              color: @primary-dark;
            }
            .later {
              font-family: "Lato";
              font-size: 14px;
              line-height: 160%;
              color: @rgba-grey;
            }
          }
        }
        .btn-add-employee {
          // width: 191px;
          height: 56px;
        }
      }
      .employee-sec {
        margin-top: 48px;
        .employee-header {
          font-family: "LibreBaskerville";
          font-size: 20px;
          line-height: 140%;
          color: @primary-dark;
          .icon-employee {
            width: 32px;
            margin-right: 16px;
          }
        }
      }
    }
    .extra-location {
      margin-top: 24px;
      display: flex;
      align-items: center;
      .input {
        flex: 1;
        margin-top: 0;
      }
      .icon-delete {
        width: 18px;
        cursor: pointer;
        margin: 0 16px;
      }
    }
    .btn-add {
      height: 56px;
      width: 223px;
      margin-top: 24px;
      @media @mobile {
        height: 42px / @pv;
      }
    }
    .btn-school-next {
      width: 223px;
      height: 51px;
      margin-top: 48px;
      margin-bottom: 104px;
      @media @mobile {
        margin: 32px / @pv 0;
      }
    }
    .bottom-sec {
      width: 100%;
      margin: 48px 0;
      display: flex;
      flex-direction: column;
      @media @mobile {
        margin: 32px 0;
        border-top: 1px solid rgba(45, 46, 61, 0.1);
        padding-top: 32px;
      }
      .tip {
        max-width: 424px;
        text-align: right;
        font-family: "Lato";
        font-size: 16px;
        color: rgba(45, 46, 61, 0.5);
        line-height: 160%;
        @media @mobile {
          max-width: 100%;
          text-align: left;
          margin-top: 16px;
        }
      }
      .next-sec {
        display: flex;
        justify-content: space-between;
        @media @mobile {
          flex-direction: column;
        }
        .btn-next {
          height: 51px;
          padding: 0 24px;
          @media @mobile {
            padding: 0 24px / @pv;
          }
        }
      }
      .skip-sec {
        display: flex;
        justify-content: space-between;
        height: 100px;
        background: #eaeaec;
        border-radius: 4px;
        align-items: center;
        padding: 0 24px;
        margin-top: 48px;
        @media @mobile {
          height: auto;
          flex-direction: column;
          align-items: flex-start;
          justify-content: unset;
          padding: 16px / @pv;
          margin-top: 32px / @pv;
        }
        .btn-skip {
          height: 51px;
          padding: 0 16px;
          @media @mobile {
            padding: 0 16px / @pv;
            font-size: 16px / @pv;
            height: 42px;
          }
        }
      }
    }
  }
  @media @mobile {
    margin-top: 104px;
    .center-wrap {
      width: 100vw - (60px / @pv);
      .title {
        font-size: 24px / @pv;
        margin-top: 32px / @pv;
      }
      .subtitle {
        font-family: "Lato";
        font-weight: 800;
        font-size: 16px / @pv;
        margin-top: 32px / @pv;
        margin-bottom: 0;
        img {
          width: 24px / @pv;
          margin-right: 8px / @pv;
        }
      }
      .input {
        margin-top: 16px / @pv;
      }
      .company-input {
        margin-top: 16px / @pv;
      }
      .location-detail {
        .program-input {
          margin-top: 16px / @pv;
        }
        .location-divider {
          margin-top: 32px / @pv;
        }
        .accredited-sec {
          margin-top: 16px / @pv;
        }
        .location-title {
          margin-top: 32px / @pv;
          font-size: 16px / @pv;
          font-weight: 800;
          font-family: "Lato";
          img {
            width: 24px / @pv;
            margin-right: 8px / @pv;
          }
        }
        .chais-sec {
          flex-direction: column;
          .chairs-input {
            margin-right: 0px;
          }
          .footage-wrap {
            margin-top: 16px / @pv;
          }
        }

        .btn-add {
          height: 42px / @pv;
          width: 183px / @pv;
          margin-top: 16px / @pv;
        }
        .employee-sec {
          margin-top: 16px / @pv;
          .employee-header {
            font-family: "Lato";
            font-weight: 800;
            font-size: 16px / @pv;
            .icon-employee {
              width: 24px / @pv;
              margin-right: 8px / @pv;
            }
          }
        }
        .empty-employee-sec {
          margin-top: 24px / @pv;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          background-color: transparent;
          padding: 0;
          height: auto;
          .left-sec {
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon-employee {
              width: 24px / @pv;
              margin-right: 8px / @pv;
            }
            .left-center {
              display: flex;
              align-items: center;
              flex-direction: row;
              .employee {
                font-size: 16px / @pv;
                margin-right: 8px / @pv;
                font-family: "Lato";
                font-weight: 800;
              }
              .later {
                font-size: 14px / @pv;
              }
            }
          }
          .btn-add-employee {
            height: 42px / @pv;
            margin-top: 17px / @pv;
          }
        }
      }
    }
  }
}
.menu-area {
  padding: 8px 0;
  background-color: white;
  border: 1px solid @rgba-grey-light;
  box-sizing: border-box;
  border-radius: 4px;
  .square-item {
    width: 125px;
    height: 48px;
    font-family: "Lato";
    font-size: 16px;
    line-height: 48px;
    padding-left: 12px;
    cursor: pointer;
    &:hover {
      background-color: @extra-light;
    }
  }
}
