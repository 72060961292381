@body-bg: #f3e1d7;

// theme override for antd
@primary-color: #2d2e3d;
@primary-dark: #2d2e3d;
@dark-grey: #61626d;
@grey: #96979e;
@light-grey: #cacbce;
@light: #eaeaec;
@extra-light: #f4f5f5;
@grey-bg: #f2f3f3;
@rgba-grey: rgba(45, 46, 61, 0.5);
@rgba-grey-light: rgba(45, 46, 61, 0.1);
@neutral-dark: black;
@neutral-light: white;
// mixins
@layout-body-background: @extra-light;
// item/ menu-item/ dropdown-item bg color
@item-hover-bg: #f4f5f5;
@select-item-active-bg: #f4f5f5;

@header-height: 88px;
@mibile-header-height: 80px;
@create-header-height: 80px;
@create-content-height: calc(100vh - @create-header-height);

/**
 * Mobile px to vw
 */
@pv: (375 * 1px) / 100vw;

/**
 * Media Query
 */
@desktop: ~"screen and (min-width: 1024px)";
@mobile: ~"screen and (max-width: 1023px)";

.ant-layout {
  background-color: @grey-bg;
}

// mixins
.poa-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flex-center-container() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-between-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.shadow-box() {
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.07), 0px 3px 7px rgba(0, 0, 0, 0.07),
    0px 0px 4px rgba(0, 0, 0, 0.07);
  &:hover {
    box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.1),
      0px 7px 15px rgba(0, 0, 0, 0.05), 0px 0px 5px rgba(0, 0, 0, 0.04);
  }
}

.layout {
  @media screen and (min-width: 1440px) {
    width: 1230px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    margin: 0 20px / @pv;
  }
  @media @mobile {
    margin: 0 30px / @pv;
  }
}
.padding-layout {
  .layout();
  @media @desktop {
    padding: 120px 0;
  }
  @media @mobile {
    padding: 48 / @pv 0;
  }
}

.max-content {
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
}

.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@gutter-md: 16px;
@gutter-lg: 32px;
@gutter-xxl: 64px;

@pseudo-dark: #9E8E9F;
