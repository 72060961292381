.relogin-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */

    .modal-dialog {
        width: 400px;
        margin: 0; /* Adjusted for fixed position */
        background-color: #fff; /* Main background color */
        border-radius: 8px; /* Rounded corners */
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); /* Box shadow for depth */
        padding: 20px;
        border: 3px solid; /* Subtle top border */
        border-image: linear-gradient(to right, #d56e9e, #912d98, #3dbee5) 1;
    }

    .modal-message {
        color: rgb(216, 77, 77);
    }

    .modal-body {
        padding: 20px;
    }

    .modal-footer {
        padding: 10px 20px;
        display: flex;
        justify-content: flex-end; /* Align buttons to the right */
    }

    input[type="text"],
    input[type="password"] {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }

    .btn {
        background-color: #f2f2f2; /* Light grey for buttons */
        color: #333; /* Dark text for contrast */
        border: none;
        border-radius: 5px;
        padding: 10px 15px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .btn:hover {
        background-color: #e6e6e6; /* Slightly darker grey on hover */
    }

    .modal-footer .btn {
        margin-left: 10px; /* Space between buttons */
    }
}
