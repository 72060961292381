@import "../../../theme.less";

.cm-home-faq {
  background: rgba(237, 183, 208, 0.4);
  padding: 90px;
  @media @mobile {
    padding: 24px;
  }

  &__btn-selector {
    background-color: #9E8E9F !important;
    color: white !important;
    text-transform: uppercase;
    font-family: "Lato-bold";
    font-size: 15px;
    margin: 0 8px;
    height: 50px !important;

    @media @mobile {
      width: 70vw;
      margin-bottom: 16px;
    }

    &--selected {
      background: white !important;
      color: #9E8E9F !important;
    }
  }
}
