@import "../../../theme.less";
.add-canvases-root {
  display: flex;
  flex-direction: column;
  width: 890px;
  margin: 24px auto;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .bread-crumb-item {
    font-family: "Lato";
    font-weight: 800;
    font-size: 14px;
    line-height: 120%;
    color: rgba(45, 46, 61, 0.5);
    text-decoration: underline;
  }
  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    margin-top: 18px;
    color: @primary-dark;
  }
  .content {
    margin: 48px 0;
    background-color: @light;
    padding: 48px;
    border-radius: 8px;
    @media @mobile {
      width: 100vw;
      margin: 32px 0;
      border-radius: 0;
      padding: 32px / @pv 30px / @pv;
      margin-left: -30px;
    }
    .subtitle {
      font-family: "LibreBaskerville";
      font-size: 20px;
      line-height: 140%;
      margin-top: 48px;
      color: @dark-grey;
    }
    .divider {
      height: 1px;
      background-color: @extra-light;
      width: calc(100% - 48px);
      margin: 0 auto;
    }
    .options {
      width: 100%;
      border: 1px solid #cacbce;
      background-color: white;
      margin: 24px 0;
      border-radius: 4px;
      .canvas-option {
        display: flex;
        align-items: center;
        height: auto;
        padding: 16px 30px 16px 14px;
        .info-sec {
          max-width: 100%;
          margin-left: 14px;
          margin-right: 16px;
          display: flex;
          flex-direction: column;
          .name {
            font-family: "Lato";
            font-weight: 800;
            font-size: 16px;
            line-height: 160%;
            color: @primary-dark;
          }
          .desc {
            font-family: "Lato";
            font-size: 14px;
            line-height: 160%;
            color: @grey;
          }
          .premium-mobile {
            display: none;
            @media @mobile {
              display: inline;
            }
          }
        }
        .space {
          flex: 1;
        }
        .premium {
          font-family: "Lato-bold";
          font-size: 14px;
          display: flex;
          color: @primary-dark;
          line-height: 160%;
          img {
            height: 18px;
            width: 18px;
            margin-right: 8px;
          }
          @media @mobile {
            display: none;
          }
        }
      }
    }
  }
  .btn-create {
    height: 51px;
    &[disabled],
    &[disabled]:hover {
      background: @grey;
      color: white;
    }
  }
}
