@import "../theme.less";
.address-text-field {
  position: relative;
  .text-field {
    width: 100%;
  }
  .dropdown-address {
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.1),
      0px 7px 15px rgba(0, 0, 0, 0.05), 0px 0px 5px rgba(0, 0, 0, 0.04);
    span {
      width: 100%;
      line-height: 48px;
      font-family: "Lato";
      font-size: 16px;
      padding: 0 16px;
      border-bottom: 1px solid @extra-light;
      cursor: pointer;
    }
  }
}
