@import "../theme.less";
.cr-switch {
  span {
    height: 33px;
    padding: 0 12px;
    display: inline-block;
    background-color: @light;
    border-radius: 4px;
    line-height: 33px;
    cursor: pointer;
    font-family: "Lato";
    font-weight: 800;
    color: @grey;
    &.active {
      background-color: @grey;
      color: white;
    }
  }
}
