@import "../theme.less";

.home-root .posted-opp-list-container {
  .posted-opp-card {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    .pc-view {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    .mobile-view {
      display: none;
    }
    &:not(:first-child) {
      margin-top: 24px;
    }
    .left-sec {
      display: flex;
    }
    .avatar-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 150px;
      width: 150px;
      height: 150px;
      border-radius: 4px;
      overflow: hidden;
      background-color: @light;
      .time-type {
        position: absolute;
        left: 0;
        top: 0;
        width: 76px;
        height: 27px;
        font-size: 12px;
        text-align: center;
        line-height: 27px;
        font-weight: bold;
        align-items: center;
        color: #2d2e3d;
        background: #f4f5f5;
      }
    }
    .card-info-wrap {
      margin-left: 24px;
      color: @grey;
      .card-title {
        font-family: "LibreBaskerville-bold";
        font-weight: bold;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.5px;
        color: #2d2e3d;
      }
      .card-location,
      .card-date {
        display: flex;
        align-items: center;
      }
      .card-location {
        margin-top: 8px;
        .location {
          margin-left: 10px;
        }
      }
      .card-date {
        margin-top: 10px;
        img {
          margin-right: 9px;
        }
        .bold {
          font-weight: bold;
        }
      }
      .card-status {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .btn-secondary {
          font-weight: 800;
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
        .view-count {
          color: #96979e;
        }
        .status-btn {
          padding: 0 16px 0 9px;
          .dropdown-icon {
            margin-left: 10px;
            color: @grey;
          }
          .btn-text {
            margin-left: 5px;
          }
        }
      }
    }
    .card-actions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .card-action-wrap {
      display: flex;
      align-items: center;
      color: #2d2e3d;
      .action-btn {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: @extra-light;
        }
      }
    }
  }
  @media @mobile {
    .posted-opp-card {
      padding: 16px 12px;
      .pc-view {
        display: none;
      }
      .mobile-view {
        display: block;
        width: 100%;
        .top-sec {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          .avatar-wrap {
            min-width: auto;
            width: 100px;
            height: 100px;
            .time-type {
              left: auto;
              right: 0;
              width: 70px;
              height: 24px;
              line-height: 24px;

              border-radius: 2px 0 2px 2px;
            }
          }
        }
        .card-info-wrap {
          margin-left: 0;
          margin-top: 16px;
          .card-title {
            font-size: 16px;
          }
          .card-date {
            flex-wrap: wrap;
            margin-top: 8px;
            white-space: nowrap;
          }
          .card-status {
            flex-wrap: wrap;
            margin-top: 0;
            .btn-secondary {
              margin-top: 16px;
              &:not(:last-child) {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.status-dropdopwn {
  .option-text {
    margin-left: 13px;
  }
}

.tag {
  padding: 5px 8px;
  background-color: #83b23a;
  color: white;
  text-transform: uppercase;
  font-family: "Lato-bold";
  font-size: 12px;
  margin-left: 16px;
}