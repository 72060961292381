@import "../theme.less";
.horizontal-scroll-root {
  position: relative;
  display: flex;
  justify-content: space-between;
  .arrow {
    width: 34px;
    height: 34px;
    padding: 8px;
    background-color: #f4f5f5;
    cursor: pointer;
  }
  .button {
    height: 100%;
    display: flex;
    align-items: center;
    width: 58px;
    z-index: 2;
    position: absolute;
    top: 0;
  }
  .left {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(244, 245, 245, 1) 0%,
      rgba(244, 245, 245, 1) 50%,
      rgba(244, 245, 245, 0) 100%
    );
  }
  .right {
    right: 0;
    flex-direction: row-reverse;
    background: linear-gradient(
      270deg,
      rgba(244, 245, 245, 1) 0%,
      rgba(244, 245, 245, 1) 50%,
      rgba(244, 245, 245, 0) 100%
    );
  }
  .scroll-sec {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: block;
    z-index: 1;
    &::-webkit-scrollbar {
      height: 0 !important;
    }
    .list-wrap {
      flex-wrap: nowrap;
      width: auto;
      display: inline-flex;
      &.showArrow {
        padding: 0 68px;
      }
    }
  }
}
