@import "../theme.less";
.list-table-wrap.MuiTableContainer-root {
  position: relative;
  margin: 24px 0;
  tr {
    display: flex;
    width: 100%;
    // text-align-last: left;
  }
  thead {
    display: block;
    width: 100%;
    th {
      flex: 1;
      height: 88px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: @rgba-grey;
      font-weight: bold;
      white-space: pre-wrap;
      &.MuiTableCell-paddingCheckbox {
        flex: 0 0 40px;
      }
    }

    .sort-head {
      img {
        margin-left: 4px;
        cursor: pointer;
        transition: all 0.3s;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }
  tbody {
    background: #fff;
    overflow-y: scroll;
    width: 100%;
    margin-bottom: 30px;
    &.row-4{
      max-height: 71px * 4;
      display: block;
    }
    &.row-5{
      display: block;
      max-height: 71px * 5;
    }
    &.row-6{
      display: block;
      max-height: 71px * 6;
    }
    &.row-7{
      display: block;
      max-height: 71px * 7;
    }
    &.row-8{
      display: block;
      max-height: 71px * 8;
    }
    &.row-9{
      display: block;
      max-height: 71px * 9;
    }
    &.row-10{
      display: block;
      max-height: 71px * 10;
    }
    
    td {
      flex: 1;
      // height: 72px;
      display: flex;
      align-items: center;
      overflow: visible;
      overflow-wrap: anywhere;
      @media @mobile {
        display: table-cell;
        height: unset;
      }
      &.capitalize {
        text-transform: capitalize;
      }
      &.MuiTableCell-paddingCheckbox {
        flex: 0 0 40px;
      }
    }
    .mobile-header {
      font-family: "Lato-bold";
      font-size: 14px / @pv;
      line-height: 160%;
      color: @primary-dark;
      display: flex;
      align-items: center;
      .ic-more {
        background: #f4f5f5;
        height: 34px / @pv;
        width: 34px / @pv;
        padding: 8px / @pv;
        border-radius: 4px;
      }
      .avatar-name {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      }
    }
    .mobile-info-list {
      .mobile-info-item {
        margin-bottom: 6px / @pv;
        display: flex;
        align-items: center;
        margin-left: 42px / @pv;
        .mobile-info-item-key {
          font-family: "Lato-bold";
          font-size: 12px / @pv;
          line-height: 140%;
          color: rgba(45, 46, 61, 0.5);
          width: 64px / @pv;
          display: inline-block;
        }
        .mobile-info-item-value {
          font-family: "Lato";
          font-size: 14px / @pv;
          line-height: 160%;
          color: @primary-dark;
          max-width: 100px / @pv;
          margin-left: 16px / @pv;
          overflow: visible;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .table-cell-action {
      display: flex;
      align-items: center;
    }
    .avatar-name {
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
        .ant-avatar.ant-avatar-icon {
          min-width: 32px;
          @media @mobile {
            min-width: 32px / @pv;
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
      img {
        margin-right: 8px;
        width: 32px;
        @media @mobile {
          width: 32px / @pv;
        }
      }
    }
    .pending-action {
      font-family: "Lato-bold";
      font-size: 12px;
      line-height: 140%;
      color: rgba(45, 46, 61, 0.5);
      @media @mobile {
        margin-left: 42px / @pv;
      }
      span {
        cursor: pointer;
      }
      .resend {
        margin-left: 16px;
      }
    }
    .completion {
      font-family: "Lato-bold";
      color: #5a8e09;
    }
    .status {
      .status-value {
        padding: 5px 8px;
        border-radius: 4px;
        background-color: #eaeaec;
        color: #96979e;
        font-family: "Lato-bold";
        font-size: 12px;
        &.isHired {
          background-color: #83b23a;
          color: white;
        }
        &.isAva {
          background-color: #f1dd23;
          color: rgba(45, 46, 61, 0.5);
        }
      }
    }
  }
  .MuiTableCell-root {
    padding: 16px 8px;
  }
  .MuiTableCell-paddingCheckbox {
    .MuiIconButton-colorSecondary:hover {
      background-color: @rgba-grey-light;
    }
    .MuiSvgIcon-root {
      color: @light-grey;
    }
    .MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root {
      color: @dark-grey;
    }
  }
  .total-cnt {
    text-align: right;
    margin: 0 10px 20px;
  }
  .loading-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    .flex-center-container;
  }
  .noHeaderSelect {
    tbody {
      .mobile-header {
        position: relative;
        z-index: 10;
        .MuiIconButton-root {
          padding-right: 0;
          padding-top: 5px;
        }
      }
      .mobile-info-list {
        position: relative;
        top: -54px;
        bottom: -54px;
        z-index: 1;
        margin-bottom: -54px;
        .mobile-info-item {
          margin-left: 0;
          .mobile-info-item-key {
            width: 122px / @pv;
          }
        }
      }
    }
    &.payment {
      tbody {
        .mobile-header {
          position: relative;
          z-index: 10;
          .MuiIconButton-root {
            padding-right: 0;
            padding-top: 5px;
          }
          .payment-action {
            position: absolute;
            right: 0px;
            top: 0px;
          }
        }
        .mobile-info-list {
          position: relative;
          top: 0px;
          bottom: 0px;
          z-index: 1;
          margin-bottom: -8px;
          .mobile-info-item {
            margin-left: 0;
            .mobile-info-item-key {
              width: 122px / @pv;
            }
          }
        }
      }
    }
  }
}
