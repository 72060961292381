@import "../theme.less";
.edit-category-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  .close {
    position: absolute;
    cursor: pointer;
    height: 24px;
    right: 24px;
    top: 20px;
  }
  .title {
    font-family: "Lato-bold";
    font-size: 20px;
    margin-bottom: 8px;
    color: @primary-dark;
    height: 64px;
    line-height: 64px;
    background-color: white;
    padding-left: 24px;
  }
  .input {
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .btn-add {
    height: 41px;
    width: 60px;
    margin: 24px;
    font-size: 14px;
  }
}
.edit-category-modal-wrap {
  .ant-modal {
    width: 400px !important;
    .ant-modal-body {
      background-color: #f4f5f5;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;
    }
  }
}
