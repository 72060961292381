@import "../theme.less";

.my-opp-list-root {
  .opportunity-item {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    &:not(:first-child) {
      margin-top: 16px;
    }
    @media @mobile {
      flex-direction: column;
      padding: 16px / @pv 15px / @pv 24px / @pv;
      border-radius: 0;
    }

    .apply-status {
      padding: 5px 8px;
      border-radius: 4px;
      font-family: Lato;
      font-weight: bold;
      font-size: 12px;
    }
    .left-sec {
      display: flex;
      .avatar-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 150px;
        width: 150px;
        height: 150px;
        border-radius: 4px;
        overflow: hidden;
        background-color: @light;
      }

      .card-info-wrap {
        margin-left: 24px;
        color: @grey;
        .card-title {
          font-family: "LibreBaskerville-bold";
          font-weight: bold;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: -0.5px;
          color: #2d2e3d;
        }
        .card-location,
        .card-canvas-name,
        .card-date {
          display: flex;
          align-items: center;
        }
        .card-location,
        .card-canvas-name {
          margin-top: 8px;
          .location {
            margin-left: 10px;
          }
        }
        .card-location {
          margin-top: 16px;
        }
        .card-date {
          margin-top: 10px;
          img {
            margin-right: 9px;
          }
          .bold {
            font-weight: bold;
          }
        }
        .apply-status {
          display: none;
        }
      }

      @media @mobile {
        .avatar-wrap {
          min-width: 80px / @pv;
          width: 80px / @pv;
          height: 80px / @pv;
        }
        .card-info-wrap {
          margin-left: 16px / @pv;
          .card-title {
            font-size: 16px / @pv;
          }

          .card-location,
          .card-canvas-name {
            margin-top: 8px / @pv;
            .location {
              margin-left: 8px / @pv;
            }
          }
          .card-location {
            margin-top: 16px / @pv;
          }

          .card-location,
          .card-canvas-name,
          .card-date {
            align-items: flex-start;
            .card-icon {
              margin-top: 2px;
            }
          }
          .card-date {
            flex-wrap: wrap;
          }
          .apply-status {
            display: block;
            margin-top: 16px / @pv;
            width: fit-content;
            padding: 5px / @pv 8px / @pv;
            font-size: 12px / @pv;
          }
        }
      }
    }
    .right-sec {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      min-height: 100%;
      .bottom-sec {
        display: flex;
        .bottom-btn {
          padding: 0 16px;
          height: 41px;
          &:not(:first-child) {
            margin-left: 16px;
          }
        }
      }
      @media @mobile {
        align-items: flex-start;
        .apply-status {
          display: none;
        }
        .bottom-sec {
          margin-top: 24px / @pv;
          .bottom-btn {
            padding: 0 10px / @pv;
            height: 41px / @pv;
            font-size: 14px / @pv;
          }
        }
      }
    }
  }
}

.my-opportunity-message-modal {
  max-width: 800px;
  .send-msg-modal {
    padding: 24px;
    .common-title {
      margin-bottom: 16px;
    }
    .modal-text {
      font-size: 16px;
      margin-bottom: 45px;
    }
    .material-input {
      width: 100%;
      margin-bottom: 24px;
    }
    .btn-wrap {
      .ant-btn {
        height: 41px;
      }
      .ant-btn-primary {
        margin-right: 16px;
      }
    }
  }
  .comfirm-modal {
    text-align: center;
    .common-title {
      font-size: 24px;
      margin-bottom: 8px;
      font-family: "LibreBaskerville-bold";
    }
    .modal-text {
      font-size: 16px;
    }
    img {
      width: 88px;
      margin-top: 28px;
    }
    .btn-wrap {
      .flex-center-container;
      margin-top: 24px;
      .ant-btn {
        height: 41px;
      }
      .ant-btn-primary {
        margin-right: 16px;
      }
    }
  }

  @media @mobile {
    .send-msg-modal {
      padding: 0;
      .modal-text {
        margin-bottom: 24px / @pv;
      }
    }
  }
}
