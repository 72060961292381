@import "../../../theme.less";

.admin-user-container {
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  width: 890px;

  i {
    font-style: normal;
  }

  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    color: @primary-dark;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @media @mobile {
      flex-flow: column nowrap;
      align-items: flex-start;
      .title-btn {
        margin-top: 16px;
      }
    }
    i,
    button {
      height: 40px;
      line-height: 40px;
      padding: 0px 16px;
      border: 1px solid rgba(45, 46, 61, 0.1);
      box-sizing: border-box;
      border-radius: 4px;
      align-items: center;
      letter-spacing: 0.04em;
      color: #2d2e3d;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      font-family: Lato;
      text-transform: inherit;
      cursor: pointer;
      &:first-child {
        margin-right: 12px;
      }
      &.filter-btn {
        width: 132px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
  }

  .list-wrapper {
    margin-top: 28px;
    @media @mobile {
      margin-top: 40px / @pv;
    }
    .search-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      .MuiTextField-root {
        width: 280px;
        margin-right: 24px;
      }
      .ant-input-affix-wrapper {
        width: 400px;
        margin-right: 24px;
        height: 56px;
      }
      .btn-secondary {
        height: 48px;
      }
    }
    .avatar-name-inner {
      align-items: center;
    }
  }

  .MuiTablePagination-root {
    margin-top: 16px;
  }
}

.chat-pop {
  .ant-dropdown-menu-item {
    span.msg-menu-item {
      img {
        margin-right: 12px;
      }
    }
  }
}
