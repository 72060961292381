.cm-membership-user {
    padding-bottom: 30px;

    .downgrade-title {
        margin-top: 48px;
        font-size: 1.5em;
        font-family: "LibreBaskerville";
        color: #2d2e3d;
        line-height: 1.2;
        letter-spacing: -1px;
    }
    .downgrade-desc {
        margin-top:20px;
        text-align: center;
        font-size: 1.5em;
        font-family: "LibreBaskerville";
        color: #2d2e3d;
        line-height: 1.2;
        letter-spacing: -1px;
    }
    .resume-membership-modal {
        h2 {
            font-size: 1.2em;
            margin-bottom: 10px;
        }
        p {
            font-size: 1em;
            margin-bottom: 10px;
        }
    }
    .coupon {
        width: 50px;
    }
}
