@import "../../../theme.less";

.my-opportunities-root {
  width: 890px;
  margin: 24px auto;
  .opportunity-list-wrap {
    margin-top: 48px;
  }
  .loading {
    margin-top: 50px;
  }
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
    .opportunity-list-wrap {
      margin-top: 32px / @pv;
    }
  }
}
