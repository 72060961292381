@import "../../theme.less";

.post-job-wrap {
  margin: 0 auto;
  @media @desktop {
    width: 920px;
    min-height: @create-content-height;
    padding: 64px 0 50px;
    margin-top: @create-header-height;
    .common-title {
      margin-bottom: 48px;
      font-family: "LibreBaskerville-bold";
    }
    .btn-wrap {
      margin-top: 32px;
      .flex-between-container();
      .submit {
        display: flex;
        button {
          height: 50px;
          padding: 0 24px;
          margin-right: 8px;
        }
      }
      .ant-btn-link {
        font-size: 16px;
        font-weight: 800;
        color: @primary-color;
      }
    }
  }
  @media @mobile {
    margin: 136px 30px 32px;
    .common-title {
      margin-bottom: 32px / @pv;
      font-family: "LibreBaskerville-bold";
    }
    .btn-wrap {
      margin-top: 32px / @pv;
      .submit {
        display: flex;
        margin-bottom: 40px / @pv;
        button {
          height: 50px / @pv;
          padding: 0 24px / @pv;
          margin-right: 8px / @pv;
        }
      }
      .ant-btn-link {
        font-size: 16px / @pv;
        font-weight: 800;
        color: @primary-color;
        padding: 0 24px / @pv;
      }
    }
  }
}
