.cm-canvas-link-root {
  width: 100%;
  margin-bottom: 128px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin-right: 24px;
  }

  .cm-canvas-link-wrapper {
    flex: 1;
    margin-right: 12px;
    .MuiFormControl-root {
      width: 100%;
    }
  }
}
