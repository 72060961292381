@import "../theme.less";
.edit-employment-modal-wrap {
  .ant-modal {
    border-radius: 4px;
    overflow: hidden;
    width: 400px !important;
    .ant-modal-body {
      padding: 0;
      background-color: @grey-bg;
      border-radius: 4px;
    }
  }
  .edit-employment-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    .title-sec {
      display: flex;
      justify-content: space-between;
      height: 64px;
      background-color: white;
      align-items: center;
      padding: 0 24px;
      margin-bottom: 8px;
      .title {
        font-family: "Lato-bold";
        font-size: 20px;
        line-height: 160%;
        color: @primary-dark;
      }
      .close {
        cursor: pointer;
        height: 24px;
      }
    }
    .date-sec {
      margin: 16px 24px 0 24px;
      position: relative;
      img {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        pointer-events: none;
      }
      .date-input {
        color: @dark-grey;
        width: 100%;
      }
    }

    .auto-input {
      margin: 16px 24px 0 24px;
      color: @dark-grey;
      width: calc(100% - 48px);
    }
    .check-sec {
      margin: 24px 24px 8px;
      .check-box {
        margin-right: 8px;
        height: 18px;
        width: 18px;
      }
      span {
        font-family: "Lato";
        font-size: 14px;
        line-height: 120%;
        color: @primary-dark;
      }
    }
    .input {
      margin: 16px 24px 0 24px;
      color: @dark-grey;
    }
    .btn-add {
      height: 41px;
      width: 200px;
      font-size: 14px;
      margin: 20px auto;
    }
  }
}
