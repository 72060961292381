@import "../../../theme.less";
.bookmarks-root {
  display: flex;
  flex-direction: column;
  width: 890px;
  margin: 24px auto;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .title {
    font-family: "LibreBaskerville-Bold";
    font-size: 32px;
    line-height: 120%;
    margin-top: 18px;
    color: @primary-dark;
  }
  .empty-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "LibreBaskerville";
    font-size: 20px;
    line-height: 140%;
    margin-top: 210px;
    color: @primary-dark;
    .detail {
      font-family: "Lato";
      font-size: 12px;
      color: @grey;
      line-height: 140%;
      margin-top: 6px;
    }
    img {
      width: 96px;
      height: 96px;
    }
  }
  .subtitle {
    font-family: "LibreBaskerville";
    font-size: 20px;
    line-height: 140%;
    margin-top: 48px;
    color: @dark-grey;
  }
  .canvas-list {
    margin-top: 24px;
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: rgba(45, 46, 61, 0.1);
  }
  .btn-more {
    height: 41px;
    margin-bottom: 48px;
    margin-top: 24px;
  }
}
