@import "../theme.less";
.category-item {
  display: flex;
  min-height: 43px;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  .icon-award {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
  .issue {
    font-family: "Lato-bold";
    font-size: 14px;
    line-height: 160%;
    color: @primary-dark;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-edit {
    width: 15px;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 20px;
  }
  .icon-delete {
    width: 15px;
    cursor: pointer;
  }
}
