@import "../../../theme.less";
.opportunity-publish-success-root {
  .home-header {
    z-index: 2;
  }
  .publish-success-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media @desktop {
      min-height: @create-content-height;
      padding: 100px 0;
      margin-top: @header-height;
      .success-icon {
        width: 88px;
        height: 88px;
        margin-bottom: 36px;
      }
      .common-title {
        width: 448px;
        margin-bottom: 8px;
        text-align: center;
      }
      .intro {
        font-size: 16px;
        color: @dark-grey;
        line-height: 1.6;
        margin-bottom: 32px;
        text-align: center;
        width: 496px;
      }
      .copy-link {
        width: 448px;
        height: 66px;
        background-color: #fff;
        border-radius: 4px;
        .flex-between-container();
        padding: 0 24px;
        color: @primary-dark;
        margin-bottom: 24px;
        & > span {
          font-size: 16px;
          flex: 1;
          overflow: hidden;
          margin-right: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        div {
          cursor: pointer;
          img {
            width: 18px;
            margin-right: 4px;
          }
          span {
            font-size: 14px;
            font-weight: 800;
            line-height: 18px;
          }
        }
      }
      .divider {
        width: 448px;
        span {
          color: @grey;
          font-size: 16px;
        }
      }
      .share {
        margin-top: 24px;
        margin-bottom: 44px;
        .flex-between-container();
        width: 448px;
        span {
          font-size: 16px;
          color: @primary-dark;
          font-family: "Lato-bold";
        }
        .social-icons {
          .flex-between-container();
          width: 128px;
          img {
            width: 24px;
            &:not(:first-child) {
              margin-left: 16px;
            }
          }
        }
      }
      .btn-wrap {
        .flex-center-container();
        .ant-btn {
          height: 50px;
          padding: 0 24px;
          &.btn-primary {
            margin-right: 16px;
          }
        }
      }
    }
    @media @mobile {
      margin: 132px / @pv 30px / @pv;
      .success-icon {
        width: 88px / @pv;
        height: 88px / @pv;
        margin-bottom: 36px / @pv;
      }
      .common-title {
        margin-bottom: 32px / @pv;
        text-align: center;
      }
      .intro {
        font-size: 16px / @pv;
        color: @dark-grey;
        margin-bottom: 32px / @pv;
        text-align: center;
      }
      .copy-link {
        color: @primary-dark;
        width: 100%;
        & > span {
          display: block;
          padding: 12px / @pv 16px / @pv;
          background-color: #fff;
          border-radius: 4px;
          font-size: 16px / @pv;
          margin-bottom: 24px / @pv;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
        }
        div {
          text-align: center;
          margin-bottom: 24px / @pv;
          img {
            width: 18px / @pv;
            margin-right: 4px / @pv;
          }
          span {
            font-size: 14px / @pv;
            font-weight: 800;
            line-height: 18px / @pv;
          }
        }
      }
      .divider {
        width: 100%;
        span {
          color: @grey;
          font-size: 16px / @pv;
        }
      }
      .share {
        margin-top: 30px / @pv;
        margin-bottom: 44px / @pv;
        span {
          display: block;
          text-align: center;
          font-size: 16px / @pv;
          color: @primary-dark;
          font-family: "Lato-bold";
          margin-bottom: 28px / @pv;
        }
        .social-icons {
          .flex-between-container();
          img {
            margin: 0 14px / @pv;
            width: 24px / @pv;
          }
        }
      }
      .btn-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-btn {
          height: 50px / @pv;
          padding: 0 24px / @pv;
          margin-bottom: 16px / @pv;
        }
      }
    }
  }
}
