@import "../../theme.less";
.home-root {
  font-family: Lato;
  .home-header {
    // background: @body-bg;
    z-index: 22;
    &.isHome {
      // background: transparent;
      // border-bottom: transparent;
    }
  }
  .home-content {
    min-height: calc(100vh - 184px);
    margin-top: 88px;
    // overflow-x: hidden;
  }

  @media (max-width: 475px) {
    .home-content {
      min-height: 100vh;
      width: 100%;
      margin-top: @mibile-header-height;
    }
  }

  .homepage-title-wrap {
    .guide {
      text-transform: uppercase;
      opacity: 0.5;
    }
    .title {
      font-family: "LibreBaskerville";
      color: @primary-dark;
      line-height: 1.2;
      letter-spacing: -1px;
    }
    @media @desktop {
      .guide {
        font-size: 16px;
        margin-bottom: 14px;
      }
      .title {
        font-size: 40px;
        margin-bottom: 24px;
      }
      .intro {
        font-size: 16px;
        margin-bottom: 32px;
      }
    }
    @media @mobile {
      .guide {
        font-size: 12px / @pv;
        margin-bottom: 8px / @pv;
        font-weight: bold;
      }
      .title {
        font-size: 32px / @pv;
        margin-bottom: 16px / @pv;
      }
      .intro {
        font-size: 16px / @pv;
        margin-bottom: 24px / @pv;
      }
    }
  }

  .start-and-tip {
    display: flex;
    align-items: center;
    .tip {
      display: inline-block;
    }
    @media @desktop {
      .tip {
        font-size: 12px;
        width: 115px;
        margin-left: 24px;
      }
    }
    @media @mobile {
      justify-content: space-between;
      .tip {
        font-size: 12px / @pv;
        width: 115px / @pv;
      }
    }
  }
}

.flex-between-panel {
  @media @desktop {
    .flex-between-container();
    & > .homepage-title-wrap {
      width: 504px;
      max-width: 45%;
    }
  }
}

.saloncentric-home-root {
  .home-content {
    padding-top: 88px;
    background: white;
  }
}
