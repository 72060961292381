.wella-logo {
    position: fixed;
    display: flex;
    align-items: center;
    top: 5px;
    left: 200px;
    color: white;
    z-index: 99;
    font-family: "Lato-bold";
    width: 80px;
    @media screen and (max-width: 1028px) {
        top: 7px;
        left: 145px;
    }
    .wella-x {
        font-size: 1.2em;
        font-family: "sans-serif";
        padding: 0 10px 0 0;
        position: relative;
        top: 15px;
    }
}
.cm-wella-landing {
    .cm-sp-hero-content {
        @media screen and (max-width: 1028px) {
            padding: 10px;
        }
    }
}

.cm-hero-cta-form-wella {
    @media screen and (max-width: 1028px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .cm-txt-field-wella-form {
            padding: 0;
            margin-bottom: 0 !important;
        }
    }
}

.txt-container {
    color: white;
    text-align: left;
    margin-left: 15%;
    @media screen and (max-width: 1028px) {
        margin: 10%;
    }
    .details {
        width: 80%;
        @media screen and (max-width: 1028px) {
            width: 100%;
        }
    }
    .headliner {
        font-size: 2.5em;
        margin-bottom: 20px;
        width: 100% !important;
        @media screen and (max-width: 1028px) {
            font-size: 1.7em;
        }
    }
}
.cm-sp-hero-img-wrapper-wella {
    width: 400px;
    margin-right: 40px;
    @media screen and (max-width: 1028px) {
        width: 100%;
        margin-right: 0px;
    }
}
.cta-header {
    color: black;
}

.video-position {
    @media screen and (max-width: 1028px) {
        padding: 20px;
    }
}
