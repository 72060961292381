@import "../../../theme.less";

.membership-view {
  width: 890px;
  margin: 24px auto;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  h1 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 48px;

    &.no-margin {
      margin: 0px;
    }
  }

  h2 {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    font-weight: normal;
  }

  section {
    margin-bottom: 48px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  nav.MuiTypography-root {
    margin-bottom: 20px;

    a,
    span {
      color: @grey;
      font-weight: bold;
      font-size: 14px;
    }

    a {
      text-decoration: underline;
    }
  }

  .list-table-wrap {
    margin-bottom: 24px;
  }

  .information-table {
    margin-top: 24px;
    border: solid @light 1px;

    tr {
      td {
        font-weight: bold;

        &:nth-child(1),
        &:nth-child(3) {
          white-space: nowrap;
          text-transform: uppercase;
          font-size: 10px;
          color: @grey;
        }

        &:nth-child(2),
        &:nth-child(4) {
          width: 40%;
          font-size: 16px;
        }
      }
    }
  }

  .no-content-message {
    padding: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    background: white;
    text-align: center;
  }

  .billing-update {
    form {
      .ant-form-item label {
        position: absolute;
      }

      .ant-form-explain {
        margin-bottom: 16px;
      }

      .MuiFormControl-root {
        width: 100%;

        .MuiAutocomplete-input {
          padding: 0px;
        }
      }

      .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
        padding-top: 25px;
      }

      .button-panel {
        margin-top: 32px;
      }
    }
  }

  .invoice-view {
    .information-table {
      tr {
        td {
          border: none;
          height: auto;

          &:nth-child(2),
          &:nth-child(4) {
            width: 60%;
            font-size: 14px;
          }
        }
      }
    }

    .product-summary-table {
      tr {
        td {
          &:nth-child(2),
          &:nth-child(4) {
            width: 40%;
            text-align: right;
          }
        }

        &:last-of-type {
          border-top: solid @light 1px;
        }
      }
    }
  }

  .plan-update-view {
    section {
      &:first-of-type {
        h2 {
          margin-bottom: 24px;
        }
      }
    }

    .plan-selection-panel {
      .selection-container {
        display: block;
        height: 100%;
        background: white;
        border: solid @light 1px;
        border-radius: 4px;
        margin-right: 5px;
        cursor: pointer;

        .MuiFormControlLabel-label {
          font-family: "Lato-bold";
          text-transform: uppercase;
          font-size: 12px;
        }

        .MuiRadio-colorSecondary {
          color: @grey;
        }

        &.premium {
          background: black;
          color: white;
          margin-left: 5px;

          .MuiRadio-colorSecondary {
            color: white;
          }

          .plan-top-section {
            border-bottom: solid rgba(255, 255, 255, 0.2) 1px;
          }
        }

        .plan-top-section {
          padding: 28px;
          border-bottom: solid @light 1px;

          > div:first-of-type {
            margin-bottom: 34px;
          }

          .plan-price {
            span {
              display: inline-block;

              &:first-of-type {
                font-size: 40px;
                margin-right: 8px;
                font-family: "LibreBaskerville-Bold";
              }

              &:last-of-type {
                color: @grey;
              }
            }
          }
        }

        ul.plan-bottom-section {
          padding: 28px;
          list-style: none;
          margin: 0px;

          li {
            background-repeat: no-repeat;
            padding: 0 0 0 30px;
            margin: 0 0 10px 0;
          }
        }
      }
    }

    .post-section {
      background: @light;
      border-radius: 4px;
      padding: 24px;

      h2 {
        font-family: Lato;
        margin-bottom: 24px;
        font-family: "Lato-bold";
        font-size: 16px;
      }

      .post-selection-panel {
        button {
          border-radius: 4px;
          background: white;
          min-width: 40px;
          min-height: 40px;
          margin-right: 4px;
          font-size: 16px;

          &.selected {
            background: black;
            color: white;
          }
        }

        > span {
          font-size: 16px;
          margin-left: 4px;
        }
      }

      .breakdown-cta {
        font-family: "Lato";
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        margin-top: 24px;
      }
    }

    .product-summary-section {
      table {
        border-radius: 4px;
        border: none;

        tr {
          td:last-of-type {
            text-align: right;
          }
        }
      }
    }

    .MuiCheckbox-colorSecondary {
      color: black;
    }
  }
}

.payment-contact-success-modal {
  transform: translate(0, 0) !important;
  top: 0 !important;
  left: 0 !important;
  text-align: center !important;
  width: 90%;
  margin: 0 auto;

  .common-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .intro {
    font-size: 16px;
    line-height: 1.6;
    color: @primary-dark;
    margin-bottom: 16px;
  }

  .ant-btn {
    height: 40px;
    text-align: center;
  }

  .ant-btn-primary {
    margin-right: 24px;
    font-size: 24px !important;
  }
}

.cancel-dropdown {
  width: 90%;
}

.txt-btn {
  text-decoration: underline;
  display: block;
  margin: 20px;
}

.modal-txt {
  font-size: 1em;
}

.april-img {
  max-width: 100px;
  margin: 20px;
}

.lets-chat {
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.ant-btn-primary{
  font-size: 24px;
  margin-right: 0 !important;
}