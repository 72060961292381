@import "../../../theme.less";
@import "../../create_business_canvas/company_info.less";

.competition {
  padding-top: 20px;
  font-size: medium;
  font-weight: 600;
  
}

.post-job-opp-container {
  width: 100%;
  padding-bottom: 72px;
  .home-header {
    z-index: 2;
  }
  .container {
    &.hasHeader {
      margin-top: @header-height;
      @media @mobile {
        margin-top: @mibile-header-height;
      }
    }
  }
  .breadcrumbs-wrap {
    display: none;
  }
  .center-wrap {
    max-width: 920px;
    width: auto;
    .opportunity-info-contianer,
    .additional-info-container,
    .media-info-container {
      display: flex;
      flex-direction: column;
      .job-title-sec {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .job-select {
          flex: 1;
        }
        .job-input {
          width: calc(50% - 12px);
          margin-left: 24px;
        }
      }

      .info-divider {
        height: 1px;
        background-color: @rgba-grey-light;
        margin-top: 50px;
      }

      .info-title {
        display: flex;
        align-items: center;
        font-family: "LibreBaskerville";
        font-size: 20px;
        color: @primary-dark;
        margin-top: 48px;
        line-height: 140%;
        img {
          width: 32px;
          margin-right: 16px;
        }
      }
    }

    .auto-complete {
      margin-top: 0;
      width: 100% !important;
    }

    .media-info-container {
      .media-wrap {
        margin-top: 17px;
      }
      .item-media {
        position: relative;
        padding: 16px;
        background-color: #ffffff;
        &:not(:last-child)::after {
          content: "";
          position: absolute;
          width: calc(100% - 32px);
          height: 1px;
          background-color: @rgba-grey-light;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .btn-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 48px;
      .left-sec {
        display: flex;
        align-items: center;
        .preview-btn {
          margin-left: 16px;
        }
      }
      .ant-btn {
        height: 51px;
        padding: 0 16px;
        &.ant-btn-link {
          letter-spacing: 0.04em;
          color: #2d2e3d;
          font-size: 16px;
          font-weight: 800;
        }
      }
    }
    @media @mobile {
      .opportunity-info-contianer,
      .additional-info-container,
      .media-info-container {
        .info-title,
        .info-divider {
          margin-top: 32px;
        }

        .info-sec .input {
          width: 100%;
        }
      }
      .btn-wrap .ant-btn {
        // margin-top: 26px;
        font-size: 16px;
      }
    }
  }
  &.isInMyAccount {
    // transform: scale(0.75);
    // transform-origin: 0 0;

    .breadcrumbs-wrap {
      display: flex;
      align-items: center;
      margin-top: 38px;
      font-weight: 800;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.04em;
      .breadcrumb-name {
        color: @rgba-grey;
        border-bottom: 1px solid rgba(45, 46, 61, 0.25);
      }
      .break-icon {
        margin: 0 5px;
      }
      .breadcrumb-item:last-child .break-icon {
        display: none;
      }
    }
    .title {
      margin-top: 17.5px;
    }
    @media @mobile {
      // transform: scale(1);
      .center-wrap {
        .breadcrumbs-wrap {
          margin-top: 5px;
        }
      }
    }
  }
  @media @mobile {
    padding-bottom: 26px;
    .container {
      padding-left: 30px / @pv;
      padding-right: 30px / @pv;
    }
    &.isInMyAccount .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.btn-primary {
  font-size: 16px !important;
}
