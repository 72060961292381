@import "../../theme.less";

.business-payment-wrap {
  padding: 5px 20px 20px;
  text-align: center;

  .logo {
    margin: 20px auto;

    img {
      width: 300px;
    }
  }

  .payment-header {
    h2 {
      font-family: "LibreBaskerville-bold";
      font-size: 1.6em;
    }

    p {
      font-family: "LibreBaskerville";
      text-align: center;
      font-size: 1.1em;
      color: black;
      width: 45%;
      margin-left: 27.5%;
      font-weight: bold;
    }
  }


  @media @mobile {
    .payment-header {
      p {
        font-size: 1em;
        width: 90%;
        margin-left: 5%;
      }
    }
  }

  .promotion-code-wrap {

    .MuiFormControl-root {

      .MuiFormHelperText-root {
        display: none;
      }

      .MuiFormHelperText-root.Mui-error {
        display: block;
        font-size: 14px;
      }

      .MuiFilledInput-root {
        border: 1px solid #CACBCE;

        &.Mui-focused {
          border: 1px solid #96979E;
        }

        &.Mui-error {
          border: 1px solid #ff6b00;


        }
      }
    }

    .promotion-code-input {
      display: inline-block;
    }

    .ant-btn {
      margin-left: 0;
      display: inline-block !important;
      height: 56px;
    }

    @media @mobile {
      .ant-btn {
        margin-left: 0;
        height: 56px;
        font-weight: 800;
        font-size: 16px;
      }
    }
  }

  .title-wrap {
    .common-title {
      font-family: "LibreBaskerville-bold";
      line-height: 1.4;
      text-align: center;
      padding: 0 20px;
    }

    .update-intro {
      font-size: 20px;
      color: @primary-dark;
      margin-bottom: 24px;
    }
  }

  .payment-content-wrap {
    margin-bottom: 20px;
    .pay-type {
      .pay-type-item {
        transition: all 0.3s;
        color: @primary-dark;
        background-color: #fff;
        border-radius: 4px;
        cursor: pointer;

        .info-wrap {
          border-bottom: 1px solid rgba(45, 46, 61, 0.1);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
        }

        .name-wrap {
          display: flex;
          align-items: flex-start;
          text-transform: uppercase;

          span {
            border-radius: 50%;
            border: 2px solid #cacbce;
          }

          .name {
            font-family: "Lato-bold";
          }
        }

        .fee-wrap {
          display: flex;
          align-items: center;

          .unit {
            opacity: 0.5;
            line-height: 1.2;
          }
        }

        .save {
          position: absolute;
          top: 0;
          background-color: @primary-dark;
          color: #fff;
          text-transform: uppercase;
          .flex-center-container();
          font-family: "Lato-bold";

          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0;
            border-color: transparent;
            border-bottom-color: #fff;
            border-style: solid;
          }
        }

        .detail-list div {
          display: flex;
          align-items: flex-start;
        }

        &.active {
          background-color: @primary-dark;
          color: #fff;

          .info-wrap {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .common-title {
              color: #fff;
            }

            .save {
              background-color: #fff;
              color: @primary-dark;

              &::after {
                border-bottom-color: @primary-dark;
              }
            }
          }
        }
      }
    }

    .custom-post {
      position: relative;

      .custom-title {
        font-family: "Lato-bold";
      }

      .post-rule {
        .show-more {
          text-align: center;
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }

        .only {
          width: 400px;
          max-width: 80%;
          margin: 0 10% !important;
        }

        .post-item {
          cursor: pointer;
          position: relative;
          min-width: 200px;
          max-width: 300px;
          margin: 10px;
          background: #fff;
          color: @primary-dark;
          transition: all 0.3s;
          text-align: center;
          border-radius: 4px;
          font-size: 2em;
          height: 150px;
          .post-item-container {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .post-item-number {
            font-size: 2.2em !important;
            font-weight: 400 !important;
            margin-right: 10px;
          }

          .post-item-opp-txt {
            text-align: left;
            line-height: 1.1;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: .5em;
          }

          .post-item-price {
            font-weight: bold;
            font-size: 1.1em;
          }

          .post-item-per-txt {
            position: absolute;
            bottom: 0;
            font-size: .5em;
            margin-bottom: 0;
            background-color: #D7D7D7;
            width: 100%;
            border-radius: 0 0 4px 4px;
            padding: 10px 0;
            &.active {
              background-color: #1C1D26;
            }
          }

          &.active {
            background: @primary-dark;
            color: #fff;
          }
        }
      }

      .more {
        font-weight: 800;
        color: @primary-dark;

        a {
          text-decoration: underline;
        }
      }
    }

    .product-summary {
      width: 80%;
      margin-left: 10%;
      .summary-list {
        background-color: #fff;
        font-weight: 500;
        border-radius: 4px;
        &>div {
          .flex-between-container();
          color: @grey;
          font-size: 10px;
          text-transform: uppercase;

          &>div:first-child {
            max-width: 75%;
          }

          .amount {
            color: @primary-dark;
            font-size: 16px;
          }

          &.summary-total {
            color: @primary-dark;
            border-top: 1px solid @rgba-grey-light;

            .amount {
              font-weight: bold;
            }
          }
        }
      }
    }

    .pay-method {
      max-width: 90%;

      .to-pp {
        .flex-between-container();
        color: @primary-dark;

        div {
          display: flex;
        }

        a {
          border-bottom: 1px solid @primary-dark;
        }
      }
    }

    .understand-checkbox {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      color: @primary-dark;
      cursor: pointer;
      margin-bottom: 24px;
    }

    .pay-plan {
      .plan-item {
        background: #fff;
        border-radius: 4px;
        border: 1px solid #fff;
        cursor: pointer;
        transition: border-color 0.3s;

        &.active {
          border-color: @primary-dark;
        }

        .plan {
          display: flex;
          align-items: center;
          color: @primary-dark;

          i {
            border: 2px solid #cacbce;
            border-radius: 50%;
          }

          div {
            font-weight: 800;
            letter-spacing: 0.04em;
          }

          span {
            line-height: 1.4;
            background: @light;
          }
        }

        .fee {
          display: flex;
          align-items: center;

          span {
            opacity: 0.5;
          }

          div {
            letter-spacing: -0.3px;
            font-family: "LibreBaskerville";
            color: @primary-dark;
          }

          .del-fee {
            color: #96979e;
            text-decoration: line-through;
          }
        }
      }

      .tip {
        color: @primary-dark;
      }
    }
  }

  .video-wrap {
    background: #fff;

    .video-title {
      color: @primary-dark;
      font-family: "Lato-bold";
    }

    .video {
      position: relative;

      .img {
        width: 100%;
      }

      .play {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  // @media @desktop {
  //   .title-wrap {
  //     .common-title {
  //       margin-bottom: 24px;

  //       &.business-title {
  //         width: 500px;
  //       }
  //     }

  //     .intro {
  //       margin-bottom: 24px;
  //       font-size: 15px;
  //       letter-spacing: -0.1px;
  //       text-align: center;
  //       padding: 0 10px;
  //     }
  //   }

  //   .payment-content-wrap {
  //     display: flex;
  //     justify-content: space-around;
  //     align-items: flex-start;

  //     .payment-wrap {
  //       display: flex;
  //       justify-content: center;
  //       width: 684px;
  //       flex-shrink: 0;
  //       max-width: 65%;
  //       flex-wrap: wrap;

  //       .pay-type {
  //         .flex-between-container();
  //         align-items: stretch;

  //         .pay-type-item {
  //           &.only {
  //             width: 100%;

  //             .info-wrap .name-wrap .name {
  //               width: 100%;
  //             }
  //           }

  //           .info-wrap {
  //             padding: 24px;
  //             height: 162px;

  //             .name-wrap {

  //               img,
  //               span {
  //                 width: 20px;
  //                 height: 20px;
  //                 margin-right: 10px;
  //               }

  //               .name {
  //                 font-size: 12px;
  //                 width: 145px;
  //               }
  //             }

  //             .fee-wrap {
  //               .common-title {
  //                 font-size: 40px;
  //               }

  //               .unit {
  //                 font-size: 14px;
  //                 width: 85px;
  //                 margin-left: 8px;
  //               }
  //             }

  //             // .monthly {
  //             //   font-size: 14px;
  //             // }
  //             .save {
  //               width: 75px;
  //               height: 72px;
  //               right: 24px;
  //               font-size: 10px;

  //               .save-num {
  //                 font-size: 14px;
  //               }

  //               &::after {
  //                 border-left-width: 37.5px;
  //                 border-right-width: 37.5px;
  //                 border-bottom-width: 10px;
  //               }
  //             }
  //           }

  //           .detail-list {
  //             padding: 24px 24px 8px;

  //             div {
  //               margin-bottom: 16px;

  //               img {
  //                 margin-right: 10px;
  //                 margin-top: 5px;
  //               }
  //             }
  //           }
  //         }
  //       }

  //       .custom-post {

  //         &::after {
  //           border-left-width: 12px;
  //           border-right-width: 12px;
  //           border-bottom-width: 16px;
  //           top: -16px;
  //         }

  //         .custom-title {
  //           font-size: 16px;
  //           margin-bottom: 24px;
  //         }

  //         .post-rule {
  //           .post-wrap {
  //             .post-item {
  //               // display: inline-block;
  //               // min-width: 200px;
  //               // line-height: 30px;
  //               // margin-right: 4px;
  //               cursor: pointer;
  //             }

  //             .unit {
  //               font-size: 16px;
  //               margin-left: 10px;
  //             }
  //           }

  //           .common-title {
  //             font-size: 20px;
  //           }
  //         }

  //         .more {
  //           margin-top: 24px;
  //           font-size: 14px;
  //         }
  //       }

  //       .title-with-icon {
  //         display: block !important;
  //         width: 60%;
  //         margin: 10px 20%;
  //       }

  //       .product-summary {
  //         .summary-list {
  //           padding: 0 24px;
  //           margin-bottom: 24px;

  //           &>div {
  //             padding: 16px 0;
  //           }
  //         }

  //         .tip {
  //           font-size: 16px;
  //           letter-spacing: -0.2px;
  //         }
  //       }

  //       .pay-method {
  //         margin-bottom: 48px;

  //         .to-pp {
  //           img {
  //             width: 16px;
  //             height: 16px;
  //             margin-right: 10px;
  //           }

  //           span {
  //             font-size: 16px;
  //           }

  //           a {
  //             font-size: 16px;
  //             font-weight: 800;
  //           }

  //           div {
  //             align-items: center;
  //           }
  //         }
  //       }

  //       .pay-plan {
  //         margin-bottom: 48px;

  //         .plan-item {
  //           padding: 26px;
  //           margin-bottom: 8px;
  //           .flex-between-container();

  //           .plan {

  //             img,
  //             i {
  //               width: 20px;
  //               height: 20px;
  //               margin-right: 26px;
  //             }

  //             div {
  //               font-size: 16px;
  //             }

  //             span {
  //               font-size: 12px;
  //               padding: 5px 8px;
  //               margin-left: 8px;
  //             }
  //           }

  //           .fee {
  //             span {
  //               font-size: 16px;
  //               margin-right: 8px;
  //             }

  //             div {
  //               font-size: 20px;
  //             }

  //             .del-fee {
  //               margin-right: 8px;
  //             }
  //           }
  //         }

  //         .tip {
  //           font-size: 16px;
  //           margin-top: 16px;
  //         }
  //       }

  //       .btn-primary {
  //         img {
  //           margin-right: 6px;
  //         }
  //       }
  //     }

  //     .video-wrap {
  //       flex-shrink: 1;
  //       margin-left: 24px;
  //       padding: 24px;

  //       .video-title {
  //         font-size: 16px;
  //         margin-bottom: 24px;
  //       }

  //       .video {
  //         .play {
  //           width: 70px;
  //         }
  //       }
  //     }
  //   }
  // }

  @media @mobile {
    .title-wrap {
      .common-title {
        margin-bottom: 24px / @pv;
      }

      .intro {
        margin-bottom: 24px / @pv;
        // font-size: 16px / @pv;
        letter-spacing: -0.1px;
        text-align: center;
        padding: 0 10px;
      }
    }

    .payment-content-wrap {
      .payment-wrap {
        .pay-type {
          .pay-type-item {
            margin-bottom: 16px / @pv;

            .info-wrap {
              padding: 18px / @pv 16px / @pv;
              height: 120px / @pv;

              .name-wrap {

                img,
                span {
                  width: 15px / @pv;
                  height: 15px / @pv;
                  margin-right: 10px / @pv;
                }

                .name {
                  font-size: 10px / @pv;
                  width: 145px / @pv;
                }
              }

              .fee-wrap {
                .common-title {
                  font-family: "LibreBaskerville-bold";
                  font-size: 32px / @pv;
                }

                .unit {
                  font-size: 12px / @pv;
                  width: 75px / @pv;
                  margin-left: 8px / @pv;
                }
              }

              .monthly {
                font-size: 12px / @pv;
              }

              .save {
                width: 64px / @pv;
                height: 72px / @pv;
                right: 16px / @pv;
                font-size: 10px / @pv;

                .save-num {
                  font-size: 14px / @pv;
                }

                &::after {
                  border-left-width: 32px / @pv;
                  border-right-width: 32px / @pv;
                  border-bottom-width: 10px / @pv;
                }
              }
            }

            .detail-list {
              padding: 18px / @pv 16px / @pv;

              div {
                margin-bottom: 16px / @pv;

                img {
                  margin-right: 10px / @pv;
                  margin-top: 5px / @pv;
                }
              }
            }
          }
        }

        .custom-post {
          &::after {
            border-left-width: 12px / @pv;
            border-right-width: 12px / @pv;
            border-bottom-width: 16px / @pv;
            top: -16px / @pv;
          }

          .custom-title {
            font-size: 16px / @pv;
            margin-bottom: 8px / @pv;
          }

          .post-rule {
            .post-wrap {
              .post-items {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
              }

              .unit {
                font-size: 16px / @pv;
                margin-top: 10px / @pv;
              }
            }

            .common-title {
              font-size: 20px / @pv;
              margin-top: 16px / @pv;
            }
          }

          .more {
            margin-top: 16px / @pv;
            font-size: 14px / @pv;
          }
        }

        .product-summary {
          .summary-list {
            padding: 0 16px / @pv;
            margin-bottom: 16px / @pv;

            &>div {
              padding: 16px / @pv 0;
            }
          }

          .tip {
            font-size: 16px / @pv;
            letter-spacing: -0.2px;
          }
        }

        .pay-method {
          max-width: 90%;
          margin-bottom: 32px / @pv;

          .to-pp {
            flex-direction: column;
            align-items: flex-start;

            img {
              width: 16px / @pv;
              height: 16px / @pv;
              margin-right: 10px / @pv;
            }

            span {
              font-size: 16px / @pv;
            }

            a {
              font-size: 16px / @pv;
              font-weight: 800;
            }

            div {
              align-items: baseline;
              margin-bottom: 8px / @pv;
            }
          }
        }

        .pay-plan {
          margin-bottom: 32px / @pv;

          .plan-item {
            padding: 12px / @pv 15px / @pv;
            margin-bottom: 8px / @pv;

            .plan {
              margin-bottom: 10px / @pv;

              img,
              i {
                width: 15px / @pv;
                height: 15px / @pv;
                margin-right: 10px / @pv;
              }

              div {
                font-size: 14px / @pv;
              }

              span {
                font-size: 12px / @pv;
                padding: 5px / @pv 8px / @pv;
                margin-left: 8px / @pv;
              }
            }

            .fee {
              span {
                font-size: 14px / @pv;
                margin-left: 8px / @pv;
                order: 2;
              }

              div {
                font-size: 20px / @pv;
              }

              .del-fee {
                order: 2;
                margin-left: 8px / @pv;
              }
            }
          }

          .tip {
            font-size: 16px;
            margin-top: 16px;
          }
        }

        .btn-primary {
          margin: 0 auto;
          display: block;

          img {
            width: 20px / @pv;
            margin-right: 6px / @pv;
          }
        }
      }

      .video-wrap {
        padding: 16px / @pv;

        .video-title {
          font-size: 16px / @pv;
          margin-bottom: 18px / @pv;
        }

        .video {
          .play {
            width: 50px / @pv;
          }
        }
      }
    }
  }
}

.payment-contact-success-modal .ant-modal-body {
  text-align: center;
  width: 90%;
  margin: 0 auto;

  .common-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .intro {
    font-size: 16px;
    line-height: 1.6;
    color: @primary-dark;
    margin-bottom: 16px;
  }

  .ant-btn {
    height: 40px;
    text-align: center;
  }

  .ant-btn-primary {
    margin-right: 24px;
  }
}

.cm-finish-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;

  button {
    width: 300px;
    text-align: center;
  }
}

.next-btn {
  display: inline-block;
  width: 80%;
  max-width: 400px;
  height: 60px !important;
}

.plan-row {
  display: flex;
  justify-content: left;
  margin-top: 20px;
  gap: 20px;
  justify-content: space-between;

  @media @mobile {
    flex-direction: column;
  }

  .switch-plan {
    cursor: pointer;
    padding-left: 10%;
    display: inline-block;

    img {
      display: block;
      width: 40vw;
      max-width: 110px;
      text-align: left;
      background-color: black;
      fill: white;
      border-radius: 20px;
    }

    span {
      text-decoration: underline;
      font-size: 12px;
      text-align: center;
    }
  }

  .selected-options {
    color: black;
    text-align: left;
    display: inline-block;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 10px;
    font-size: 1.2em;

    @media @mobile {
      text-align: center;
    }

    .num-options {
      font-weight: 300;
    }

    .disclosure {
      font-size: .8em;
      font-weight: bold;
      text-align: right !important;
      @media @mobile {
        text-align: center !important;
      }
    }
  }
}

.review-sub {
  padding: 0 0;
  font-size: 1.2em;
  font-family: "LibreBaskerville-bold";
  font-weight: 300;
  text-align: left;
}

.successful-promo {
  text-align: center;
  margin-top: 10px;
  width: 100%;

  .success-txt {
    color: rgb(27, 137, 47);
    font-size: 1.3em;
  }

  .success-amount {
    font-weight: bold;
    font-size: 1.4em;
  }
}

.pay-method {
  max-width: 70%;
  margin: 0 15%;

  @media @mobile {
    max-width: 90%;
    margin: 0 5%;
  }
}

.back-btn {
  margin: 20px;
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.2em;
}

.confirm-txt {
  font-weight: bold;
  max-width: 70%;
  margin: 0 15%;
  font-size: .9em;
  margin-bottom: 20px;

  @media @mobile {
    max-width: 90%;
    // margin: 0 5%;
  }
}

.contact-info {
  max-width: 70%;
  margin: 0 15%;

  @media @mobile {
    max-width: 90%;
    margin: 0 5%;
  }
}

.no-padding {
  padding: 0 !important;
}

.post-wrap {
  .post-items {
    display: flex;
    justify-content: center;
  }
}

.new-sub {
  .post-items {
    flex-wrap: nowrap !important;
  }
}

.show-more {
  display: block;
}

.promotion-code-wrap {
  display: block;
  text-align: center;

}

.step-two-logo {
  img {
    margin: 50px 0 50px 0;
    width: 300px;
  }

  @media @mobile {
    img {
      margin: 100px 0 50px 0;
    }
  }
}

.offline-payment-btn {
  display: block;
  margin: 20px;
}

.offline-payment {
  font-family: "LibreBaskerville-bold";
}

.salon-centric-input {
  margin-bottom: 20px;
  padding: 20px;
  background: white;
  border-radius: 5px;
}

.payment-method-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  flex-direction: row;

  @media @mobile {
    display: flex;
    flex-direction: column;
  }

  .payment-method-bank-transfer,
  .payment-method-credit-card {
    margin: 0 10px;
    font-weight: 500;
    transition: font-weight 0.3s;

    &.selected {
      font-weight: 1000;
    }
  }

  // Toggle Switch Styles
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #e3e3e3;
      transition: 0.4s;
      border-radius: 34px;
      box-shadow: inset 0 0 5px #e1e1e1;

      &::before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .slider::before {
      transform: translateX(26px);
    }
  }
}

.switch .slider.round:before {
  background-color: #282936;
}

.business-payment-wrap .switch .slider {
  background-color: #edecec;
  box-shadow: inset 0 0 5px #d3d3d3;
}

@media @mobile {
  .business-payment-wrap {
    .post-wrap {
      .post-items {
        flex-wrap: wrap !important;
      }
    }
  }
}

.back-to-subscription-options {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: bold;
  font-size: 1.1em;
  margin: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.summary-pay {
  width: 70%;
  margin-left: 15%;
  @media @mobile {
    width: 90%;
    margin-left: 5%;
  }
}

.title-with-icon {
  text-align: left !important;
  margin-bottom: 10px !important;
  display: block !important;
  margin: 10px 0 0 0;
  text-align: left;
  font-size: 1.2em !important;
  @media @mobile {
    text-align: center !important;
    margin-bottom: 10px !important;
  }
}

/* Mobile Styles: Vertical Switch */
@media (max-width: 600px) {
  .switch {
    width: 24px !important;
    height: 50px !important;

    .slider {
      border-radius: 34px !important;

      &::before {
        top: 2px !important;
        left: 2px !important;
        bottom: auto !important;
        transform: translateY(0) !important;
      }
    }

    input:checked + .slider::before {
      transform: translateY(26px) !important;
    }
  }
}
