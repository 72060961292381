@import "../../theme.less";
.student-canvas-root {
  height: @create-content-height;
  background-color: @extra-light;
  display: flex;
  margin-top: @create-header-height;
  @media @mobile {
    height: calc(100vh - 104px);
    margin-top: 104px;
  }
  .left-sec {
    height: 100%;
    width: 472px;
    box-shadow: inset -1px 0px 0px rgba(45, 46, 61, 0.1);
    overflow-y: auto;
    .title-sec {
      display: flex;
      margin-left: 24px;
      margin-top: 48px;
      justify-content: space-between;
      margin-right: 29px;
      @media @mobile {
        margin: 32px / @pv 30px / @pv 16px / @pv;
      }
      .title {
        @media @mobile {
          font-size: 24px / @pv;
        }
      }
      .btn-back {
        height: 41px;
        .txt-back {
          @media @mobile {
            display: none;
          }
        }
        .icon-edit {
          display: none;
          @media @mobile {
            display: inline-block;
          }
        }
      }
    }
    .txt-input {
      width: 100%;
      margin-bottom: 16px;
      @media @mobile {
        margin-bottom: 16px / @pv;
      }
    }
    .panel {
      background: none;
      .professional-background {
        width: 100%;
        padding: 0 24px 24px 0;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .professional-title {
          font-family: "Lato";
          font-weight: 800;
          font-size: 16px;
          line-height: 160%;
          color: @primary-dark;
          margin-bottom: 26px;
        }
        .btn-add {
          height: 42px;
          margin-top: 14px;
        }
      }
      .education-info {
        width: 100%;
        padding: 24px;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .education-title {
          font-family: "Lato";
          font-weight: 800;
          font-size: 16px;
          line-height: 160%;
          color: #2d2e3d;
          margin-bottom: 26px;
        }
        .school-name-sec {
          display: flex;
          align-items: center;
          margin-bottom: 26px;
          .school-name-input {
            width: 100%;
          }
          .del-icon {
            margin-left: 16px;
            cursor: pointer;
          }
        }
        .btn-add {
          height: 42px;
        }
        .divider {
          background-color: rgba(45, 46, 61, 0.1);
          margin: 26px 0;
          width: 100%;
          height: 1px;
        }
      }
      .intro-detail {
        width: 100%;
        padding: 24px;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .years-input {
          width: 100%;
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
        .locate-switch {
          width: 100%;
          height: 56px;
          background-color: white;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          margin-bottom: 16px;
          .locate-label {
            font-family: "Lato";
            font-size: 16px;
            color: @grey;
          }
          .select-wrap {
            span {
              height: 33px;
              padding: 0 12px;
              display: inline-block;
              background-color: @light;
              border-radius: 4px;
              line-height: 33px;
              cursor: pointer;
              font-family: "Lato";
              font-weight: 800;
              color: @grey;
              &.active {
                background-color: @grey;
                color: white;
              }
            }
          }
        }
        .checkbox-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-left: 3px;
          .check-box {
            margin-right: 15px;
            .ant-checkbox-input {
              width: 18px;
              height: 18px;
            }
          }
          span {
            font-size: 16px;
            color: #000000;
            .bold {
              font-weight: bold;
            }
          }
        }
        .relocate-input {
          width: 100%;
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
        .text-area-location {
          margin-bottom: 16px;
          width: 100%;
        }
        .about-me {
          margin-bottom: 16px;
          width: 100%;
        }
      }
      .media-detail {
        width: 100%;
        padding: 24px;
        background-color: @light;
        @media @mobile {
          padding: 30px;
        }
        .item-media {
          margin-bottom: 16px;
        }
        .btn-upload-video {
          white-space: normal;
          min-height: 42px;
          width: auto;
          padding: 0 16px;
          font-size: 14px;
          span {
            margin-left: 0 !important;
          }
          .loading {
            font-size: 18px;
            width: 20px;
            margin-right: 10px;
            @media @mobile {
              font-size: 16px;
              width: 18px;
            }
          }
        }
      }
      .award-detail {
        width: 100%;
        background-color: @light;
        padding: 24px;
        @media @mobile {
          padding: 30px;
        }
        .award-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 0;
          text-align: center;
          width: 100%;
          .icon-award-empty {
            height: 48px;
            width: 48px;
          }
          .empty-title {
            font-family: "Lato-bold";
            font-size: 14px;
            line-height: 160%;
            color: @primary-dark;
            margin: 16px 0 8px;
          }
          .empty-subtitle {
            font-family: "Lato";
            font-size: 14px;
            line-height: 160%;
            color: @grey;
            width: 282px;
          }
        }
        .btn-add-award {
          height: 42px;
          width: 131px;
          margin-top: 16px;
          padding: 0 16px;
        }
        .item-award {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
      .additional-detail {
        padding: 24px;
        width: 100%;
        background-color: @light;
        @media @mobile {
          padding: 30px / @pv 30px / @pv 14px / @pv;
        }
        .auto-complete {
          width: 100% !important;
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
      .kpi-detail {
        padding: 24px 24px 8px 24px;
        width: 100%;
        background-color: @light;
        @media @mobile {
          padding: 30px / @pv 30px / @pv 14px / @pv;
        }
      }
      .employ-detail {
        width: 100%;
        background-color: @light;
        padding: 24px;
        @media @mobile {
          padding: 30px;
        }
        .employ-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 0;
          text-align: center;
          width: 100%;
          .icon-employ-empty {
            height: 48px;
            width: 48px;
          }
          .empty-title {
            font-family: "Lato-bold";
            font-size: 14px;
            line-height: 160%;
            color: @primary-dark;
            margin: 16px 0 8px;
          }
          .empty-subtitle {
            font-family: "Lato";
            font-size: 14px;
            line-height: 160%;
            color: @grey;
            width: 282px;
          }
        }
        .employ-list {
          padding-bottom: 16px;
          .info-sec{
            .award-title{
              min-height: 10px;
            }
          }
        }
        .btn-add-employ {
          height: 42px;
          width: auto;
          margin-top: 16px;
          padding: 0 16px;
        }
        .item-employ {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
        .divider {
          width: 100%;
          height: 1px;
          margin-bottom: 16px;
          background-color: rgba(45, 46, 61, 0.1);
        }
      }
      .social-detail {
        padding: 24px 24px 8px 24px;
        width: 100%;
        background-color: @light;
        @media @mobile {
          padding: 30px / @pv 30px / @pv 14px / @pv;
        }
        .item-social-media {
          margin-bottom: 16px;
          @media @mobile {
            margin-bottom: 16px / @pv;
          }
        }
      }
    }
    .upload-btn-wrap {
      display: flex;
      @media @mobile {
        flex-direction: column;
        align-items: center;
        .file-uploader:last-child {
          margin-top: 12px;
        }
      }
    }
  }
  .right-sec {
    height: 100%;
    flex: 1;
    overflow: auto;
    @media @mobile {
      display: none;
    }
    .center-sec {
      width: 800px;
      margin: 48px auto;
      position: relative;
      height: 100%;

      .portrait-photo-wrap.hidden{
        opacity: 1;
      }
    }
  }
}
.MuiAutocomplete-noOptions {
  display: none;
}

.cm-standalone-label {
  font-size: 1rem;
  color: #2d2e3d;
  font-family: "Lato";
  text-transform: uppercase;
  margin: 0.25rem;
}

.subheader {
  padding: 0 20px;
}

.overview-link {
  text-decoration: underline;
  font-weight: bold;
}
