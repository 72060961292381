@import "../theme.less";
.school-location-detail-root {
  display: flex;
  flex-direction: column;
  .divider {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: @rgba-grey-light;
  }
  .location-title {
    margin-bottom: 24px;
    font-family: "Lato";
    font-weight: 800;
    font-size: 16px;
    line-height: 160%;
    color: @primary-dark;
  }
  .accredit-sec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .accredit-text {
      font-family: "Lato";
      font-size: 16px;
      line-height: 160%;
      color: @primary-dark;
      width: 204px;
    }
  }
  .input {
    margin-bottom: 16px;
    width: 100%;
  }
}
