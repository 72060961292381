// ! important: don't import this file in other .less files
//  instead, import the "theme.less" if necessary.
@import "~antd/dist/antd.less";
@import "./theme.less";
// @body-bg:  #F3F5F9;

@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Lato-bold";
  src: url("assets/fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "LibreBaskerville";
  src: url("assets/fonts/LibreBaskerville-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "LibreBaskerville-bold";
  src: url("assets/fonts/LibreBaskerville-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "LibreBaskerville-italic";
  src: url("assets/fonts/LibreBaskerville-Italic.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "LOREAL_PRO_OFFICE";
  src: url("assets/fonts/LOREAL_PRO_OFFICE-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "LOREAL_PRO_OFFICE-Bold";
  src: url("assets/fonts/LOREAL_PRO_OFFICE-SemiBold.ttf") format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: @extra-light;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ellipse {
  word-break: break-all;
  display: block;
  max-height: 80px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-primary {
  background: @primary-dark;
  color: white;
  font-family: "Lato";
  font-weight: 800;
  font-size: 16px;
  border-radius: 4px;
  letter-spacing: 0.04em;
  border: none;
  @media @mobile {
    font-size: 16px / @pv;
  }
  &:active {
    background: black;
    color: white;
  }
  &:hover {
    background: @grey;
    color: white;
  }
  &:focus {
    background: black;
    color: white;
  }
  &[disabled],
  &[disabled]:hover {
    background: @light;
    color: @grey;
  }
}

.btn-primary-light {
  height: 50px;
  padding: 0 24px;
  background: white;
  color: @primary-dark;
  font-family: "Lato";
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 0.04em;
  border: none;
  border-radius: 4px;
  .shadow-box();
  @media @mobile {
    height: 52px;
    padding: 0 16px;
    font-size: 14px;
  }
  &:active {
    background: rgba(0, 0, 0, 0.07);
  }
  &:focus {
    background: rgba(0, 0, 0, 0.07);
  }
  &[disabled],
  &[disabled]:hover {
    color: #d9d9d9;
  }
}

.btn-secondary {
  background: transparent;
  color: @primary-dark;
  font-family: "Lato";
  font-weight: 800;
  font-size: 16px;
  border-radius: 4px;
  padding: 0 24px;
  border: 1px solid @rgba-grey-light;
  display: flex;
  align-items: center;
  @media @mobile {
    // font-size: 16px / @pv;
    height: 42px / @pv;
  }
  &:active {
    background: transparent;
    border: 1px solid rgba(45, 46, 61, 0.75);
  }
  &:hover {
    background: transparent;
    border: 1px solid @rgba-grey;
  }
  &:focus {
    background: transparent;
    border: 1px solid rgba(45, 46, 61, 0.75);
  }
  &[disabled],
  &[disabled]:hover {
    background: transparent;
    color: rgba(45, 46, 61, 0.25);
    border: 1px solid @rgba-grey-light;
  }
  img {
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }
  @media (max-width: 475px) {
    font-size: 14px;
    padding: 0 17px;
    img {
      width: 18px;
    }
  }
}

.common-title {
  font-size: 32px;
  font-family: "LibreBaskerville";
  color: @primary-dark;
  line-height: 1.2;
  letter-spacing: -1px;
  &.bold-title {
    font-family: "LibreBaskerville-bold";
  }
  @media @mobile {
    font-size: 24px / @pv;
  }
}

.title-with-icon {
  font-size: 20px;
  font-family: "LibreBaskerville";
  letter-spacing: -0.3px;
  color: @primary-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  img {
    height: 32px;
    margin-right: 16px;
  }
  @media @mobile {
    font-family: "Lato-bold";
    font-size: 16px / @pv;
    margin-bottom: 16px / @pv;
    img {
      height: 24px / @pv;
      margin-right: 8px / @pv;
    }
  }
}

.material-input {
  .MuiFilledInput-root {
    background-color: white !important;
    border-radius: 4px;
    font-family: "Lato";
    font-size: 16px;
    color: @primary-dark;
    border: 1px solid white;
    &.Mui-focused {
      border: 1px solid @grey;
    }

    &.Mui-error {
      border: 1px solid #ff6b00;
      &:hover {
        border: 1px solid #ff6b00;
      }
    }
    &:hover {
      background-color: white;
      border: 1px solid @grey;
    }
    .MuiSelect-filled.MuiSelect-filled {
      text-align: left;
    }
  }
  .MuiFormLabel-root.Mui-error {
    color: #ff6b00 !important;
  }
  .MuiFilledInput-underline {
    &::before {
      border: none;
    }
    &::after {
      border: none;
    }
    &:hover,
    &.Mui-disabled {
      &:before {
        border-bottom: none;
      }
    }
  }
  .MuiInputLabel-root {
    font-family: "Lato";
  }
  .MuiFormLabel-root {
    font-size: 16px;
    color: @grey;
  }
  .MuiFormLabel-root.Mui-focused {
    font-size: 16px;
    color: @dark-grey;
    text-transform: uppercase;
    font-family: "Lato-bold";
  }
  .MuiInputLabel-shrink {
    color: @dark-grey;
    text-transform: uppercase;
    font-family: "Lato-bold";
  }
  .MuiFormHelperText-root.Mui-error {
    color: #ff6b00;
  }
}
.material-expand {
  box-shadow: none !important;
  margin: 0 !important;
  border-bottom: 1px solid @rgba-grey-light;
  &.Mui-expanded {
    border-bottom: none;
  }
  .MuiExpansionPanelSummary-root {
    height: 58px;
    min-height: 58px;
    @media @mobile {
      padding: 0 30px / @pv;
    }
    .MuiExpansionPanelSummary-content {
      font-family: "Lato-bold";
      font-size: 16px;
      color: @primary-dark;
      line-height: 160%;
    }
  }
  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 58px;
  }
  &:before {
    background-color: transparent !important;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0;
  }
}
.lb-bold {
  font-family: "LibreBaskerville-bold";
  font-size: 20px;
  color: @primary-dark;
}
.tag-input {
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(16px, 10px) scale(0.75);
  }
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-top: 36px;
    padding-left: 16px;
    padding-bottom: 12px;
  }

  .MuiChip-root {
    background-color: @extra-light;
    border: 1px solid @rgba-grey-light;
    font-family: "Lato";
    font-size: 16px;
    color: @primary-dark;
    .MuiChip-label {
      line-height: 32px;
    }
  }
  .MuiChip-deleteIcon {
    margin: 0 13px 0 -6px;
    width: 16px;
  }
}

// date picker input
.material-date-picker.MuiTextField-root {
  background-color: #fff;
  height: 58px;
  border-radius: 4px;
  border: 1px solid #fff;
  .MuiInput-underline::before,
  .MuiInput-underline::after {
    display: none;
  }
  &:hover {
    border-color: @grey;
  }
  .MuiFormLabel-root {
    transform: translate(12px, 20px) scale(1);
    font-size: 16px;
    &.MuiInputLabel-shrink,
    &.Mui-focused {
      font-family: "Lato-bold";
      transform: translate(12px, 10px) scale(0.75);
      color: @dark-grey;
      text-transform: uppercase;
    }
  }
  .MuiInputBase-input {
    font-size: 16px;
    padding: 27px 12px 10px;
  }
  label + .MuiInput-formControl {
    margin: 0;
  }
}

// date picker popover
.MuiPopover-paper .MuiPickersBasePicker-container {
  .MuiPickersYear-root,
  .MuiPickersMonth-root {
    color: @dark-grey;
  }
  .MuiTypography-colorPrimary {
    color: @primary-dark;
    background-color: @extra-light;
    border-radius: 8px;
  }
  .MuiPickersDay-daySelected {
    background-color: @dark-grey;
    &:hover {
      background-color: @dark-grey;
    }
  }
}

.table-empty-wrap {
  .flex-center-container();
  flex-direction: column;
  height: 492px;
  .common-title {
    font-size: 20px;
    margin-top: 28px;
    margin-bottom: 6px;
  }
  p {
    font-size: 12px;
  }
  .btn-secondary {
    height: 56px;
    margin-top: 24px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-modal-content {
  background-color: @extra-light;
}
.slick-slider {
  .slick-prev {
    width: 30px;
    height: 30px;
    left: -35px;
    @media @mobile {
      display: none !important;
    }
  }
  .slick-prev:before {
    color: black;
    font-size: 30px;
  }
  .slick-next {
    width: 30px;
    height: 30px;
    right: -35px;
    @media @mobile {
      display: none !important;
    }
  }
  .slick-next:before {
    color: black;
    font-size: 30px;
  }
}
.hyper-link {
  color: blue;
  text-decoration: underline;
}
.space {
  flex: 1;
}

.textField-container{

}

// Center and enlarge <Spin> component Globally

.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.ant-spin .ant-spin-dot {
  font-size: 40px;
}

.ant-spin-dot-item {
  width: 16px;
  height: 16px;
}

.dotted-line-hr {
  border-top: 2px dotted #b9b2b2;
  border-bottom: none;
  margin-bottom: 10px;
}
