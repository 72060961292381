.view-admin-data-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .buttons, .spinner {
    flex: 0 0 auto;
  }

  .chart-container, .choropleth-map-container {
    flex: 1 1 auto;
    width: 100%;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.selectors {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.bubble-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center align items */
  margin: 20px 10px;
}

.bubble {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(33.333% - 40px); /* 3 items per row */
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.bubble.full-width {
  flex: 1 1 100%;
  max-width: 100%;
}

.bubble h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
  cursor: pointer;
  color: #333;
}

.bubble table {
  width: 100%;
}

.bubble .react-table-container {
  margin-top: 10px;
}

.react-table-container {
  overflow-x: scroll;
}

.combined-table-container {
  width: 100%;
  overflow-x: scroll;
}