@import "../../../theme.less";

.cm-sc-landing {
  .cm-home-faq__btn-selector.cm-home-faq__btn-selector--selected {
    background-color: #9E8E9F !important;
    color: white !important;  
  }

  .cm-home-faq__btn-selector {
    background: white !important;
    color: #9E8E9F !important;
  }
}

.cm-sc-hero {
  padding: 64px 128px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;

  

  @media @mobile {
    padding: 8px;
  }

  h2 {
    font-family: "LibreBaskerville";
    font-size: 24px;
    max-width: 360px;
  }

  &__content {
    padding: 16px;

    form {
      margin: 8px 0;
    }

    .MuiFormControl-root {
      margin-right: 4px;

      @media @mobile {
        width: 80vw;
        margin-bottom: 12px;
      }

      .MuiInputBase-root {
        border: 1px solid #96979e;
      }
    }

    .btn-primary {
      height: 52px;
      margin-bottom: 0;
    }
  }

  &__extras {
    flex: 1;
    position: relative;

    .cm-sp-scrolling-ipad {
      position: absolute;
      top: 48px;
      left: 150px;
      width: 350px;
      height: 400px;

      @media @mobile {
          width: 200px;
          height: 250px;
      }      
    }
  }

  &__overlay {
    margin: 8px;
    max-width: 400px;

    @media @mobile {
      max-width: 300px;
    }
  }

  &__info {
    background: white;
    min-height: 324px;
    min-width: 300px;
    flex: 1;
  }

  &__selector {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid lightgray;
    padding: 12px;
    padding-bottom: 0;
  }

  &__selector-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4px;
    margin-bottom: 0;
    cursor: pointer;

    &--selected {
      border-bottom: 2px solid black;
    }

    p {
      text-align: center;
      margin: 0;
    }

    img {
      height: 32px;
    }
  }
}