@import "../../../theme.less";
.manage_member_modal-root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-sec {
    position: relative;
    background-color: white;
    height: 64px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 24px;
    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
    .title {
      font-family: "Lato-Bold";
      font-size: 20px;
      line-height: 160%;
      color: @primary-dark;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media @mobile {
        max-width: 150px / @pv;
      }
    }
  }

  .content {
    width: 100%;
    .search-sec {
      display: flex;
      padding: 24px;
      align-items: center;
      border-bottom: 1px solid rgba(45, 46, 61, 0.1);
      @media @mobile {
        flex-direction: column;
      }
      .input-wrap {
        flex: 1;
        display: flex;
        background-color: white;
        padding-left: 12px;
        border-radius: 4px;
        @media @mobile {
          width: 100%;
        }
        .input {
          flex: 1;
          .MuiFilledInput-root {
            &.Mui-focused {
              border: 1px solid white;
            }
            &:hover {
              border: 1px solid white;
            }
          }
        }
      }
      .btn-confirm {
        margin-left: 16px;
        height: 41px;
        @media @mobile {
          width: 100%;
          margin-top: 16px / @pv;
          margin-left: 0;
          span {
            margin: 0 auto;
          }
        }
      }
    }
    .list {
      padding: 16px 0;
      max-height: 318px;
      overflow-y: auto;
      .member-item {
        height: 56px;
        display: flex;
        padding: 0 22px;
        align-items: center;
        &:hover {
          background-color: #eaeaec;
          .icon-del {
            display: inline-block;
          }
        }
        .avatar {
          width: 32px;
          height: 32px;
        }
        .name {
          margin-left: 18px;
          font-family: "Lato";
          font-size: 16px;
          line-height: 160%;
          color: @primary-dark;
        }
        .space {
          flex: 1;
        }
        .icon-del {
          height: 34px;
          width: 34px;
          cursor: pointer;
          padding: 8px;
          display: none;
          @media @mobile {
            display: inline-block;
          }
        }
      }
    }
  }
}
.manage_member_modal-wrap {
  .ant-modal {
    width: 600px !important;
    .ant-modal-body {
      background-color: #f4f5f5;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;
    }
  }
}
