@import './theme.styl';

// define root font-size
@media screen and (max-width: pc-size-max) {
    html {
        font-size: base-font-size;
    }
}

@media screen and (min-width: pc-size-max) {
    html {
        font-size: base-font-size-large;
    }
}

deco-scrollbar();