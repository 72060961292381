@import "../../../theme.less";

.opportunity-applicants-root {
  width: 890px;
  margin: 24px auto;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .bread-crumb-item {
    font-weight: 800;
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 17.5px;
    .btn-secondary {
      padding: 0 16px;
      height: 41px;
    }
    @media @mobile {
      flex-direction: column;
      align-items: flex-start;
      .btn-secondary {
        margin-top: 16px / @pv;
        font-size: 14px / @pv;
        padding: 0 16px / @pv;
        height: 41px / @pv;
      }
    }
  }
  .card-wrap {
    display: flex;
    margin-top: 51px;
    .card-info {
      flex: 1;
      padding: 16px;
      color: @primary-color;
      background-color: #ffffff;
      border-radius: 4px;
      &:not(:first-child) {
        margin-left: 17px;
      }
      .card-title {
        font-family: "Lato";
        font-size: 12px;
        line-height: 140%;
      }
      .card-content {
        margin-top: 4px;
        font-family: "LibreBaskerville";
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: -1px;
      }
    }

    @media @mobile {
      flex-direction: column;
      margin-top: 32px / @pv;
      .card-info {
        padding: 16px / @pv;
        &:not(:first-child) {
          margin-left: 0;
          margin-top: 16px / @pv;
        }
        .card-title {
          font-size: 12px / @pv;
        }
        .card-content {
          font-size: 24px / @pv;
        }
      }
    }
  }
  .fitler-wrap {
    margin-top: 48px;
    .wrap-title {
      font-family: "LibreBaskerville";
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.3px;
      color: @primary-color;
    }
    .filter-container {
      margin-top: 24px;
      .ant-col {
        height: 56px;

        .material-input {
          width: 100%;
        }
        .keyword-input {
          height: 100%;
        }
        .input .MuiInputBase-root {
          height: 56px;
        }

        .btn-secondary {
          padding: 0 16px;
          height: 56px;
        }
      }
    }

    @media @mobile {
      display: flex;
      flex-direction: column;
      .ant-col {
        width: 100%;
        height: 56px / @pv;
        &:not(:first-child) {
          margin-top: 16px / @pv;
        }
      }
    }
  }
}

.opportunity-applicants-message-modal {
  max-width: 800px;
  .send-msg-modal {
    padding: 24px;
    .common-title {
      margin-bottom: 16px;
    }
    .modal-text {
      font-size: 16px;
      margin-bottom: 45px;
    }
    .material-input {
      width: 100%;
      margin-bottom: 24px;
    }
    .btn-wrap {
      .ant-btn {
        height: 41px;
      }
      .ant-btn-primary {
        margin-right: 16px;
      }
    }
  }
  .comfirm-modal {
    text-align: center;
    .common-title {
      font-size: 24px;
      margin-bottom: 8px;
      font-family: "LibreBaskerville-bold";
    }
    .modal-text {
      font-size: 16px;
    }
    img {
      width: 88px;
      margin-top: 28px;
    }
    .btn-wrap {
      .flex-center-container;
      margin-top: 24px;
      .ant-btn {
        height: 41px;
      }
      .ant-btn-primary {
        margin-right: 16px;
      }
    }
  }

  @media @mobile {
    .send-msg-modal {
      padding: 0;
      .modal-text {
        margin-bottom: 24px / @pv;
      }
    }
  }
}


.spinner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
