@import "../../../theme.less";

.school-dashboard-wrap {
  min-width: 890px;
  margin: 24px;
  @media @mobile {
    width: 100%;
    padding: 16px / @pv 30px / @pv;
    margin: 0;
  }
  .school-dashboard-tab-wrap {
    .flex-between-container();
    padding-bottom: 16px;
    border-bottom: 1px solid @rgba-grey-light;
    margin-bottom: 48px;
    flex-direction: row;
    flex-wrap: wrap;

    @media @mobile {
      flex-direction: column;
      margin-bottom: 32px / @pv;
    }

    .tab-list {
      display: flex;
      @media @mobile {
        flex-direction: column;
        width: 100%;
        overflow-x: auto;
      }
      .scroll {
        width: 100%;
        height: 41px;
        @media @mobile {
          height: 41px / @pv;
        }
      }
      .tab-item {
        height: 41px;
        white-space: nowrap;
        @media @mobile {
          width: fit-content;
          height: 41px / @pv;
          flex-shrink: 0;
          font-size: 14px;
          &:not(:last-child) {
            margin-bottom: 8px / @pv;
          }
        }
      }
    }
    .material-input {
      width: 267px;

      @media @mobile {
        width: 100%;
        margin-top: 16px;
      }
    }
    .member-role {
      text-align: right;
      width: 100%;
      font-weight: bold;
      font-family: "Lato";
      font-size: 14px;
      margin-top: 12px;
      color: #96979e;
    }
  }

  .school-pay-tip {
    width: 100%;
    height: 100%;
    .tip-content {
      text-align: center;
      padding: 110px;
      @media @mobile {
        padding: 16px;
      }
      .tip-title {
        margin: 6px auto;
        font-family: 'LibreBaskerville';
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.3px;
        text-align: center;
        color: @primary-color;
        @media @mobile {
          width: 100%;
        }
      }
      .tip-des {
        width: 423px;
        @media @mobile {
          width: 100%;
        }
        margin: 6px auto;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        line-height: 160%;
        color:  #96979e;
      }
      .btn-pay {
        height: 51px;
        margin-top: 10px;
      }
    }
  }
}
