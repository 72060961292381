@import "../theme.less";
.award-item {
  display: flex;
  min-height: 43px;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  .icon-award {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
  .info-sec {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    width: calc(100% - 130px);
    .award-title {
      font-family: "Lato-bold";
      font-size: 14px;
      color: @primary-dark;
      line-height: 160%;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .issue {
      font-family: "Lato";
      font-size: 12px;
      line-height: 140%;
      color: @dark-grey;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .icon-edit {
    width: 15px;
    cursor: pointer;
    margin-left: 16px;
    margin-right: 20px;
  }
  .icon-delete {
    width: 15px;
    cursor: pointer;
  }
}
