@import "../theme.less";
.validate-form {
  .ant-form-item label {
    position: absolute;
  }
  .material-input {
    width: 100%;
  }
  .has-error .material-input .MuiFilledInput-root {
    border-color: #f5222d;
  }
}