@import "../../theme.less";
.homepage-canvas-rights-data-container{
  .terms-banner .title {
    margin: 0;
  }
  .terms-content {
    background-color: #fff;
    h3, h4 {
      font-family: "LibreBaskerville";
      letter-spacing: -1px;
      color: #000;
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      line-height: 1.6;
      color: @primary-dark;
      opacity: 0.7;
      .MuiFormControl-root {
        width: 100%;
        border: 1px solid rgba(45, 46, 61, 0.1);
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 24px;
      }

      &.radio-wrapper{
        border: 1px solid rgba(45, 46, 61, 0.1);
        padding: 8px 18px;
        .MuiIconButton-colorSecondary:hover{
          background-color: transparent !important;
        }
        .MuiFormControlLabel-root{
          position: relative;
          &:not(:last-child){
            &:after{
              content: '';
              display: block;
              left:8px;
              right: 15px;
              bottom: 0;
              height: 1px;
              background-color: rgba(45, 46, 61, 0.1);;
              position: absolute;
            }
          }

        }
        .MuiSvgIcon-root{
          width: 2.1rem;
          height: 2.1rem;
        }
        .MuiRadio-colorSecondary.Mui-checked{
          color: #000;
          &:hover{
            background-color: transparent !important;
          }
        }
        .MuiFormControlLabel-root{

          min-height: 54px;
        }
        .MuiTypography-body1{
          //line-height: 4.5;
          padding: 8px 0;
        }
      }

      &.checkbox-wrapper{
        display: flex;
        flex-flow: row nowrap;
        span.check-text{
          margin-left: 4px;
          margin-top: -3px;
        }
      }

      &.btn-wrapper{
        text-align: center;
        button{
          text-transform: capitalize !important;
          font-weight: bold !important;
          font-size: 14px !important;
          color: #fff;
          background-color: #000;
          margin-right: 24px;
          padding: 12px 24px;
          &:disabled{
            background-color: #ccc;
          }
        }

      }
    }
    .g-recaptcha{
      margin-bottom: 24px;
    }
    .success-wrapper{
      text-align: center;
      p{
        width: 40%;
        margin: 0 auto;
        @media @mobile{
          width: 80%;
        }
      }
    }
  }

  @media @desktop {
    .terms-banner {
      padding: 80px 0 120px;
      text-align: center;
      .title {
        font-size: 48px;
      }
    }
    .terms-content {
      .padding-layout {
        width: 815px;
        margin: 0 auto;
      }
      h3 {
        font-size: 32px;
        margin-bottom: 24x;
        margin-top: 48px;
      }
      h4 {
        font-size: 20px;
        margin-bottom: 8px;
        margin-top: 48px;
      }
      p {
        font-size: 16px;
        margin-bottom: 24px;
      }
      pre {
        width: 100%;
        white-space: pre-wrap;
        font-family: "Lato";
      }
    }
  }
  @media @mobile {
    .terms-banner {
      padding: 48px / @pv 0 48px / @pv;
      .title {
        font-size: 32px / @pv;
      }
    }
    .terms-content {
      h3 {
        font-size: 32px / @pv;
        margin-bottom: 24px / @pv;
        margin-top: 48px / @pv;
      }
      h4 {
        font-size: 20px / @pv;
        margin-bottom: 8px / @pv;
        margin-top: 48px / @pv;
      }
      p {
        font-size: 16px / @pv;
        margin-bottom: 24px / @pv;
      }
    }
  }
}